<template>
    <div style="display: flex">
        <div class="body">
            <Header title="Bokningar"/>
            <pulse-loader style="text-align: center; margin-top: 35vh" :loading="loading_spinner" :color="color"
                          :size="size"></pulse-loader>
            <div v-if="empty_bookings===true">
                <EmptyBooking></EmptyBooking>
            </div>

            <div style="margin-left: 45px; margin-right: 45px; height: 100%;" v-if="bookings.length>0">
                <v-row class="row">
                    <!-- Left body content -->
                    <v-col class="left-col">
                        <div style="overflow-y: scroll; height: 80vh">
                            <div style="margin-top: 20px" v-if="recent_bookings.length>0">
                                <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold">
                                    Denna månad
                                </p>
                                <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                        v-for="booking in recent_bookings" v-bind:key="booking" @click="show(booking)">
                                    <div style="float: left;">
                                        <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                            {{ booking['createdDate'] }}</p>
                                        <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                            {{ booking['address'] }}</p>
                                        <p class="text-left"
                                           style="color: #9E9E9E; display: inline-block;">
                                            {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                            ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                            {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                            {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                            {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                        <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                            {{ booking['senderName'] }}</p>
                                    </div>
                                    <div style="float: right; display: inline-flex; margin-top: 4%">
                                        <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                            <v-icon>chevron_right</v-icon>
                                        </p>
                                    </div>
                                </button>
                            </div>
                            <div v-if="old_bookings.length>0">
                                <!--- January -->
                                <div v-if="booking_1.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        January</p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_2" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- February -->
                                <div v-if="booking_2.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        February</p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_2" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- March -->
                                <div v-if="booking_3.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        March</p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_3" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- April -->
                                <div v-if="booking_4.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        April</p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_4" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- May -->
                                <div v-if="booking_5.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        May</p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_5" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- June -->
                                <div v-if="booking_6.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        June
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_6" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- July -->
                                <div v-if="booking_7.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        July
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_7" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- August -->
                                <div v-if="booking_8.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        August
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_8" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- September -->
                                <div v-if="booking_9.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        September
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_9" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- October -->
                                <div v-if="booking_10.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        October
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_10" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- November -->
                                <div v-if="booking_11.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        November
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_11" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>

                                <!--- December -->
                                <div v-if="booking_12.length>0">
                                    <p style="margin-bottom: 25px; font-size: 30px; font-weight: bold; margin-top: 27px">
                                        December
                                    </p>
                                    <button style="border-bottom: solid 1px; border-bottom-color: lightgray; width: 100%;"
                                            v-for="booking in booking_12" v-bind:key="booking" @click="show(booking)">
                                        <div style="float: left;">
                                            <p style="font-size: 13px; font-weight: bold; color: #0E046E; text-align: left; margin-bottom: 0">
                                                {{ booking['createdDate'] }}</p>
                                            <p style="text-align: left; font-weight: bold; font-size: 25px; margin-bottom: 0">
                                                {{ booking['address'] }}</p>
                                            <p class="text-left"
                                               style="color: #9E9E9E; display: inline-block;">
                                                {{ booking['price'] }} kr, {{ booking.hoursNb }} timmar
                                                ({{ booking.from.split(":")[0] + ":" + booking.from.split(":")[1] }}
                                                {{booking.from.substr(booking.from.length-2, booking.from.length)}} -
                                                {{ booking.to.split(":")[0] + ":" + booking.to.split(":")[1] }}
                                                {{booking.from.substr(booking.to.length-2, booking.to.length)}})</p>
                                            <p class="text-left" style="color: #9E9E9E; margin-top: -10px">Bokad av
                                                {{ booking['senderName'] }}</p>
                                        </div>
                                        <div style="float: right; display: inline-flex; margin-top: 4%">
                                            <p style="float: right; margin-right: 20px; text-align: center; font-size: 18px;color: #9E9E9E; line-height: 60px">
                                                <v-icon>chevron_right</v-icon>
                                            </p>
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </v-col>

                    <v-col class="right-col">
                        <div id="selectparkingspot" style="display: block">
                            <div class="text-center circle" style="margin-top: 250px">
                                <i style="color: white; margin-top: 30%; font-size: 67px" class="far fa-eye"></i>
                            </div>
                            <p style="color: #B2B2B2; text-align: center; font-size: 52px; font-weight: bold; margin-top: 33px">
                                Välj en bokningar</p>
                        </div>
                        <div id="myDIV" style="display: none; height: 80vh">
                            <BookingDetails :booking="booking"
                                            v-if="show_details"/>
                        </div>

                    </v-col>
                </v-row>
            </div>
        </div>
    </div>
</template>

<script>
    import Header from "./Header";
    import firebase from "firebase";
    import BookingsApi from "@/services/BookingsApi";
    import EmptyBooking from "@/components/EmptyBooking";
    import BookingDetails from "@/components/BookingDetails";
    import PulseLoader from 'vue-spinner/src/SyncLoader.vue'

    export default {
        components: {
            BookingDetails,
            Header,
            EmptyBooking,
            PulseLoader
        },
        data() {
            return {
                bookings: [],
                booking: [],
                address: '',
                postkod: '',
                city: '',
                tider: '',
                parkingType: '',
                parkingSpec: '',
                parkingInfo: '',
                show_details: false,
                recent_bookings: [],
                old_bookings: [],
                booking_1: [],
                booking_2: [],
                booking_3: [],
                booking_4: [],
                booking_5: [],
                booking_6: [],
                booking_7: [],
                booking_8: [],
                booking_9: [],
                booking_10: [],
                booking_11: [],
                booking_12: [],
                color: '#0E046E',
                size: '45px',
                margin: '2px',
                radius: '2px',
                loading_spinner: true,
                empty_bookings: false
            }
        },
        beforeCreate() {
            document.title = "Bokningar"
        },
        created() {
            let vm = this;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    try {
                        BookingsApi.getBookings({
                            params: {
                                current: firebase.auth().currentUser.uid
                            }
                        }).then((res) => {
                            if (res['data']['bookings'].length > 0) {
                                vm.bookings = res['data']['bookings'];
                                let date = new Date();
                                let actual_month = date.getMonth() + 1;
                                vm.bookings.forEach(el => {
                                    let booking_month = el['createdAt'].substring(5, 7);
                                    if (booking_month.toString() === actual_month.toString()) {
                                        vm.recent_bookings.push(el)
                                    } else {
                                        vm.old_bookings.push(el)
                                    }
                                });
                                if (vm.old_bookings.length > 0) {
                                    vm.old_bookings.forEach(element => {
                                        switch (element['month_booking']) {
                                            case 'January':
                                                vm.booking_1.push(element);
                                                break;
                                            case 'February':
                                                vm.booking_2.push(element);
                                                break;
                                            case 'March':
                                                vm.booking_3.push(element);
                                                break;
                                            case 'April':
                                                vm.booking_4.push(element);
                                                break;
                                            case 'May':
                                                vm.booking_5.push(element);
                                                break;
                                            case 'June':
                                                vm.booking_6.push(element);
                                                break;
                                            case 'July':
                                                vm.booking_7.push(element);
                                                break;
                                            case 'August':
                                                vm.booking_8.push(element);
                                                break;
                                            case 'September':
                                                vm.booking_9.push(element);
                                                break;
                                            case 'October':
                                                vm.booking_10.push(element);
                                                break;
                                            case 'November':
                                                vm.booking_11.push(element);
                                                break;
                                            case 'December':
                                                vm.booking_12.push(element);
                                                break;
                                        }
                                    });
                                    if (vm.bookings.length === 0) {
                                        this.loading_spinner = false;
                                        this.empty_bookings = true
                                    } else {
                                        vm.loading_spinner = false
                                    }
                                }
                            } else {
                                this.loading_spinner = false;
                                this.empty_bookings = true
                            }
                        }).catch(() => {
                            vm.loading_spinner = false;
                            vm.empty_bookings = true;
                        })
                    } catch (e) {
                        vm.loading_spinner = false;
                        vm.empty_bookings = true;
                        console.log(e)
                    }
                }
            })
        },
        methods: {
            show(booking) {
                this.address = booking.address;
                this.show_details = true
                this.city = booking.city;
                this.postkod = booking.postkod
                this.parkingType = booking.parkingType;
                this.parkingInfo = booking.checkinfo;
                this.parkingSpec = booking.parkingspec;
                this.booking = booking;
                var y = document.getElementById("selectparkingspot");
                var x = document.getElementById("myDIV");
                if (x.style.display === "none") {
                    x.style.display = "block";
                    y.style.display = "none"
                }
            }
        }

    }
</script>

<style scoped>
    .right-col {
        overflow-y: scroll;
        float: right;
        margin-right: 47px;
        height: 80vh;
        width: 90vh;
        margin-top: 20px;
        text-align: left;

    }

    .left-col {
        padding-right: 0;
        float: left;
        border-right: solid #0E046E 1px;
        height: 90vh;
        width: 90vh;
    }


    .row {
        height: 100%;
    }


    html {
        height: 100%;
    }

    .view-picture {
        position: relative;
        z-index: 10;
        margin-top: -35px;
        margin-left: 25%;
        width: 95px;
        height: 35px;
        background: white;
        box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
        border-radius: 50px;
    }

    .information {
        display: -webkit-box;
        display: -moz-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
    }

    .user {
        padding-bottom: 25px;
        border-bottom: solid 1px;
        border-bottom-color: lightgray;
    }

    .body {
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */
        width: 100%;
        height: 100%;
        float: right;
    }

    .parkering {
        padding-bottom: 25px;
        border-bottom: solid 1px;
        border-bottom-color: lightgray;
    }

    .tider {
        padding-bottom: 25px;
        border-bottom: solid 1px;
        border-bottom-color: lightgray;
    }

    .circle {
        margin-top: 25%;
        margin-left: auto;
        margin-right: auto;
        background: rgb(178, 178, 178);
        border-radius: 50%;
        width: 137px;
        height: 137px;
    }


    ::-webkit-scrollbar {
        display: none;
    }
</style>