<template>
    <div style="display: flex">
        <div class="body">
            <Header title="Inställningar"/>
            <v-row class="row">
                <!-- Left body content -->
                <v-col class="left-col" style="padding-right: 0">
                    <div style="height: 80vh; overflow-y: scroll">
                        <button @click="showInfo()" style="text-align: left; font-size: 25px">
                            <strong style="float: left">Profilinformation</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                        <button @click="showBank()" style="text-align: left; font-size: 25px">
                            <strong style="float: left"> Koppla bankkonto</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                        <button @click="showLicence()" style="text-align: left; font-size: 25px">
                            <strong style="float: left">Licenser</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                        <button @click="showAnvan()" style="text-align: left; font-size: 25px">
                            <strong style="float: left">Användarvillkor</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                        <button @click="showPolicy()" style="text-align: left; font-size: 25px">
                            <strong style="float: left"> Integritetspolicy</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                        <button @click="showDelete()" style="text-align: left; font-size: 25px">
                            <strong style="float: left">Radera ditt konto</strong>
                            <i style="color: #C4C4C6;float: right; width: 14px; height: 30px; margin-right: 30px"
                               class="fas fa-chevron-right"></i>
                        </button>
                    </div>
                </v-col>

                <!-- Right body content -->
                <v-col class="right-col">
                    <div id="A" style="display: block; width: 100%; overflow-x: hidden">
                        <p style="color: black; font-size: 42px; font-weight: bold;  margin-left: 44px; margin-bottom: 34px">
                            Profilinformation</p>
                        <!--- Image --->

                        <div id="image"
                             style="vertical-align: center; height: 80px; margin-bottom: 100px;  margin-left: 44px">
                            <p style="font-weight: bold; margin-bottom: 15px;">Personlig information</p>
                            <v-img v-if="imgavatar.length > 0" :src="imgavatar"
                                   style="border: solid black 1px; border-radius: 50px; height: 80px; width: 80px; display: inline-block;"></v-img>
                            <v-avatar role="button" v-else size="80"
                                      style="height: 92px; width: 92px; background: #080056; color: #FFFFFF; font-size: 25px">
                                {{ initial.toUpperCase() }}
                            </v-avatar>
                            <label for="file"
                                   style="vertical-align: top !important; margin-top: 22px; margin-left: 15px; color: #080056; font-weight: bold; font-size: 22px"
                                   role="button">Byt profilbild</label>
                            <input id="file" style="display: none" type="file" @change="onFileChange" accept="image/*"/>
                        </div>

                        <form @submit.prevent="modify">
                            <div style="display: block; width: 100%; margin-top: 50px; margin-left: 44px">
                                <input class="input" id="firstname" placeholder="Förnamn" v-model="newfirstName"
                                       style="font-weight: bold; font-size: 31px">
                                <input class="input" id="lastname" placeholder="Efternamn" v-model="newlastName"
                                       style="font-weight: bold; font-size: 31px">
                                <input class="input" id="social" placeholder="Personnummer" v-model="newsocialNumber"
                                       style="font-weight: bold; font-size: 31px" @input="acceptSocial">
                            </div>

                            <div style="display: block; margin-left: 44px; margin-bottom: 53px">
                                <p style="color: black; font-size: 17px; font-weight: bold">Adress</p>
                                <input class="input" id="Address" placeholder="Adress" v-model="newAddress"
                                       style="font-weight: bold; font-size: 31px">
                                <input class="input" type="number" id="PostCode" placeholder="PostKod"
                                       v-model="newpostCode" style="font-weight: bold; font-size: 31px">
                                <input class="input" id="city" placeholder="Stad" v-model="newCity"
                                       style="font-weight: bold; font-size: 31px">
                            </div>

                            <v-btn class="modify"
                                   style="text-transform: none; margin-top: 40px; margin-left: 18%; width: 475px; height: 73px; background: #0E046E; font-size: 35px; font-weight: normal; margin-right: auto; color: white; border-radius: 50px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);"
                                   :disabled="isDisabled" :loading="loading" @click="modify"><strong>Spara</strong>
                            </v-btn>

                        </form>
                    </div>
                    <!--- Bank --->
                    <div id="B" style="display: none;">
                        <p style="color: black; font-size: 42px; font-weight: bold;  margin-left: 44px; margin-bottom: 34px">
                            Kopplat bankkonto</p>
                        <button v-if="bank_name && account_holder_name && last4" class="add"
                                style="margin-bottom: 20px; height: 120px; margin-left: 44px">
                            <v-row style="display: contents;">
                                <v-col style="width: 10%; display: inline-block; float: left">
                                    <i style="display: flex; margin-top: 10px" class="fas fa-university fa-3x"></i>
                                </v-col>
                                <v-col style="display: inline-block;float: right;width: 90%;text-align: left;padding-left: 45px;">
                                    <strong>{{ bank_name }}</strong>
                                    <p style="color: #879797; font-size: 20px; margin-bottom: 0; font-weight: normal">{{
                                        account_holder_name }}</p>
                                    <p style="color: #879797; font-size: 20px; margin-bottom: 0; font-weight: normal">
                                        IBAN sista 4: {{ last4 }}</p></v-col>
                            </v-row>
                        </button>
                        <button v-else class="add" style="margin-bottom: 15px; margin-left: 44px; height: 88px"
                                @click="redirectBank"><strong>Lägg till bankkonto</strong></button>
                        <button class="contact" style="margin-left: 44px; height: 88px"><a target="_blank"
                                                                                           style="text-decoration: none"
                                                                                           href="https://fastpark.se/kontakt-oss/"><strong
                                style="color: #0E046E">Kontakta ekonomisk
                            support</strong></a></button>
                    </div>

                    <!--- Licences --->
                    <div id="C" style="display: none; text-align: left; margin-left: 44px">
                        <h3>Bild och illustrationer</h3>
                        <h3>Designed by pch.vector / Freepik</h3>
                        <h3>Designed by vectorjuice / Freepik </h3>
                        <h3>Designed by Freepik</h3>
                        <h3>Designed by pikisuperstar / Freepik</h3>
                    </div>

                    <!--- Anvandarvillkor --->
                    <div id="D" style="display: none">
                        <Anvandarvillkor style="overflow: scroll; height: 80vh; width: 70vh; margin-left: 44px"/>
                    </div>

                    <!--- Policy --->
                    <div id="E" style="display: none">
                        <Policy style="overflow: scroll; height: 80vh; width: 70vh; margin-left: 44px"/>
                    </div>

                    <!--- Delete --->
                    <div id="F" style="display: none">
                        <div>
                            <p style="font-size: 42px; margin-left: 44px; font-weight: bold">Radera konto</p>
                            <div>
                                <div>
                                    <div v-if="deletechoice === null">
                                        <p style="margin-left: 44px; margin-bottom: 70px; font-size: 29px; font-weight: bold">
                                            Varför väljer du att radera ditt konto?</p>
                                        <div style="font-size: 30px; display: block; margin-left: 44px">
                                            <input id="one" name="Type" type="radio" class="with-font"
                                                   style="float: right" @click="check()"
                                                   value="Hemsidan är för svar att använda"/>
                                            <label style="width: 100%; float: left; font-weight: normal" for="one">Hemsidan
                                                är för svår att använda</label>
                                        </div>

                                        <div style="font-size: 30px; display: block; margin-left: 44px">
                                            <input style="width: 100%" id="two" name="Type" type="radio"
                                                   class="with-font"
                                                   @click="check()" value="Jag använder inte hemsidan"/>
                                            <label style="width: 100%; font-weight: normal" for="two">Jag använder inte
                                                hemsidan</label>
                                        </div>

                                        <div style="font-size: 30px; display: block; margin-left: 44px">
                                            <input style="width: 100%" id="three" name="Type" type="radio"
                                                   class="with-font"
                                                   @click="check()" value="Jag tycker inte om FastPark"/>
                                            <label style="width: 100%; font-weight: normal" for="three">Jag tycker inte
                                                om FastPark</label>
                                        </div>

                                        <div style="font-size: 30px; display: block; margin-left: 44px">
                                            <input style="width: 100%" id="four" name="Type" type="radio"
                                                   class="with-font"
                                                   @click="check()" value="Hemsidan är inte värd tiden"/>
                                            <label style="width: 100%; font-weight: normal" for="four">Hemsidan är inte
                                                värd tiden</label>
                                        </div>

                                        <div style="font-size: 28px; display: block; margin-left: 44px">
                                            <input style="width: 100%; font-weight: normal" id="five" name="Type"
                                                   type="radio"
                                                   class="with-font"
                                                   @click="check()" value="Jag behover en paus fran FastPark"/>
                                            <label style="width: 100%; font-weight: normal" for="five">Jag behöver en
                                                paus från FastPark</label>
                                        </div>
                                    </div>


                                    <div v-if="deletechoice !== null">
                                        <p style="color: #980000; font-weight: bold; margin-left: 44px; font-size: 29px; margin-bottom: 20px">
                                            Är du säker pa att du vill ta bort
                                            ditt<br> konto?</p>
                                        <p style="margin-left: 44px; font-size: 29px; margin-bottom: 54px">Din
                                            profilinformation, bokningar, meddelanden kommer permanent att raderas och
                                            går inte att återställa.</p>
                                        <button class="modify"
                                                style="background: #980000; display: block; width: 475px; height: 73px; margin-bottom: 20px; font-size: 30px"
                                                @click="deleteUser"><strong>Radera konto</strong>
                                        </button>
                                        <button class="modify" @click="deletechoice = null"
                                                style="display: block; width: 475px; height: 73px; font-size: 30px">
                                            <strong>Avbryt</strong>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import Anvandarvillkor from "./Anvandarvillkor";
    import Header from "./Header";
    import Policy from "./Policy";
    import firebase from "firebase";
    import SettingsApi from "../services/SettingsApi";

    export default {
        name: "Settings",
        components: {
            Header,
            Policy,
            Anvandarvillkor
        },

        data() {
            return {
                url: null,
                userInfo: {},
                newsocialNumber: '',
                newfirstName: '',
                newlastName: '',
                newAddress: '',
                newpostCode: '',
                newCity: '',
                avatar: '',
                imgavatar: '',
                initial: '',
                deletechoice: null,
                reload_page: false,
                reason: '',
                account_holder_name: '',
                bank_name: '',
                last4: '',
                loader: null,
                loading: false,
            }
        },
        beforeCreate() {
            document.title = "Inställningar"
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]
                setTimeout(() => (this[l] = false), 6000)
                this.loader = null
            },
            reload_page(value) {
                console.log(value)
                this.$router.go(0)
            }
        },
        created() {
            let vm = this
            firebase.auth().onAuthStateChanged(user => {
                    if (user) {
                        if (user.displayName) {
                            if (user.displayName.length === 1) {
                                vm.initial = user.displayName
                            } else {
                                window.db.collection('users').doc(user.uid).get().then(function (thisDoc) {
                                    if (thisDoc.exists) {
                                        vm.initial = thisDoc.data()['firstName'].charAt(0).toUpperCase() + thisDoc.data()['lastName'].charAt(0).toUpperCase()
                                    }
                                });
                            }
                        }
                         window.db.collection('users').doc(user.uid).get().then(function (thisDoc) {
                        if (thisDoc.exists) {
                            firebase.storage().ref('userProfileImages/' + thisDoc.data()['profileImageUrl']+".jpg").getDownloadURL().then(imgUrl => {
                                vm.imgavatar = imgUrl
                            });
                        }
                    });
                        SettingsApi.getUserDetails({
                            params: {
                                'current': user.uid
                            }
                        }).then(res => {
                            vm.userInfo = res.data
                            this.newfirstName = vm.userInfo['firstName']
                            this.newlastName = vm.userInfo['lastName']
                            this.newAddress = vm.userInfo['address']
                            this.newpostCode = vm.userInfo['postalCode']
                            this.newCity = vm.userInfo['city']
                            this.newsocialNumber = vm.userInfo['socialSecurityNumber']
                        })
                        SettingsApi.getBankAccount({
                            params: {
                                'current': user.uid
                            }
                        }).then(r => {
                            let res = r.data
                            vm.account_holder_name = res['account_holder_name']
                            vm.bank_name = res['bank_name']
                            vm.last4 = res['last4']
                        })
                    } else {
                        this.authUser = {}
                    }
                }
            );
        },
        methods: {
            acceptSocial() {
                var x = this.newsocialNumber.replace(/[^0-9]/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,4})/);
                this.newsocialNumber = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
            },
            deleteUser() {
                try {
                    let vm = this
                    SettingsApi.deleteUserAccount({
                        userId: firebase.auth().currentUser.uid,
                        user: firebase.auth().currentUser,
                        reason: this.deletechoice
                    }).then(r => {
                        console.log(r)
                        vm.$store.dispatch('setToken', null)
                        vm.$store.dispatch('setUser', null)
                        vm.$router.push({path: '/'})
                        window.location.reload(); //reload on signout !
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            redirectBank() {
                this.$router.push({name: 'bankVerification'})
            },
            check() {
                let select = document.querySelector('input[name="Type"]:checked').value;
                this.deletechoice = select
                return select
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.imageData = e.target.files[0]
                this.url = URL.createObjectURL(file);
                this.updateAvatar();
            },
            updateAvatar() {
                try {
                    SettingsApi.modifyAvatar({
                        userId: firebase.auth().currentUser.uid,
                        avatar: firebase.auth().currentUser.uid
                    }).then(async (res) => {
                        if (res.status === 200) {
                            const storageRef = firebase.storage().ref(`userProfileImages/${res.data}` + '.jpg').put(this.imageData);
                            storageRef.snapshot.ref.getDownloadURL().then((url) => {
                                this.url = url;
                                this.reload_page = true
                            }).catch((r) => {
                                console.log(r);
                                this.reload_page = true
                            })
                        }
                    })
                } catch (e) {
                    console.log(e)
                }
            },
            modify() {
                this.loader = 'loading';
                try {
                    SettingsApi.modifyUserDetails({
                        userId: firebase.auth().currentUser.uid,
                        newfirstName: this.newfirstName,
                        newlastName: this.newlastName,
                        newAddress: this.newAddress,
                        newpostCode: this.newpostCode,
                        newCity: this.newCity,
                        newEmail: this.newEmail,
                        newsocialNumber: this.newsocialNumber
                    }).then(r => {
                        console.log(r);
                        this.$router.go(0)
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            showInfo() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (a.style.display === "none") {
                    a.style.display = "block";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                    f.style.display = "none";
                }
            },
            showBank() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (b.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "block";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                    f.style.display = "none";
                }
            },
            showLicence() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (c.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "block";
                    d.style.display = "none";
                    e.style.display = "none";
                    f.style.display = "none";
                }
            },
            showAnvan() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (d.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "block";
                    e.style.display = "none";
                    f.style.display = "none";
                }
            },
            showPolicy() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (e.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "block";
                    f.style.display = "none";
                }
            },
            showDelete() {
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                var f = document.getElementById("F");
                if (f.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                    f.style.display = "block";
                }
            },
            checkInput() {
                let returnvalue = true
                if (this.newfirstName && this.newlastName && this.newAddress && this.newpostCode && this.newCity && this.newsocialNumber) {
                    returnvalue = false
                }
                return returnvalue

            }
        },
        computed: {
            isDisabled() {
                return this.checkInput()
            }
        }
    }
</script>

<style scoped>
    button {
        height: 100px;
        border-bottom: solid 1px;
        border-bottom-color: #F1F1F1;
        width: 100%;
    }

    .body {
        width: 100%;
        float: right;
    }

    .right-col {
        overflow-y: scroll;
        float: right;
        margin-right: 47px;
        height: 80vh;
        width: 70vh;
        margin-top: 20px;
        text-align: left;

    }

    .left-col {
        float: left;
        border-right: solid #0E046E 1px;
        margin-left: 45px;
        height: 90vh;
        width: 90vh;
    }


    .row {
        position: fixed;
        height: 100%;
    }


    input:focus {
        outline: none;
    }

    .input {
        padding-left: 15px;
        float: left;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: xx-large;
        background: #eeeeee;
        margin-bottom: 20px;
        width: 70vh;
        height: 91px;
    }

    .modify {
        margin-right: auto;
        margin-left: auto;
        font-weight: bold;
        color: white;
        font-size: 25px;
        width: 300px;
        height: 70px;
        background: #080056;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .contact {
        margin-right: auto;
        margin-left: auto;
        font-weight: bold;
        color: #0E046E;
        font-size: 25px;
        width: 70vh;
        height: 70px;
        background: rgba(14, 4, 110, 0.23);
        border-radius: 10px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .add {
        margin-right: auto;
        margin-left: auto;
        font-weight: bold;
        color: black;
        font-size: 25px;
        width: 70vh;
        height: 70px;
        background: #F3F3F3;
        border-radius: 10px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .all {
        display: flex;
        -ms-overflow-style: none;
        overflow: hidden;
    }

    input[type=radio].with-font,
    input[type=checkbox].with-font {
        float: right;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-font ~ label:before,
    input[type=checkbox].with-font ~ label:before {
        float: right;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: black;
        width: 1.4em;
    }

    input[type=radio].with-font:checked ~ label:before,
    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        font-size: 1.2em;
        color: black;
        letter-spacing: 5px;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #b5b2d3 !important;
    }
</style>