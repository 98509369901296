<template>
    <div style="padding-left: 7px; padding-right: 7px">
        <div>
            <p style="font-size: large; font-weight: bold;margin-bottom: 0px">FASTPARK INTEGRITETSPOLICY</p>
            <p>
                Denna dag, 2021-01-01, har följande policy upprättats för FastPark Sweden AB.<br>
                Vi värnar om din integritet. Du ska kunna känna dig trygg när du anförtror oss dina
                personuppgifter. Därför har vi upprättat den här policyn. Den utgår från gällande
                dataskyddslagstiftning och förtydligar hur vi jobbar för att ta tillvara dina rättigheter och din
                integritet.<br>
                Syftet med den här policyn är att du ska få veta hur vi behandlar dina personuppgifter, vad vi
                använder dem till, vilka som får ta del av dem och under vilka förutsättningar samt hur du kan
                ta tillvara dina rättigheter.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold;margin-bottom: 0px">Vad är personuppgifter?</p>
            <p>
                Med personuppgifter avses alla uppgifter som direkt eller indirekt kan härledas till en levande
                fysisk person.<br><br>
                FastPark är angelägna om att dina personuppgifter skyddas och vi behandlar dem därför
                endast i enlighet med den allmänna dataskyddsförordningen (EU) 2016/679 och annan
                gällande lagstiftning.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0px">Vi är personuppgiftsansvariga</p>
            <p>
                FastPark med organisationsnummer 559201 - 4012 och adress Packhusallén 8b, 652 14
                Karlstad ansvarar för de personuppgifter som behandlas om dig. FastPark är därför
                personuppgiftsansvarig för dina personuppgifter.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold;  margin-bottom: 0px">Varför behandlar vi personuppgifter om
                dig?</p>
            <p>
                Vi behandlar dina personuppgifter för att kunna tillhandahålla dig våra tjänster och produkter,
                dvs. endast för att ingå eller fullgöra ett avtal med dig som kund.
            </p>

        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Vilka uppgifter samlar vi in om dig – och
                varför?</p>
            <p>
                Vi strävar efter att behandla så få personuppgifter som möjligt om dig. Det innebär att vi inte
                samlar in fler personuppgifter än nödvändigt för att kunna ingå eller fullgöra avtal med
                dig. För att kunna ingå samt fullgöra avtal med dig behöver vi information om förnamn,
                efternamn, e-postadress, adress, personnummer, telefonnummer, betaluppgifter och
                andra kontaktuppgifter till dig.
                FastPark kan komma att inhämta från, och lämna uppgifter om dig till, tredje part såsom
                kreditupplysningsinstitut, myndigheter och handelsplatser. Du kommer att erhålla information
                om vilka tredje parter som informationen kan komma att lämnas till/inhämtas ifrån i samband
                med den aktuella behandlingen.
                Om du inte har givit oss ditt samtycke använder vi inte dina personuppgifter för
                marknadsföringsändamål.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Hur skyddar vi dina personuppgifter?</p>
            <p>
                Din säkerhet är viktig för oss. Därför har vi vidtagit lämpliga tekniska, organisatoriska och
                administrativa säkerhetsåtgärder för att skydda dina personuppgifter från obehörig åtkomst
                och annan otillåten behandling. Vi analyserar och utvärderar regelbundet åtgärderna i syfte att
                skyddet för dina uppgifter ska bli så säkert som möjligt.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Vilka lämnar vi ut dina uppgifter till?</p>
            <p>
                Vi lämnar inte ut dina uppgifter till andra företag eller organisationer om det inte krävs enligt
                lag eller är nödvändigt för att utföra våra lagstadgade eller avtalsenliga förpliktelser gentemot
                dig.
                Vi kan lämna ut dina personuppgifter till någon av våra samarbetspartners, leverantörer eller
                underleverantörer, men endast om det behövs för att vi ska kunna uppfylla våra skyldigheter i
                förhållande till dig som kund. Vi lämnar aldrig ut fler personuppgifter än vad som är
                nödvändigt.
                När det krävs enligt lag kan vi behöva lämna ut dina uppgifter till myndigheter och andra
                organisationer. Vi kan också behöva lämna ut dina uppgifter om det är nödvändigt för att
                utöva, fastställa eller bevaka våra rättsliga anspråk.
                Vi lämnar aldrig ut dina personuppgifter till andra företag eller verksamheter för
                marknadsföringsändamål
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Med stöd av vilka rättsliga grunder
                behandlar vi
                personuppgifter om dig?</p>
            <p>
                Vi behandlar endast de personuppgifter vi behöver för att vi ska kunna utföra våra
                tjänster eller leverera våra produkter till dig, dvs. när vi ingår eller fullgör avtal med dig. I den
                mån vi behandlar ytterligare uppgifter kommer vi att inhämta ditt samtycke eller säkerställa
                att behandlingen är tillåten med stöd av annan rättslig grund.<br><br>
                FastPark kan komma att inhämta från, och lämna uppgifter om dig till, tredje part såsom
                kreditupplysningsinstitut, myndigheter och handelsplatser. Du kommer att erhålla information
                om vilka tredje parter som informationen kan komma att lämnas till/inhämtas ifrån i samband
                med den aktuella behandlingen.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold">Hur länge behandlar vi dina personuppgifter?</p>

            <p>
                FastPark bevarar inte personuppgifter längre än nödvändigt med hänsyn till ändamålet med
                behandlingen. Personuppgifterna lagras så länge avtalsförhållandet består och därefter i högst
                tio år med hänsyn till regler om preskription. I vissa fall kan uppgifterna emellertid komma att
                sparas längre tid på grund av lagstiftningskrav.<br><br>
                Uppgifter som behandlas i syfte att utveckla, analysera och marknadsföra vår verksamhet
                raderas när de inte längre är relevanta för det ändamål de samlats in för. Om
                du avanmäler dig från nyhetsbrev eller liknande kommer uppgifterna omedelbart att raderas.
            </p>
        </div>


        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Dina rättigheter</p>
            <p>
                När vi behandlar personuppgifter om dig har du som registrerad flera rättigheter. Du har rätt
                att när som helst ta kontakt med oss gällande dessa, och om du vill utöva någon av
                rättigheterna som beskrivs nedan når du oss enklast på info@fastpark.se.
                Vi förbehåller oss rätten att vidta lämpliga skydds- och säkerhetsåtgärder i syfte att säkerställa
                att du är den du utger dig för att vara när du tar kontakt med oss. Om du inte kan visa din
                identitet på ett trovärdigt sätt är det inte säkert att vi kan tillmötesgå din begäran.
            </p>
        </div>

        <div>
            <i>Tillgång till personuppgifter</i>
            <p>
                Du har rätt att få veta vilka personuppgifter vi behandlar om dig. Om du vill veta det kan du
                få ett sammanställt registerutdrag av oss som innehåller de personuppgifter vi behandlar om
                dig.
            </p>
        </div>

        <div>
            <i>Rättelse och radering</i>
            <p>
                Om vi behandlar dina personuppgifter på ett felaktigt sätt eller om vi inte längre behöver
                uppgifterna har du rätt att få dem raderade. Om uppgifterna är ofullständiga har du rätt att få
                dem kompletterade. Tänk på att det inte är säkert att vi kan utföra våra tjänster till dig om du
                begär att få dina personuppgifter raderade.
            </p>
        </div>

        <div>
            <i>Dataportabilitet</i>
            <p>Under vissa omständigheter har du rätt att få de uppgifter vi behandlar om dig i ett allmänt,
                skriftligt, maskinläsbart och strukturerat format. Detta har du rätt till beträffande de
                personuppgifter som du själv har lämnat till oss och som vi behandlar med stöd av ditt
                samtycke eller när personuppgifterna krävs för att ingå eller fullföra avtal med dig.
            </p>
        </div>

        <div>
            <i> Begränsning av behandling</i>
            <p>
                Under vissa förutsättningar har du rätt att begära att vi ska begränsa vår behandling av dina
                uppgifter. Det innebär att vi markerar uppgifterna så att vi i framtiden endast behandlar dem
                för vissa särskilda syften. Det är inte säkert att vi kan tillhandahålla dig våra tjänster om vi
                begränsar behandlingen av dina personuppgifter.
            </p>
        </div>

        <div>
            <i>Rätt att göra invändningar</i>
            <p>
                Du har rätt att invända mot behandling av personuppgifter som utförs i syfte utföra en uppgift
                av allmänt intresse, som ett led i myndighetsutövning eller efter en intresseavvägning. Vi
                behandlar inte dina personuppgifter för något av dessa ändamål eller enligt någon av dessa
                grunder. Du kan därför inte rikta några invändningar mot vår behandling enligt denna grund.
            </p>
        </div>

        <div>
            <i>Rätt att inge klagomål</i>
            <p>
                Du har rätt att inge klagomål till Datainspektionen om du anser att vi behandlar dina
                personuppgifter på ett felaktigt sätt. Du kan läsa mer om detta på Datainspektionens
                hemsida www.datainspektionen.se.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Ändringar av denna policy</p>
            <p>
                Denna policy kan komma att ändras. Den senaste versionen finns alltid
                publicerad i vår app. Besök därför gärna vår app för att ta del av eventuella uppdateringar
                eller förändringar.
            </p>
        </div>

        <div>
            <p style="font-size: large; font-weight: bold; margin-bottom: 0">Kontakt</p>
            <p>
                Du kan när som helst kontakta oss genom att skriva till info@fastpark.se eller genom att
                ringa oss på telefonnummer 070-269 11 24 om du vill veta mer om hur vi behandlar dina
                personuppgifter. Du kan även läsa mer om oss på vår hemsida www.fastpark.se
            </p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Policy"
    }
</script>

<style scoped>
    div {
        text-align: justify;
        text-justify: inter-word;
    }
</style>