<template>
  <div style="display: flex">
    <div style="width: 100%">
      <Header title="Ny parkeringsplats"/>
      <div style="text-align: center; max-height: 100%; margin-top: 30px; overflow-y: scroll; height: 85vh">
        <img src="../assets/bank_account.png" style="margin-bottom: 15px; width: 14%" alt="">
        <div>
          <p style="color: black; font-size: 54px; font-weight: bold; margin-bottom: 0">Bra jobbat!</p>
            <p style="color: black; font-size: 40px; font-weight: bold">Din parkering har blivit registrerad.</p>
          <div style="display: inline">
            <p style="display: inline; font-size: 25px; color: black">Parkeringens status:</p>
            <p style="color: #FFAB00; display: inline; ; font-size: 31px; font-weight: bold"> Ej aktiv</p>
          </div>
          <p style="font-size: 25px; color: #000000"><i style="color:#FFAB00" class="fas fa-exclamation-circle"></i> Innan din parkering blir aktiv för uthyrning behöver <br>du koppla ett bankkonto för att ta emot betalningar</p>
        </div>
        <div>
           <router-link :to="{name: 'bankVerification'}" style="text-decoration: None">
          <button class="main" style="width: 444px; margin-top: 30px">Koppla bankkonto</button>
           </router-link>
          <br>
          <router-link :to="{name: 'ParkingSpots'}" style="text-decoration: None">
            <button class="secondary" style="width: 444px; margin-bottom: 50px">Gör detta senare</button>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Header from "./Header";

export default {
  components: {
    Header
  },
}
</script>

<style scoped>
.main {
  font-weight: bold;
  color: white;
  font-size: 25px;
  margin-bottom: 25px;
  position: center;
  width: 300px;
  height: 70px;
  background: #0E046E;
  border-radius: 50px;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
}

.secondary {
  font-size: 25px;
  position: center;
  width: 300px;
  height: 70px;
  border: 3px solid #0E046E;
  border-radius: 50px;
  color: #0E046E;
  font-weight: bold;
}

  ::-webkit-scrollbar {
  display: none;
}
</style>