<template>
    <div style="display: flex">
        <div class="body">
            <!-- Header -->
            <Header title="Koppla bankkonto"/>
            <!-- Body -->
            <v-row class="row">
                <!-- Left body content -->
                <v-col id="test" style="overflow-y: scroll; height: 80vh" cols="6">
                    <div style="margin-bottom: 30px; width: 480px">
                        <p style="color: black; font-size: 18px; font-weight: bold">Juridiskt kön</p>
                        <v-card no-body
                                style="box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  margin-right: auto; margin-left: 2px; margin-top: 10px; width: 480px;">
                            <v-tabs card v-model="gender">
                                <v-tab title="Tab 1" style="width: 50%" key="first">
                                    <v-card-text style="font-weight: bold; text-transform: none;">Manlig</v-card-text>
                                </v-tab>
                                <v-tab title="Tab 2" style="width: 50%" key="second">
                                    <v-card-text style="font-weight: bold; text-transform: none;">Kvinna</v-card-text>
                                </v-tab>
                            </v-tabs>
                        </v-card>
                    </div>

                    <!-- Personal information -->
                    <div>
                        <p style="color: black; font-size: 18px; font-weight: bold">Personlig information</p>
                        <label>
                            <input class="input" placeholder="Förnamn" style="margin-bottom: 25px; font-size: 31px"
                                   v-model="firstName"/>
                        </label>
                        <label>
                            <input class="input" placeholder="Efternamn" style="margin-bottom: 25px; font-size: 31px"
                                   v-model="lastName"/>
                        </label>
                        <label>
                            <input class="input" placeholder="Födelsedatum (YYYY-MM-DD)"
                                   style="margin-bottom: 25px; font-size: 31px"
                                   v-model="birthday" @input="acceptSocial"/>
                        </label>
                    </div>

                    <!-- Adress -->
                    <div>
                        <p style="color: black; font-size: 18px; font-weight: bold">Adress</p>
                        <label>
                            <input class="input" placeholder="Adress" style="margin-bottom: 25px; font-size: 31px"
                                   v-model="address"/>
                        </label>
                        <label>
                            <input type="number" class="input" placeholder="Postkod"
                                   style="margin-bottom: 25px; font-size: 31px"
                                   v-model="postcode"/>
                        </label>
                        <label>
                            <input class="input" placeholder="Stad" style="margin-bottom: 25px; font-size: 31px"
                                   v-model="city"/>
                        </label>
                    </div>

                    <!-- contact information -->
                    <div style="margin-top: 15px">
                        <p style="font-size: 17px; font-weight: bold">Kontaktinformation </p>
                        <label>
                            <input class="input" placeholder="E-postadress" style="margin-bottom: 25px; font-size: 31px"
                                   v-model="email_address"/>
                        </label>
                        <label>
                            <input class="input" placeholder="Telefonnummer"
                                   style="margin-bottom: 25px; font-size: 31px" @input="acceptNumber"
                                   v-model="phone_number"/>
                        </label>
                    </div>

                    <div style="margin-top: 15px">
                        <p style="display: inline; font-size: 17px; font-weight: bold">Bankkoninformation </p>
                        <!-- Clearing Select -->
                        <button v-if="bank===1" @click="ibanSelect"
                                style="font-size: 17px;display: inline; font-weight: bold; color: #080056">IBAN
                        </button>
                        <!-- IBAN Select -->
                        <button v-if="bank===2" @click="clearningSelect"
                                style="font-size: 17px;display: inline; font-weight: bold; color: #080056">Clearing- och
                            kontonummer
                        </button>
                        <!-- Clearing Select -->
                        <div v-if="bank===1" id="clearingsnummer">
                            <label>
                                <input class="input" placeholder="Clearingsnummer"
                                       style="margin-bottom: 15px;  margin-top: 18px"
                                       v-model="digits"/>
                            </label>
                            <label>
                                <input class="input" placeholder="Kontonummer" style="margin-bottom: 15px"
                                       v-model="account_number"/>
                            </label>
                        </div>
                         <!-- IBAN Select -->
                        <div v-if="bank===2" id="iban">
                            <label>
                                <input class="input" placeholder="IBAN" style="margin-bottom: 15px;  margin-top: 18px"
                                       v-model="iban"/>
                            </label>
                        </div>
                        <div v-if="dlId===1" style="margin-top: -25px">
                            <p style="display: block; font-size: 27px; color: black; font-weight: bold; margin-top: 42px; text-align: center; float: left">
                                Ladda upp bilder på fram- och <br>baksidan av
                                ett giltigt identitesbevis.</p>
                            <form method='POST' enctype="multipart/form-data">

                                <div id="preview" style=" display: inline-flex; margin-bottom: 32px">
                                    <div style="width: 215px; height: 215px; margin-right: 45px; text-align: center;">
                                        <label role="button" for="front" style=" color: #080056; font-size: 19px">Framsida
                                            <v-img style="width: 215px; height: 215px; border-radius: 10px"
                                                   :src="url_front"></v-img>
                                        </label>
                                    </div>
                                    <div role="button" style="width: 215px; height: 215px; text-align: center;">
                                        <label role="button" for="back" style="color: #080056; font-size: 19px">Baksida
                                            <v-img style="width: 215px; height: 215px; border-radius: 10px"
                                                   :src="url_back"></v-img>
                                        </label>
                                    </div>
                                </div>


                                <input id="front" name='uploadedImages' multiple='multiple' type="file"
                                       @change="frontPreview" accept="image/*" hidden/>

                                <input id="back" type="file"
                                       @change="backPreview" accept="image/*" hidden/>
                            </form>
                        </div>
                        <button @click="verifyId"
                                v-if="url_back==='/img/empty_photo.08921a38.png' || url_front==='/img/empty_photo.08921a38.png'"
                                style="margin-top: 40px ;font-size:31px; width: 475px; height: 91px; color: #080056; border-radius: 15px; border: #080056 solid 2px; text-align: center; font-weight: bold">
                            Verifiera identitet
                        </button>
                        <button @click="verifyId"
                                v-if="url_back!=='/img/empty_photo.08921a38.png' && url_front!=='/img/empty_photo.08921a38.png'"
                                style="margin-top: 40px ;font-size:31px; width: 475px; height: 91px; color: #080056; border-radius: 15px; border: #080056 solid 2px; text-align: center; font-weight: bold">
                            Bilder uppladdade
                        </button>
                    </div>
                    <div>
                        <br>
                        <v-btn :disabled="isDisabled" :loading="loading" @click="sendBankCredentials" class="slutfor"
                               style="text-transform: none ;width: 475px; height: 73px; font-size: 33px;  margin-left: auto; margin-right: auto; font-weight: bold; color: white; background: #080056; border-radius: 50px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);">
                            Slutför
                        </v-btn>
                        <br><br>
                        <p style="margin-right: 200px; text-align: center; width: 475px; font-size: 17px">Genom att
                            trycka pä "Slutför"
                            godkänner du <br>
                            <a target="_blank"
                               href="https://stripe.com/en-se/connect-account/legal"
                               style="color: #0E046E; text-decoration: none; font-weight: bold">villkoren</a> för Stripe
                            Connect. <br>
                            Undrar du nägot? <a target="_blank" href="https://fastpark.se/kontakt-oss/"
                                                style="font-weight: bold; color: #0E046E; text-decoration: none">Kontakta
                                support </a></p>
                    </div>
                </v-col>
                <!-- Right body content -->
                <v-col cols="6">
                    <v-img class="ml-3" contain height="450px"
                           style="margin-top: 25px; width: 558px; height: 457px; align-content: center; text-align: center"
                           :src="require('../assets/bank_account.png')"></v-img>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import Header from "./Header";
    import SettingsApi from "../services/SettingsApi";
    import axios from 'axios';
    import firebase from "firebase";

    export default {
        data() {
            return {
                dlId: 0,
                bank: 1,
                firstName: '',
                lastName: '',
                birthday: '',
                address: '',
                postcode: '',
                city: '',
                email_address: '',
                phone_number: '',
                iban: '',
                digits: '',
                account_number: '',
                front: null,
                back: null,
                frontData: null,
                backData: null,
                files: null,
                gender: null,
                genderchoose: null,
                userId: '',
                error_iban: '',
                url_front: require('../assets/empty-picture.jpg'),
                url_back: require('../assets/empty-picture.jpg'),
                photos_uploaded: '',
                loader: null,
                loading: false,
            }
        },
        beforeCreate() {
            document.title = "Koppla bankkonto"
        },
        components: {
            Header
        },
        props: ['user'],
        mounted() {
            let vm = this;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    try {
                        vm.userId = firebase.auth().currentUser.uid
                    } catch (e) {
                        console.log(e)
                    }
                }
            })
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]
                setTimeout(() => (this[l] = false), 6000)
                this.loader = null
            },
            gender: function (value) {
                if (value === 0) {
                    this.genderchoose = 'male'
                } else if (value === 1) {
                    this.genderchoose = 'female'
                }
            },
        },
        computed: {
            isDisabled() {
                return this.checkInput()
            }
        },
        methods: {
            pushToParking() {
                this.$router.push({name: 'ParkingSpots'})
            },
            checkInput() {
                let returnvalue = true
                if (this.address && this.postcode && this.city && this.firstName && this.lastName && this.birthday && this.email_address && this.phone_number) {
                    if (this.iban || (this.digits && this.account_number)) {
                        if (this.front && this.back) {
                            returnvalue = false
                        }
                    }
                }
                return returnvalue
            },
            acceptNumber() {
                var x = this.phone_number.replace(/[^0-9]/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
                this.phone_number = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
            },
            scrollToEnd: function () {
                var objDiv = document.getElementById("test");
                objDiv.scrollTop = objDiv.scrollHeight;
            },
            acceptSocial() {
                var x = this.birthday.replace(/[^0-9]/g, '').match(/(\d{0,4})(\d{0,2})(\d{0,2})/);
                this.birthday = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '');
            },
            frontPreview(e) {
                this.front = e.target.files[0];
                this.imageData = e.target.files[0]
                this.url_front = URL.createObjectURL(this.front);

            },
            backPreview(e) {
                this.back = e.target.files[0]
                this.imageData = e.target.files[0]
                this.url_back = URL.createObjectURL(this.back);
            },
            ibanSelect() {
                this.bank = 2;
                console.log(this.bank)
                return this.bank
            },
            clearningSelect() {
                this.bank = 1;
                console.log(this.bank)
                return this.bank
            },
            verifyId() {
                setTimeout(this.scrollToEnd, 100)
                if (this.dlId === 0) {
                    this.dlId = 1;
                    return this.dlId
                } else if (this.dlId === 1) {
                    this.dlId = 0;
                    return this.dlId
                }
            },
            sendBankCredentials() {
                this.loader = 'loading';
                //clearingsnummer
                let credentials = {};
                let dob_year = this.birthday.substring(0, 4)
                let dob_month = this.birthday.substring(5, 7)
                let dob_day = this.birthday.substring(8, 10)
                let without_zero = this.phone_number;
                if (without_zero.charAt(0) === '0') {
                    without_zero = without_zero.substring(1);
                }
                let phone_number_formatted = without_zero.replace(/\s/g, '');
                let newStr = phone_number_formatted.replace(/-/g, "");
                let new_phone_number = '+46' + newStr;
                if (this.bank === 1) {
                    let url = "https://api.iban.com/clients/api/calc-api.php?api_key=f644ea2e13261dcda80a150c37b3320b&format=json&country=SE&format=json&bankcode=" + this.digits + "&account=" + this.account_number
                    SettingsApi.GetIban({
                        params: {
                            'url': url
                        }
                    }).then(res => {
                        if (res.data['iban']) {
                            this.iban = res.data['iban']
                        } else {
                            this.error_iban = res.data['error']
                        }
                    })
                }
                //IBAN
                if (this.iban) {
                    credentials = {
                        type: this.bank,
                        firstName: this.firstName,
                        lastName: this.lastName,
                        dob_day: dob_day,
                        dob_month: dob_month,
                        dob_year: dob_year,
                        gender: this.genderchoose,
                        address: this.address,
                        postcode: this.postcode,
                        city: this.city,
                        email_address: this.email_address,
                        phone_number: new_phone_number,
                        iban: this.iban,
                        userId: this.userId
                    };
                    const formData = new FormData();

                    formData.append('files', this.front);
                    formData.append('files', this.back);

                    for (const key in credentials) {
                        formData.append(key, credentials[key]);
                    }
                    try {
                        let url = '';
                        if (process.env.NODE_ENV === "development") {
                            url = 'http://localhost:5005/upload'
                        } else if (process.env.NODE_ENV === "production") {
                            url = 'https://fastpark-cbda0.uc.r.appspot.com/upload'
                        }
                        axios.post(url, formData);
                        setTimeout(() => this.$router.push({name: 'ParkingSpots'}), 5000);

                    } catch(e) {
                        console.log(e)
                    }
                }
            }
        }
    }
</script>

<style scoped>
    html {
        height: 100%;
    }

    .right-col {

        float: right;
        margin-right: 47px;
        height: 84vh;
        width: 50%;
        margin-top: 20px;
        text-align: left;
        padding: 0;
    }

    .input {
        margin-bottom: 25px;
        padding-left: 30px;
        font-weight: bold;
        font-family: Proxima_bold, sans-serif;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: x-large;
        background: #eeeeee;
        width: 480px;
        height: 91px;
    }

    input:focus {
        outline: none;
    }

    v-row {
        height: 100%;
    }

    .body {
        width: 100%;
        float: right;
    }

    .row {
        margin-left: 55px;
        float: right;
        position: fixed;
        height: 80vh;
    }

    .slutfor {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        color: white;
        font-size: 30px;
        width: 460px;
        height: 73px;
        background: #080056;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar {
        display: none;
    }

    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #b5b2d3 !important;
    }

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

</style>