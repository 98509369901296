<template>
    <div style="display: flex">
        <div class="body">
            <!-- Header -->
            <Header title="Chattar"/>
            <pulse-loader style="text-align: center; margin-top: 45vh" :loading="loading_spinner" :color="color"
                          :size="size">
            </pulse-loader>
            <div v-if="empty_chats===true">
                <EmptyChat/>
            </div>
            <v-row class="row" v-if=" Object.keys(chats).length > 0">
                <v-col class="left-col">
                    <div class="inbox_chat">
                        <button @click="showMessages(conv, index)" class="chat_list"
                                v-for="(conv, index) in chats" v-bind:key="conv" style="height: 122px">
                            <div class="chat_people">
                                <div class="chat_img" style="margin-right: 20px;">
                                    <v-avatar v-if="conv[conv.length - 1]!=='null'"
                                              style="margin-top: 5px; border: solid black 1px; border-radius: 50px; height: 75px !important; width: 75px !important; display: inline-block ; margin-right: 16px">
                                        <v-img
                                                :src="conv[conv.length - 1]"
                                                alt="no photo"
                                        >
                                        </v-img>
                                    </v-avatar>
                                    <div v-else>
                                        <v-avatar role="button" size="50" v-if="typeof conv[0]==='string'"
                                                  style="background: #080056; color: #FFFFFF; height: 75px ; width: 75px">
                                            {{ conv[2] }}
                                        </v-avatar>
                                        <v-avatar role="button" size="50" v-else
                                                  style="background: #080056; color: #FFFFFF; height: 75px ; width: 75px">
                                            {{ conv[0]['initial'] }}
                                        </v-avatar>
                                    </div>
                                </div>
                                <div class="chat_ib" style="margin-left: 20px">
                                    <p style=" text-align: center; line-height: 50px; display:inline-block; font-weight: bold; font-size: 25px; float: left"
                                       v-if="conv[0]['bookerName']">{{
                                        conv[0]['bookerName'].split(' ')[0] }}</p>
                                    <p style=" text-align: center; line-height: 50px;display:inline-block; font-weight: bold; font-size: 25px; float: left"
                                       v-else>{{
                                        conv[0].split(' ')[0] }}</p>
                                    <v-icon style="display:inline-block; float: right; line-height: 85px; font-size: 30px">
                                        chevron_right
                                    </v-icon>
                                    <p style="color: #9E9E9E; margin-left: -61px; float: left; margin-top: 45px; font-size: 20px"
                                       v-if="conv[conv.length-2]['messageText']">
                                        {{conv[conv.length-2]['messageText'].substring(0, 54) }}</p>
                                    <p style="color: #9E9E9E; margin-left: -61px; float: left; margin-top: 45px; font-size: 20px" v-else>
                                        {{conv[conv.length-3]['messageText'].substring(0, 54) }}</p>
                                     <p style="color: #9E9E9E; float: left; margin-top: 45px; font-size: 20px" v-if="conv[conv.length-3]['messageText'] && conv[conv.length-3]['messageText'].length>53">
                                            ...
                                        </p>
                                        <p style="color: #9E9E9E; float: left; margin-top: 45px; font-size: 20px" v-if="conv[conv.length-2]['messageText'] && conv[conv.length-2]['messageText'].length>53">
                                            ...
                                        </p>
                                </div>
                            </div>
                        </button>
                    </div>
                </v-col>
                <v-col class="right-col">
                    <div id="message" style="display: none">
                        <div class="avatar_chat"
                             style="border-bottom: lightgray solid 2px; width: 100%; height: 94px; margin: 0">
                            <div style="display: inline-block; height: 50px; margin-right: 10px;">
                                <div style="height: auto; width: auto; display: inline-block; position: fixed; margin-left: -45px; margin-top: 13px;">
                                    <v-img v-if="chat_avatar !== 'null'"
                                           :src="chat_avatar"
                                           alt="no photo"
                                           style="border: solid black 1px; border-radius: 50px; height: 55px !important; width: 55px !important; display: flex"
                                    >
                                    </v-img>
                                    <v-avatar v-if="chat_avatar === 'null'" role="button"
                                              style="background: #080056; color: #FFFFFF; height: 55px; width: 55px;">
                                        {{ current_initial }}
                                    </v-avatar>
                                </div>
                                <div style="display: inline-flex">
                                    <p style="margin-left: 20px; margin-top: 0; float: right; line-height: 77px; font-weight: bold; font-size: 35px">
                                        {{ chat_name.split(' ')[0] }}</p>
                                </div>
                            </div>
                        </div>
                        <div id="megs_received" style="height: 80vh">
                            <div class="msg_history" id="chat_messages" v-if="messages!=='null'">
                                <div v-for="msg in messages" v-bind:key="msg" class="received_msg">
                                    <div v-if="msg['senderUID'] === currentUser"
                                         class="talk-bubble round tri-right btm-right-in"
                                         style="background:  #0090CC; float: right; width: 320px;">
                                        <div class="talktext" style="width: auto">
                                            <p style="width: auto; color: white; font-size: 18px">{{ msg['messageText']
                                                }}</p>
                                        </div>
                                        <p v-if="msg['senderUID'] === currentUser"
                                           style="float: left;font-weight: bold; display: block; margin-bottom: -50px; color: lightgray; font-size: 11px;">
                                            {{
                                            msg['created'].getHours()
                                            }}:{{ msg['created'].getMinutes() }} |
                                            {{ msg['created'].getDate() }} {{ monthNames[msg['created'].getMonth()]
                                            }}</p>
                                    </div>

                                    <div v-if="msg['senderUID'] !== currentUser"
                                         class="talk-bubble round tri-right btm-left-in"
                                         style="background: #808080; float: left; width: 320px;">
                                        <div style="width: auto" class="talktext">
                                            <p style="width: auto; color: white; font-size: 18px">{{ msg['messageText']
                                                }}</p>
                                        </div>
                                        <p v-if="msg['senderUID'] !== currentUser"
                                           style="float: right;font-weight: bold; display: block; margin-bottom: -50px; color: lightgray; font-size: 11px;">
                                            {{ msg['created'].getHours() }}:{{ msg['created'].getMinutes() }} |
                                            {{ msg['created'].getDate() }} {{ monthNames[msg['created'].getMonth()]
                                            }}</p>

                                    </div>
                                </div>
                            </div>

                            <div class="msg_history" v-if="messages==='null'">
                                <div class="text-center circle"><i
                                        style="color: white; margin-top: 25%; font-size: 67px"
                                        class="fas fa-pencil-alt"></i></div>
                                <h1 style="color: rgb(178,178,178);">Skriv det första meddelandet</h1>
                            </div>
                            <div
                                    style="display: flex ; box-sizing: border-box;height: 55px; width: 60vh; border: 1px solid #000000; border-radius: 40px; background-color: #FFFFFF; box-shadow: 0 2px 10px 0 rgba(0,0,0,0.21); margin-right: auto; margin-left: auto ; margin-top:  5px">
                                <input @keyup.enter="sendMessage" v-model="message" type="text"
                                       style="font-weight: bold; width:  60vh; min-height: 55px; display: inline-block; margin-left: 20px; font-size: 21px; color: black"
                                       class="write_msg" placeholder="Skriv ett meddelande…"/>
                                <button @click="sendMessage" type="button"><i role="button"
                                                                              class="fas fa-arrow-circle-up"
                                                                              style="height: 40px; width: 40px; margin-top: 5px; margin-bottom: 5px; margin-right: 5px;"
                                                                              @click="sendMessage"
                                                                              aria-hidden="true"></i></button>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import PulseLoader from 'vue-spinner/src/SyncLoader.vue'
    import Header from "./Header";
    import firebase from "firebase";
    import ChatsApi from "@/services/ChatsApi";
    import EmptyChat from "./EmptyChat";

    export default {
        name: "Chat",
        components: {
            EmptyChat,
            PulseLoader,
            Header
        },
        beforeCreate() {
            document.title = "Chattar"
        },
        data() {
            return {
                chats: [],
                messages: [],
                currentUser: '',
                message: '',
                indexConv: null,
                receiverUID: null,
                newmessages: [],
                avatar: '',
                testavatar: '',
                update: false,
                chat_avatar: null,
                initial_chat: null,
                chat_name: '',
                sender_id_redirect: null,
                monthNames: ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
                ],
                current_initial: '',
                color: '#0E046E',
                size: '45px',
                margin: '2px',
                radius: '2px',
                loading_spinner: true,
                empty_chats: false
            }
        },
        created() {
            let vm = this;
            firebase.auth().onAuthStateChanged(user => {
                if (user) {
                    try {
                        vm.currentUser = user.uid;
                        ChatsApi.getChats({
                            params: {
                                'current': user.uid
                            }
                        }).then(res => {
                            if (Object.keys(res.data).length > 0) {
                                let i = 0;
                                Object.keys(res.data).forEach(el => {
                                    let my_el = el;
                                    i += 1;
                                    let booker_user_image = '';
                                    if (typeof res.data[my_el][0] === 'string') {
                                        booker_user_image = res.data[my_el][3];
                                        firebase.storage().ref('userProfileImages/' + booker_user_image + ".jpg").getDownloadURL().then(imgUrl => {
                                            vm.avatar = imgUrl
                                        }).then(r => {
                                            console.log(r);
                                            res.data[my_el].push(vm.avatar);
                                        }).catch(e => {
                                            res.data[my_el].push('null');
                                            console.log(e)
                                        })
                                    } else {
                                        booker_user_image = res.data[my_el][0]['bookerImageURL'];
                                        firebase.storage().ref('userProfileImages/' + booker_user_image + ".jpg").getDownloadURL().then(imgUrl => {
                                            vm.avatar = imgUrl;
                                        }).then(r => {
                                            console.log(r);
                                            res.data[my_el].push(vm.avatar);
                                        }).catch(e => {
                                            res.data[my_el].push('null');
                                            console.log(e)
                                        })
                                    }
                                });
                                if (i === 2) {
                                    vm.chats = res.data;
                                    if (this.$route.params.senderUID.length > 0) {
                                        Object.keys(vm.chats).forEach(el => {
                                            if (typeof vm.chats[el][0] === 'string') {
                                                if (this.$route.params.senderUID === vm.chats[el][1]) {
                                                    firebase.storage().ref('userProfileImages/' + vm.chats[el][3] + ".jpg").getDownloadURL().then(imgUrl => {
                                                        vm.avatar = imgUrl
                                                    }).then(r => {
                                                        console.log(r);
                                                        vm.chats[el].push(vm.avatar);
                                                        this.showMessages(vm.chats[el], el)
                                                    }).catch(e => {
                                                        vm.chats[el].push('null');
                                                        console.log(e);
                                                        this.showMessages(vm.chats[el], el)
                                                    })
                                                }
                                            } else {
                                                if (this.$route.params.senderUID === vm.chats[el][0]['bookerUserID']) {
                                                    firebase.storage().ref('userProfileImages/' + vm.chats[el][0]['bookerImageURL'] + ".jpg").getDownloadURL().then(imgUrl => {
                                                        vm.avatar = imgUrl
                                                    }).then(r => {
                                                        console.log(r);
                                                        vm.chats[el].push(vm.avatar);
                                                        this.showMessages(vm.chats[el], el)
                                                    }).catch(e => {
                                                        vm.chats[el].push('null');
                                                        console.log(e);
                                                        this.showMessages(vm.chats[el], el)
                                                    })
                                                }
                                            }
                                        })
                                    }
                                }

                                if (Object.keys(vm.chats).length === 0) {
                                    this.loading_spinner = false;
                                    this.empty_chats = true
                                } else {
                                    vm.loading_spinner = false;
                                    this.empty_chats = false
                                }
                            } else {
                                vm.loading_spinner = false;
                                vm.empty_chats = true
                            }
                        }).catch(() => {
                            vm.loading_spinner = false;
                            vm.empty_chats = false;
                        })
                    } catch (e) {
                        vm.loading_spinner = false;
                        vm.empty_chats = true;
                    }
                } else {
                    this.authUser = {}
                }
            });
        },
        methods: {
            showMessages(conv, index) {
                if(typeof conv[0]==='string'){
                    this.current_initial = conv[2];
                }else{
                     this.current_initial = conv[0]['initial']
                }
                this.indexConv = index;
                var y = document.getElementById("message");
                if (y.style.display === "none") {
                    y.style.display = "block"
                }
                if (typeof conv[0] === "object") {
                    //this.messages = conv
                    this.fetchMessages();
                    this.chat_avatar = conv[conv.length - 1];
                    this.initial_chat = conv[0]['bookerName'].charAt(0);
                    this.chat_name = conv[0]['bookerName'];
                    setTimeout(this.scrollToEnd, 100)
                } else {
                    this.chat_avatar = conv[conv.length - 1];
                    this.initial_chat = conv[0].charAt(0);
                    this.chat_name = conv[0];
                    this.messages = 'null'
                }
            },
            sendMessage() {
                // save to firestore
                let vm = this;
                window.db.collection('chats')
                    .doc(this.indexConv)
                    .get()
                    .then(snapshot => {
                        const document = snapshot.data()
                        // do something with document
                        window.db.collection('chats').doc(vm.indexConv).collection('messages').add({
                            messageText: vm.message,
                            senderUID: document['renterUserID'],
                            receiverUID: document['bookerUserID'],
                            created: new Date()
                        })
                    }).then(r => {
                    this.fetchMessages();
                    setTimeout(this.scrollToEnd, 100);
                    console.log(r);
                    this.message = null
                });
            },
            scrollToEnd: function () {
                var container = document.getElementById("chat_messages");
                container.scrollTop = container.scrollHeight;
            },
            fetchMessages() {
                window.db.collection('chats').doc(this.indexConv).collection('messages').orderBy('created').onSnapshot((querySnapshot) => {
                    let allMessages = [];
                    querySnapshot.forEach(doc => {
                        allMessages.push(doc.data())
                    });
                    this.messages = allMessages;
                });
            }
        }
    }
</script>

<style scoped>
    .row {
        position: fixed;
        height: 100%;
    }

    .right-col {
        padding-left: 0;
        float: right;
        margin-right: 47px;
        height: 80vh;
        width: 90vh;
        text-align: center;

    }

    .left-col {
        padding-right: 0;
        float: left;
        border-right: solid #0E046E 1px;
        margin-left: 45px;
        height: 90vh;
        width: 90vh;
    }


    html {
        height: 100%;
    }


    .body {
        overflow-y: hidden; /* Hide vertical scrollbar */
        overflow-x: hidden; /* Hide horizontal scrollbar */
        width: 100%;
        float: right;
    }

    .container {
        max-width: 1170px;
        margin: auto;
    }

    img {
        max-width: 100%;
    }


    .recent_heading h4 {
        color: #0090CC;
        font-size: 21px;
        margin: auto;
    }

    .chat_ib h5 {
        font-size: 15px;
        color: #464646;
        margin: 0 0 8px 0;
    }

    .chat_ib h5 span {
        font-size: 13px;
        float: right;
    }

    .chat_img {
        float: left;
        width: 11%;
    }

    .chat_list {
        display: grid;
        width: 100%;
        border-bottom: 1px solid #DFDFDF;
        margin: 0;
        padding: 18px 16px 10px;
        height: auto;
    }

    .inbox_chat {
        height: 80vh;
        overflow-y: scroll;
    }

    .active_chat {
        background: #ebebeb;
    }

    .received_msg {
        word-wrap: break-word;
        display: inline-block;
        width: 100%;
        margin-bottom: -40px;
    }

    .time_date {
        color: #747474;
        display: block;
        font-size: 12px;
        margin: 8px 0 0;
    }

    .received_withd_msg {
        width: 57%;
    }

    .mesgs {
        float: right;
        width: 60%;
    }

    .outgoing_msg {
        overflow: hidden;
        margin: 26px 0 26px;
    }

    .sent_msg {
        float: right;
        width: 46%;
    }

    .input_msg_write input {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #4c4c4c;
        font-size: 15px;
        min-height: 48px;
        width: 100%;
    }

    .type_msg {
        background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
        border: medium none;
        color: #4c4c4c;
        font-size: 15px;
        min-height: 48px;
        width: 100%;
        border-top: 1px solid #c4c4c4;
        position: relative;
    }


    .messaging {
        padding: 0 0 50px 0;
    }

    .msg_history {
        height: 80%;
        overflow-y: auto;
    }

    /* CSS talk bubble */
    .talk-bubble {
        margin: 40px;
        display: inline-block;
        position: relative;
        max-width: 320px;
        height: auto;
        background-color: lightyellow;
    }

    .border {
        border: 1px solid #666;
    }

    .round {
        border-radius: 10px;
        -webkit-border-radius: 10px;
        -moz-border-radius: 10px;

    }

    /* Right triangle placed top left flush. */
    .tri-right.border.left-top:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -40px;
        right: auto;
        top: -8px;
        bottom: auto;
        border: 32px solid;
        border-color: #666 transparent transparent transparent;
    }

    .tri-right.left-top:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -20px;
        right: auto;
        top: 0px;
        bottom: auto;
        border: 22px solid;
        border-color: lightyellow transparent transparent transparent;
    }

    /* Right triangle, left side slightly down */
    .tri-right.border.left-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -40px;
        right: auto;
        top: 30px;
        bottom: auto;
        border: 20px solid;
        border-color: #808080 #808080 transparent transparent;
    }

    .tri-right.left-in:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -20px;
        right: auto;
        top: 38px;
        bottom: auto;
        border: 12px solid;
        border-color: lightyellow lightyellow transparent transparent;
    }

    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: -8px;
        right: auto;
        top: auto;
        bottom: -40px;
        border: 32px solid;
        border-color: transparent transparent transparent #808080;
    }

    .tri-right.btm-left:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0px;
        right: auto;
        top: auto;
        bottom: -20px;
        border: 22px solid;
        border-color: transparent transparent transparent lightyellow;
    }

    /*Right triangle, placed bottom left side slightly in*/
    .tri-right.border.btm-left-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 30px;
        right: auto;
        top: auto;
        bottom: -40px;
        border: 20px solid;
        border-color: #808080 transparent transparent #808080;
    }

    .tri-right.btm-left-in:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: 0;
        top: auto;
        bottom: -16px;
        border: 12px solid;
        border-color: #808080 transparent transparent #808080;
    }

    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 30px;
        bottom: -40px;
        border: 20px solid;
        border-color: #808080 #808080 transparent transparent;
    }

    .tri-right.btm-right-in:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 0;
        bottom: -16px;
        border: 12px solid;
        border-color: #0090CC #0090CC transparent transparent;
    }

    /*Right triangle, placed bottom right side slightly in*/
    .tri-right.border.btm-right:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -8px;
        bottom: -40px;
        border: 20px solid;
        border-color: #808080 #808080 transparent transparent;
    }

    .tri-right.btm-right:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: 0px;
        bottom: -20px;
        border: 12px solid;
        border-color: #0090CC #0090CC transparent transparent;
    }

    /* Right triangle, right side slightly down*/
    .tri-right.border.right-in:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: 30px;
        bottom: auto;
        border: 20px solid;
        border-color: #808080 transparent transparent #808080;
    }

    .tri-right.right-in:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -20px;
        top: 38px;
        bottom: auto;
        border: 12px solid;
        border-color: lightyellow transparent transparent lightyellow;
    }

    /* Right triangle placed top right flush. */
    .tri-right.border.right-top:before {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -40px;
        top: -8px;
        bottom: auto;
        border: 32px solid;
        border-color: #666 transparent transparent transparent;
    }

    .tri-right.right-top:after {
        content: ' ';
        position: absolute;
        width: 0;
        height: 0;
        left: auto;
        right: -20px;
        top: 0px;
        bottom: auto;
        border: 20px solid;
        border-color: lightyellow transparent transparent transparent;
    }

    /* talk bubble contents */
    .talktext {
        padding: 1em;
        text-align: left;
        line-height: 1.5em;
    }

    .talktext p {
        /* remove webkit p margins */
        -webkit-margin-before: 0em;
        -webkit-margin-after: 0em;
    }

    ::-webkit-scrollbar {
        display: none;
    }

    input:focus {
        outline: none;
    }

    .circle {
        margin-bottom: 33px;
        margin-top: 25%;
        margin-left: auto;
        margin-right: auto;
        background: rgb(178, 178, 178);
        border-radius: 50%;
        width: 137px;
        height: 137px;
    }

    .write_msg::placeholder {
        color: #ADADAD
    }
</style>