<template>
    <div>
        <div style=" text-align: center; height: 100%">
            <div
                    style="  min-height: 100%;margin-left: 45px; margin-right: 45px; text-align: center; padding-top: 10%; font-weight: bold; color: #B3B3B3">
                <i class="fas fa-car fa-10x"></i>
                <p style="font-weight: bold; font-size: 50px; color: darkgrey">Inga parkeringsplatser</p>
                <p style="font-weight: bold; font-size: 24px; margin-top: -15px">Tryck på registrera parkeringsplats för att</p><br>
                <p style="margin-top: -35px; font-weight: bold; font-size: 24px">komma igång</p>
                <router-link :to="{name: 'Addparkings'}">
                    <button style="width: 308px; height: 73px; background: rgb(14,4,110); border-radius: 50px; color: #FFFFFF; margin-top: 60px; font-size: 22px">
                        Registrera parkering
                    </button>
                </router-link>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "EmptyParkingSpot",
    }
</script>

<style scoped>
    .body {
        width: 100%;
        float: right;
    }
</style>