import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import 'vuetify/dist/vuetify.min.css'
import router from './router'
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'
import firebase from 'firebase'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';

require('firebase/firestore');
import {sync} from 'vuex-router-sync'
import store from './store/store'
import Vuelidate from 'vuelidate'
import VCalendar from 'v-calendar'
import {BootstrapVue, IconsPlugin} from 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import VueSimpleAlert from "vue-simple-alert";
Vue.use(VueSimpleAlert);

Vue.use(Vuelidate);
Vue.use(Antd);
// Use v-calendar & v-date-picker components
Vue.use(VCalendar, {
    componentPrefix: 'vc'
});

Vue.config.productionTip = false;
sync(store, router);


// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin)

new Vue({
    router,
    vuetify,
    store,
    template: '<App :user="user" :current_user="current_user" ></App>',
    components: {App},
    render: h => h(App),
    //
    data() {
        return {
            user: {},
            current_user: firebase.auth().currentUser.uid
        }
    },
    methods: {
        getLoginStatus() {
            let vm = this;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    vm.user = user;
                    vm.current_user = firebase.auth().currentUser.uid
                    //console.log("// User is signed in by Phone Number : ", user.phoneNumber)
                    //console.log(user)
                } else {
                    vm.user = null;
                    console.log("// No user is signed in.")
                }
            });
        },
        initFirebase() {
            firebase.initializeApp({
                apiKey: "AIzaSyBLLE6ni5oWpJZCmB-DimuH0acgdWGgS9I",
                authDomain: "fastpark-cbda0.firebaseapp.com",
                databaseURL: "https://fastpark-cbda0.firebaseio.com",
                projectId: "fastpark-cbda0",
                storageBucket: "fastpark-cbda0.appspot.com",
                messagingSenderId: "814299855643",
                appId: "1:814299855643:web:b10f7093ab077f9dd8b1be"
            });
        },
    },
    updated() {
        this.getLoginStatus()
    },
    created() {
        this.initFirebase();
        firebase.firestore().enablePersistence();
        this.getLoginStatus();
        window.db = firebase.firestore();
    },

}).$mount('#app');
