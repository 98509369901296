<template>
    <div style="display: flex">
        <div class="body">
            <!-- Body -->
            <v-row class="row">
                <!-- Left body content -->
                <v-col class="left-col">
                    <button class="button" @click="showAdress()" style="margin-bottom: 20px">
                        <div style="float: left">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Adress</p>
                            <p style="font-size: 17px; font-weight: bold" v-if="address">{{ address }} {{ zipcode }} {{
                                city }} &nbsp;</p>
                            <p style="font-size: 17px; font-weight: bold" v-if="address === '' || address === null">
                                Tryck för att ändra</p>
                        </div>
                        <div>
                            <p v-if="arrow_add === true" style="margin-left: 10px; color: #080056; float: right">
                                <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                    east
                                </v-icon>
                            </p>
                        </div>
                    </button>
                    <button class="button" @click="showTider()" style="margin-bottom: 20px">
                        <div style="float: left">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Tider</p>
                            <p style="font-size: 17px; font-weight: bold"
                               v-if="checktider === 'Specifika' || checktider === 'Langtidsuthyrning'">
                                från {{range.start.toString().substr(0, 16)}} till {{range.end.toString().substr(0,
                                16)}}</p>
                            <p style="font-size: 17px; font-weight: bold"
                               v-if="days && checktider === 'Aterkommande' && hourstart && hourend">
                                <span v-for="day in daysName" v-bind:key="day">{{day}}, </span>
                                <span>{{ moment(hourstart).format("HH:mm")}} - {{ moment(hourend).format("HH:mm") }}</span>
                            </p>
                        </div>
                        <div>
                            <p v-if="arrow_tid === true" style="margin-left: 10px; color: #080056; float: right">
                                <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                    east
                                </v-icon>
                            </p>
                        </div>
                    </button>
                    <button class="button" @click="showInfo()" style="margin-bottom: 20px">
                        <div style="float: left">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">
                                Information</p>
                            <p style="display: inline-block; font-size: 17px; font-weight: bold">{{ typechosen }}, {{
                                specchosen }},</p>
                            <p style="display: inline-block; font-size: 17px; font-weight: bold" v-if="isRoof===true">
                                Under tak, &nbsp;
                            </p>
                            <p style="display: inline-block; font-size: 17px; font-weight: bold"
                               v-if="isCharging===true">
                                Laddningstation, &nbsp;
                            </p>
                            <p style="display: inline-block; font-size: 17px; font-weight: bold"
                               v-if="isHandicap===true">
                                Handikappsanpassad &nbsp;
                            </p>
                        </div>
                        <div>
                            <p v-if="arrow_inf === true" style="margin-left: 10px; color: #080056; float: right">
                                <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                    east
                                </v-icon>
                            </p>
                        </div>
                    </button>
                    <button class="button" @click="showBild()" style="margin-bottom: 20px">
                        <div style="float: left">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Bild</p>
                            <p style="font-size: 17px; font-weight: bold">Du har valt en bild</p>
                        </div>
                        <div>
                            <p v-if="arrow_bil === true" style="margin-left: 10px; color: #080056; float: right">
                                <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                    east
                                </v-icon>
                            </p>
                        </div>
                    </button>
                    <button class="button" @click="showPris()" style="margin-bottom: 49px">
                        <div style="float: left">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Pris</p>
                            <p v-if="price" style="font-size: 17px; font-weight: bold">{{price}} kr</p>
                            <p v-else style="font-size: 17px; font-weight: bold">Tryck för att ändra</p>
                        </div>
                        <div>
                            <p v-if="arrow_pri === true" style="margin-left: 10px; color: #080056; float: right;">
                                <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                    east
                                </v-icon>
                            </p>
                        </div>

                    </button>

                    <v-btn class="edit"
                           style="box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2); text-transform: none ;font-weight: bold; color: white; font-size: 25px;width: 475px; height: 73px; background: #0E046E; border-radius: 50px; margin: 20px 20px 50px 11%;"
                           :disabled="isDisabled"
                           @click="editParkingSpot" :loading="loading">
                        Spara
                    </v-btn>
                </v-col>

                <!-- Right body content -->
                <v-col class="right-col" style="margin-left: 39px">
                    <!-- Adress -->
                    <div id="A"
                         style="float: left; width: 70vh; overflow-y: scroll; overflow-x: -moz-hidden-unscrollable">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Adress</p>
                        <div style="display: grid; width: 100%; margin-bottom: 38px">
                            <p style="float: left; color: black; font-weight: bold; font-size: 19px; margin-bottom: 18px">
                                Ange adress:</p>
                            <input placeholder="Adress" class="input" v-model="address">
                            <input placeholder="Postkod" class="input" v-model="zipcode">
                            <input placeholder="Stad" class="input" v-model="city">
                        </div>
                        <div class="Ja_nej" style="margin-bottom: 43px; width: 70vh">
                            <div style="font-size: 30px; margin-bottom: 20px">
                                <input id="JA" name="JA_NEJ" type="radio" class="with-font" v-model="ja_nej"
                                       v-bind:value="'ja'" @click="show_and_scroll_Ja_Nej()"
                                       :checked="parking.isAnonymousProfile === true"/>
                                <label style="width: 100%; font-size: 40px" for="JA">Ja</label>
                            </div>
                            <div style="font-size: 30px;">
                                <input style="width: 100%" id="NEJ" name="JA_NEJ" type="radio" class="with-font"
                                       v-model="ja_nej"
                                       v-bind:value="'nej'"
                                />
                                <label style="width: 100%; font-size: 40px" for="NEJ">Nej</label>
                            </div>
                        </div>
                        <div v-if="ja_nej === 'ja'" style="font-size: 30px;  width: 70vh; margin-bottom: 60px">
                            <input style="width: 100%" id="anonym" name="anonym" type="checkbox"
                                   class="with-font"
                                   v-model="anonym" v-bind:value="'anonym'"
                                   :checked="parking.isAnonymousProfile === true"
                            />
                            <label style="width: 100%; font-size: 40px; color: black; margin-bottom: -25px; margin-top: -15px"
                                   for="anonym">Vill du vara anonym?</label>
                            <p style="color:black; font-size: 16px; margin-top: 1px">Din profilbild och förnamn
                                kommer vara dolt
                                för <br>hyraren.</p>
                        </div>
                    </div>

                    <!-- Tider -->
                    <div id="B"
                         style="display: none; float: left; width: 70vh; overflow-y: scroll; margin-top: 2px; height: 85vh">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Tider</p>
                        <p style="color: black; font-size: 23px">Uthyrningstyp:</p>
                        <div class="Type" style=" width: 70vh">
                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input id="Specifika" name="Type" type="radio" class="with-font" @click="check()"
                                       value="Specifika"
                                       :checked="parking.isLongTime === false && parking.isReoccuring===false"/>
                                <label class="radio-custom" style="width: 100%; font-size: 45px" for="Specifika">Specifika
                                    tider</label>
                            </div>

                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input style="width: 100%" id="Aterkommande" name="Type" type="radio"
                                       class="with-font"
                                       @click="check()" value="Aterkommande"
                                       :checked="parking.isLongTime === false && parking.isReoccuring===true"/>
                                <label style="width: 100%; font-size: 45px" for="Aterkommande">Återkommande</label>
                            </div>

                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input style="width: 100%" id="Langtidsuthyrning" name="Type" type="radio"
                                       class="with-font"
                                       @click="check()" value="Langtidsuthyrning"
                                       :checked="parking.isLongTime === true && parking.isReoccuring===false"/>
                                <label style="width: 100%; font-size: 45px"
                                       for="Langtidsuthyrning">Långtidsuthyrning</label>
                            </div>
                        </div>
                        <div id="Speci" v-if="checktider==='Specifika'">
                            <p style="font-size: 20px; color: black; margin-left: 12px; margin-bottom: 38px; margin-top: 34px">
                                Tider för uthyrning :</p>
                            <div style=" margin-left: 12px; display: flex;">
                                <p style="font-weight: bold; font-size: 35px; color: #100770;">
                                    <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                            class="material-icons-two-tone">calendar_today
                                    </v-icon>
                                </p>
                                <p role="button" @click="show_and_scroll_specifica()"
                                   style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 36px">
                                    Ändra datum och tid</p>
                            </div>


                            <div v-show="show_specifika === true"
                                 style="margin-left: -30px; height: 400px; text-align: center; margin-top: -25px; border-radius: 50px; width: 70vh; float: left; display: block; margin-bottom: 35px ">
                                <br>
                                <a-range-picker :open="show_specifika_calendar"
                                                size="large"
                                                style="width: 60vh; height: 91px"
                                                @change="onChangeSpecif"
                                                :show-time='{format: "hh:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                @ok="on_ok"
                                                :default-value="[moment(actual_date), moment(after_date)]"/>
                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: auto; margin-bottom: 20px; float: left; display: block;  width: 70vh; margin-left: 12px">
                                <p style="margin-bottom: 0; margin-top: 5px; color: #C1C1C1; font-size: 21px">Hyr ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                   v-if="range.start !=null">
                                    {{ moment(range.start).locale('sv').format('DD')}}
                                    {{ moment(range.start).locale('sv').format('MMM')}}, {{
                                    moment(range.start).format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{ moment(range.start).locale('sv').format('dddd').toString()[0].toUpperCase() +  moment(range.start).locale('sv').format('dddd').toString().slice(1)}} </span>

                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: auto; float: left; display: block; width: 70vh; margin-left: 12px;">
                                <p style="margin-bottom: 0; color: #C1C1C1; font-size: 21px">Hyr ut till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{ moment(range.end).locale('sv').format('DD')}} {{
                                    moment(range.end).locale('sv').format('MMM')}},
                                    {{ moment(range.end).format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{ moment(range.end).locale('sv').format('dddd').toString()[0].toUpperCase() +  moment(range.end).locale('sv').format('dddd').toString().slice(1)}} </span>
                                <br><br><br>
                            </div>
                        </div>
                        <div v-if="checktider==='Aterkommande'">
                            <div style="width: 100%; position: relative; overflow-x: hidden; height: 295px; overflow-y: hidden">
                                <p style="font-size: 20px; color: black; margin-left: 12px; margin-bottom: 38px; margin-top: 32px">
                                    Tider
                                    för uthyrning
                                    :</p>
                                <div style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; display: flex;">
                                    <p>
                                        <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                                class="material-icons-two-tone">calendar_today
                                        </v-icon>
                                    </p>
                                    <p role="button" @click="show_and_scroll_ater()"
                                       style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 36px; overflow-y: hidden; height: auto">
                                        Ändra tider</p>
                                </div>


                                <div id="time" style="display: flex; overflow-y: hidden">
                                    <div v-show="show_tider === false"
                                         style="height: 100px; margin-bottom: 30px;  width: fit-content; margin-left: 12px; overflow-y: hidden">
                                        <p style="margin-bottom: 0; padding-top: 3px; font-size: 21px; color: #C1C1C1">
                                            Hyr ut från:</p>
                                        <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                            moment(hourstart).format("hh:mm") }}</p>
                                    </div>

                                    <div v-show="show_tider === false"
                                         style="height: 100px; margin-bottom: 30px;  width: fit-content; margin-left: 50px; overflow-y: hidden">
                                        <p style="margin-bottom: 0; padding-top: 3px; font-size: 21px; color: #C1C1C1">
                                            Hyr ut till:</p>
                                        <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                            moment(hourend).format("hh:mm") }}</p>
                                    </div>
                                </div>

                                <b-row v-if="show_tider === true" style="height: auto; width: 100%; position: relative">
                                    <b-col md="auto">
                                        <a-time-picker :open="open" @openChange="handleOpenChange"
                                                       size="large" format="HH:mm"
                                                       @change="onChangeTimeStart"
                                                       v-model="hourstart"
                                        >
                                            <a-button slot="addon" size="small" type="primary"
                                                      @click="handleClose">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                    <b-col md="auto" style="margin-left: 40px">
                                        <a-time-picker :open.sync="open2" format="HH:mm" size="large"
                                                       @change="onChangeTimeEnd"
                                                       v-model="hourend"
                                        >
                                            <a-button slot="addon" size="small" type="primary" @click="handleClose2">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                </b-row>
                            </div>
                            <div id="jours"
                                 style="width: 65vh; margin-top: 7px; display: inline-block; margin-bottom: 20px; height: 200px">
                                <div :class="monday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Monday" value="Monday"
                                           @click="monday = !monday"
                                           v-model="days">
                                    <label :class="monday===true ? 'white' : 'blue'" for="Monday">Måndag</label>
                                </div>
                                <div :class="tuesday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Tuesday" value="Tuesday"
                                           @click="tuesday = !tuesday"
                                           v-model="days">
                                    <label :class="tuesday===true ? 'white' : 'blue'" for="Tuesday">Tisdag</label>
                                </div>
                                <div :class="wednesday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Wednesday" value="Wednesday"
                                           @click="wednesday = !wednesday" v-model="days">
                                    <label :class="wednesday===true ? 'white' : 'blue'"
                                           for="Wednesday">Onsdag</label>
                                </div>
                                <div :class="thursday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Thursday" value="Thursday"
                                           @click="thursday = !thursday"
                                           v-model="days">
                                    <label :class="thursday===true ? 'white' : 'blue'" for="Thursday">Torsdag</label>
                                </div>
                                <div :class="friday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Friday" value="Friday"
                                           @click="friday = !friday"
                                           v-model="days">
                                    <label :class="friday===true ? 'white' : 'blue'" for="Friday">Fredag</label>
                                </div>
                                <div :class="saturday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Saturday" value="Saturday"
                                           @click="saturday = !saturday"
                                           v-model="days">
                                    <label :class="saturday===true ? 'white' : 'blue'" for="Saturday">Lördag</label>
                                </div>
                                <div :class="sunday===true ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Sunday" value="Sunday"
                                           @click="sunday = !sunday"
                                           v-model="days">
                                    <label :class="sunday===true ? 'white' : 'blue'" for="Sunday">Söndag</label>
                                </div>
                            </div>
                        </div>
                        <div v-if="checktider==='Langtidsuthyrning'">
                            <p style="font-size: 20px; color: black; margin-left: 12px; margin-bottom: 38px; margin-top: 32px">
                                Tider för
                                uthyrning :</p>
                            <div style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; display: flex;">
                                <p style="font-weight: bold; font-size: 35px; color: #100770;">
                                    <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                            class="material-icons-two-tone">calendar_today
                                    </v-icon>
                                </p>
                                <p role="button" @click="show_and_scroll_lang()"
                                   style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 36px">
                                    Ändra datum och
                                    tid</p>
                            </div>

                            <div v-show="show_lang === true"
                                 style="margin-left: -30px;height: 420px; text-align: center; margin-top: -25px; border-radius: 50px; width: 70vh; float: left; display: block; margin-bottom: 35px ">
                                <br>
                                <a-range-picker :open="show_lang_calendar" size="large"
                                                @change="onChangeReoccuring"
                                                :show-time='{format: "hh:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                style="width: 60vh; height: 100px; font-size: 20px"
                                                @ok="on_ok_lang"
                                                :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                            </div>

                            <div v-show="show_lang === false"
                                 style="height: auto; margin-bottom: 20px; float: left; display: block;  width: 70vh; margin-left: 12px; overflow-y: hidden">
                                <p style="margin-bottom: 0; margin-top: 5px; color: #C1C1C1; font-size: 21px">Hyr ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                   v-if="range.start !=null">
                                    {{ moment(range.start).locale('sv').format('DD')}}
                                    {{ moment(range.start).locale('sv').format('MMM')}}, {{
                                    moment(range.start).format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{ moment(range.start).locale('sv').format('dddd').toString()[0].toUpperCase() +  moment(range.start).locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>

                            <div v-show="show_lang === false"
                                 style="height: auto; float: left; display: block; width: 70vh; margin-left: 12px;  overflow-y: hidden; margin-bottom: 40px">
                                <p style="margin-bottom: 0; color: #C1C1C1; font-size: 21px">Hyr ut till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{ moment(range.end).locale('sv').format('DD')}} {{
                                    moment(range.end).locale('sv').format('MMM')}},
                                    {{ moment(range.end).format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{ moment(range.end).locale('sv').format('dddd').toString()[0].toUpperCase() +  moment(range.end).locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>
                        </div>
                    </div>
                    <!-- Info -->
                    <div id="C" style="display: none; float: left; width: 90vh; overflow-y: scroll">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Information</p>
                        <div style="margin-bottom: 42px">
                            <p style="font-size: 24px; color: black">Parkeringtyp:</p>
                            <div style="align-items: center; text-align: center; height: 74px; width: 80vh">
                                <v-card style="width: 66.5vh; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  margin-right: auto; margin-left: 2px; margin-top: 10px">
                                    <v-tabs fixed-tabs card v-model="type">
                                        <v-tab title="Tab 1" style="width: 33%" key="first">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Parkering
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 2" style="width: 33%" key="second">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Uppfart
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 3" style="width: 33%" key="third">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Garage
                                            </v-card-text>
                                        </v-tab>
                                    </v-tabs>
                                </v-card>
                            </div>
                        </div>
                        <div>
                            <p style="color:black; font-size: 23px">Storlek:</p>
                            <div style="align-items: center; text-align: center; height: 74px; width: 80vh">
                                <v-card style="width: 66.5vh; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; margin-right: auto; margin-left: 2px; margin-top: 10px"
                                        no-body>
                                    <v-tabs card v-model="size">
                                        <v-tab title="Tab 1" style="width: 50%;" key='first'>
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Standard (2x5)
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 2" style="width: 50%;" key='second'>
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Handikapp (3x5)
                                            </v-card-text>
                                        </v-tab>
                                    </v-tabs>
                                </v-card>
                            </div>
                        </div>
                        <div>
                            <div class="Specifikationer">
                                <p style="color: black; font-size: 23px; margin-bottom: 26px; margin-top: 40px">
                                    Specifikationer:</p>
                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input id="Laddningstation" name="specifikationerCharging" type="checkbox"
                                           class="with-font"
                                           @click="specifikation()" value="Laddningstation"/>
                                    <label style="width: 100%; font-size: 35px"
                                           for="Laddningstation">Laddningstation</label>
                                </div>

                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input style="width: 100%" id="under_tak" name="specifikationerUnderTak"
                                           type="checkbox"
                                           class="with-font"
                                           @click="specifikation()" value="Under tak"/>
                                    <label style="width: 100%; font-size: 35px" for="under_tak">Under tak</label>
                                </div>

                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input style="width: 100%" id="Handikappsanpassad" name="specifikationerHandikap"
                                           type="checkbox"
                                           class="with-font" @click="specifikation()" value="Handikappsanpassad"/>
                                    <label style="width: 100%; font-size: 35px" for="Handikappsanpassad">Handikappsanpassad</label>
                                </div>
                            </div>
                            <div>
                                <p style="font-size: 23px; margin-top: 3px; color: black; margin-bottom: 41px">Övriga
                                    detaljer:</p>
                                <textarea
                                        style="height: 150px; font-size: 20px; text-align: left; color: black; margin-bottom: 55px"
                                        placeholder="Ange övriga specifikationer och eventuella tips som kan hjälpa för att lättare hitta din parkering…"
                                        v-model="description"
                                        class="input"
                                ></textarea>
                            </div>
                        </div>
                    </div>

                    <!-- Bild -->
                    <div id="D" style="display: none; float: left; width: 90vh; overflow-y: scroll">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Bild</p>
                        <p style="font-size: 23px; color: black; margin-top: 33px">Omslagsbild:</p>
                        <div style="margin-bottom: 50px">
                            <input id="file" type="file" style="display: none;" @change="onFileChange"
                                   accept="image/*"/>
                            <div id="preview" style="width: 531px; height: 580px; overflow-y: hidden">
                                <v-img v-if="img" style="width: 531px; height: 531px; border-radius: 11px; z-index: 1"
                                       v-bind:src="img"
                                       alt=""/>
                                <label for="file" class="view-picture"
                                       style="margin-left: auto; margin-right: auto ; color: #080056; font-weight: bold"
                                       role="button">Lägg till bild</label>
                            </div>

                        </div>
                    </div>

                    <!-- Pris -->
                    <div id="E" style="display: none; float: left; width: 90vh; overflow-y: scroll">
                        <p style="color: black; font-size: 45px; margin-bottom: 33px">Pris</p>
                        <div style="border-bottom: solid 2px grey">
                            <div style="width: 100%; margin-bottom: 37px">
                                <p style="color: black; font-size: 23px">Timpris:</p>
                                <input class="input"
                                       type="number"
                                       style="font-size: 100px; background-color: white; color: black; height: 130px;"
                                       placeholder="Ange pris" v-model="price">
                            </div>

                            <div id="price" style="width: 70vh; display: flex; margin-top: 36px">
                                <div>
                                    <p style="color: #C3C3C3; font-size: 24px">Dagspris:</p>
                                    <p style="color: #E3E3E3; font-size: 69px">{{ price_day }}kr</p>
                                </div>
                                <div style="margin-left: 178px">
                                    <p style="font-size: 24px; color: #C3C3C3">Veckopris:</p>
                                    <p style="color: #E3E3E3; font-size: 69px"> {{ price_week }}kr</p>
                                </div>
                            </div>

                        </div>
                        <div style="margin-top: 20px">
                            <div>
                                <p style="margin-bottom: 17px; color: black; margin-top: 40px; font-size: 23px">
                                    Priszon:</p>
                                <p style="color: black; font-size: 47px; text-decoration: underline">{{ city }} <i
                                        style="text-decoration: none" class="fas fa-map-pin"></i></p>
                            </div>
                            <div style="margin-top: 18px">
                                <p style="margin-bottom: 16px; color: black;font-size: 23px">Maxpris: </p>
                                <p style="font-size: 52px; color: red; margin-bottom: 18px">{{ price_zone }}kr</p>
                                <p style="color: black; margin-bottom: 16px">Uppskattat område:</p>
                                <p v-if="price > price_zone" style="color: black; font-size: 47px; margin-bottom: 45px">
                                    Inget
                                    resultat</p>
                                <p v-if="price <= price_zone && price > yellow_price"
                                   style="color: red; font-size: 47px; margin-bottom: 45px">
                                    I centrum</p>
                                <p v-if="yellow_price >= price && price > green_price"
                                   style="color: yellow; font-size: 47px; margin-bottom: 45px">Centralt</p>
                                <p v-if="green_price >= price && price > blue_price"
                                   style="color: green; font-size: 47px; margin-bottom: 45px">Nära stan</p>
                                <p v-if="price <= blue_price && price > 0"
                                   style="color: blue; font-size: 47px; margin-bottom: 45px">Landsbygd</p>
                                <p v-if="price === null || price <= 0 "
                                   style="color: black; font-size: 47px; margin-bottom: 45px">Inget
                                    resultat</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase";
    import ParkingSpotApi from "../services/ParkingSpotApi";
    import moment from 'moment';
    import 'moment/locale/sv'

    export default {

        data() {
            return {
                range: {
                    start: null,
                    end: null,
                },
                show_tider: false,
                show_specifika: false,
                show_lang: false,
                checkedDays: [],
                hourstart: this.date_of_today,
                hourend: this.date_of_today,
                date: new Date(),
                parkingtype: null,
                storlek: null,
                ja_nej: null,
                anonym: false,
                adressbool: false,
                tiderbool: false,
                infobool: false,
                bildbool: false,
                prisbool: false,
                checktider: null,
                checkinfo: null,
                url: null,
                address: null, //bon
                postcode: null, //bon
                city: null, //bon
                price: 0, //bon
                price_day: '0', //bon
                price_week: '0', //bon
                dragValue: null,
                typechosen: null,
                specchosen: null,
                description: null, //bon
                date_of_today: new Date(),
                imageData: null,
                img: null, //bon
                isAnonymousProfile: '', //bon
                hours: [], //bon
                fromSpecificDate: '', //bon
                status: '', //bon
                toReoccurringDate: '', //bon
                isHandicap: '', //bon
                imageID: '', //bon
                type: '', //bon
                docId: '', //bon
                size: null, //bon
                isReoccuring: '', //bon
                weekPrice: '',//bon
                fromReoccurringDate: '', //bon
                days: [], //bon
                isLongTime: '', //bon
                isRoof: '', //bon
                isCharging: '',
                toSpecificDate: '',
                zipcode: '',
                buttonText: '',
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                dayMultiplier: 5,
                weekMultiplier: 15,
                price_zone: null,
                arrow_add: true,
                arrow_tid: false,
                arrow_inf: false,
                arrow_bil: false,
                arrow_pri: false,
                green_price: null,
                yellow_price: null,
                blue_price: null,
                loader: null,
                loading: false,
                loader1: null,
                loading1: false,
                daysName: [],
                value2: moment(),
                actual_date: null,
                after_date: null,
                hourstartstring: '',
                hourendstring: '',
                show_specifika_calendar: false,
                show_tider_time: false,
                show_lang_calendar: false,
                open: false,
                open2: false,
            }
        },
        beforeCreate() {
            document.title = "Redigera"
        },
        watch: {
            days() {
                this.days_edit()
                let monday_exists = false
                let tuesday_exists = false
                let wednesday_exists = false
                let thursday_exists = false
                let friday_exists = false
                let saturday_exists = false
                let sunday_exists = false
                this.daysName.forEach(el => {
                    switch (el) {
                        case 'Mån':
                            this.days.forEach(element => {
                                if (element === 'Monday') {
                                    monday_exists = true
                                }
                            })
                            break;
                        case 'Tis':
                            this.days.forEach(element => {
                                if (element === 'Tuesday') {
                                    tuesday_exists = true
                                }
                            })
                            break;
                        case 'Ons':
                            this.days.forEach(element => {
                                if (element === 'Wednesday') {
                                    wednesday_exists = true
                                }
                            })
                            break;
                        case 'Tor':
                            this.days.forEach(element => {
                                if (element === 'Thursday') {
                                    thursday_exists = true
                                }
                            })
                            break;
                        case 'Fer':
                            this.days.forEach(element => {
                                if (element === 'Friday') {
                                    friday_exists = true
                                }
                            })
                            break;
                        case 'Lör':
                            this.days.forEach(element => {
                                if (element === 'Saturday') {
                                    saturday_exists = true
                                }
                            })
                            break;
                        case 'Sön':
                            this.days.forEach(element => {
                                if (element === 'Sunday') {
                                    sunday_exists = true
                                }
                            })
                            break;
                    }
                })
                if (monday_exists === false) {
                    let index = this.daysName.indexOf("Mån");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (tuesday_exists === false) {
                    let index = this.daysName.indexOf("Tis");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (wednesday_exists === false) {
                    let index = this.daysName.indexOf("Ons");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (thursday_exists === false) {
                    let index = this.daysName.indexOf("Tor");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (friday_exists === false) {
                    let index = this.daysName.indexOf("Fer");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (saturday_exists === false) {
                    let index = this.daysName.indexOf("Lör");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (sunday_exists === false) {
                    let index = this.daysName.indexOf("Sön");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
            },
            loader() {
                const l = this.loader;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 6000);
                this.loader = null
            },
            loader1() {
                const l = this.loader1;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 6000);
                this.loader1 = null
            },
            ja(value) {
                this.ja = value;
            },
            price: function (value) {
                this.price_day = value * this.dayMultiplier;
                this.price_week = value * this.weekMultiplier;
                console.log("value");
                let value2 = Number(value)
                console.log(typeof value2);
                console.log("price zone");
                console.log(typeof this.price_zone)
                if (value2 > this.price_zone) {
                    this.price = this.price_zone.toString()
                }
            },
            type: function (value) {
                if (value === 0) {
                    this.typechosen = 'Parkingar'
                } else if (value === 1) {
                    this.typechosen = 'Uppfart'
                } else if (value === 2) {
                    this.typechosen = 'Garage'
                }

            },
            size: function (value) {
                if (value === 0) {
                    this.specchosen = 'Standard (2x5)'
                } else if (value === 1) {
                    this.specchosen = 'Handikapp (3x5)'
                }
            },
            city(value) {
                let vm = this;
                window.db.collection('pricezones').doc(value).onSnapshot((querySnapshot) => {
                        if (querySnapshot.exists) {
                            vm.price_zone = querySnapshot.data()['red'];
                            vm.blue_price = querySnapshot.data()['blue'];
                            vm.green_price = querySnapshot.data()['green'];
                            vm.yellow_price = querySnapshot.data()['yellow'];
                            vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                            vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                            vm.price_day = vm.price * vm.dayMultiplier;
                            vm.price_week = vm.price * vm.weekMultiplier
                        } else {
                            window.db.collection('pricezones').doc('standardZones').onSnapshot((querySnapshot) => {
                                vm.price_zone = querySnapshot.data()['red'];
                                vm.blue_price = querySnapshot.data()['blue'];
                                vm.green_price = querySnapshot.data()['green'];
                                vm.yellow_price = querySnapshot.data()['yellow'];
                                vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                                vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                                vm.price_day = vm.price * vm.dayMultiplier;
                                vm.price_week = vm.price * vm.weekMultiplier
                            })
                        }
                    }
                );
            }
        },
        components: {},
        props: ['user', 'parking', 'field'],
        mounted() {
            if (this.field) {
                switch (this.field) {
                    case 'adress':
                        this.showAdress();
                        break;
                    case 'tider':
                        this.showTider();
                        break;
                    case 'information':
                        this.showInfo();
                        break;
                    case 'bild':
                        this.showBild();
                        break;
                    case 'pris':
                        this.showPris();
                        break;
                }
            }
            let vm = this;
            // general
            this.parkingtype = 'first';
            const current = new Date();
            this.date_of_today = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            this.actual_date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
            let test = new Date(new Date().setDate(new Date().getDate() + 7));
            let month = test.getMonth() + 1;
            this.after_date = test.getFullYear() + '-' + month + '-' + test.getDate();
            this.date_of_today = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            this.range.start = moment(this.actual_date, 'YYYY-MM-DD');
            this.range.end = moment(this.after_date, 'YYYY-MM-DD');
            // first part
            this.address = this.parking['address'];
            this.zipcode = this.parking['zipcode'];
            this.city = this.parking['city'];
            this.isAnonymousProfile = this.parking['isAnonymousProfile'];
            if (this.isAnonymousProfile === true) {
                document.getElementById("JA").checked = true;
                this.ja_nej = 'ja';
                this.anonym = true
            } else {
                document.getElementById("JA").checked = false;
            }
            // second part
            this.isLongTime = this.parking['isLongTime'];
            if (this.parking['fromSpecificDate']) {
                if (this.parking['isLongTime'] === true) {
                    this.actual_date = new Date(this.parking['fromSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.after_date = new Date(this.parking['toSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.range.start = new Date(this.parking['fromSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.range.end = new Date(this.parking['toSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.checktider = 'Langtidsuthyrning';
                } else {
                    this.actual_date = new Date(this.parking['fromSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.after_date = new Date(this.parking['toSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.range.start = new Date(this.parking['fromSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.range.end = new Date(this.parking['toSpecificDate']['_seconds'] * 1000).toString().substr(0, 21);
                    this.checktider = 'Specifika';
                }
            }
            this.isReoccuring = this.parking['isReoccuring']
            if (this.parking['isReoccuring'] === true) {
                this.days = this.parking['days'];
                this.days.forEach(el => {
                    switch (el) {
                        case "Monday":
                            this.monday = true;
                            break;
                        case "Tuesday":
                            this.tuesday = true;
                            break;
                        case "Wednesday":
                            this.wednesday = true;
                            break;
                        case "Thursday":
                            this.thursday = true;
                            break;
                        case "Friday":
                            this.friday = true;
                            break;
                        case "Saturday":
                            this.saturday = true;
                            break;
                        case "Sunday":
                            this.sunday = true
                            break;
                    }
                });
                this.hourstart = moment(new Date(this.parking['fromReoccuringDate']['_seconds'] * 1000));
                this.hourend = moment(new Date(this.parking['toReoccuringDate']['_seconds'] * 1000));
                this.checktider = 'Aterkommande';
            }
            // third part
            this.typechosen = this.parking['type']
            if (this.typechosen === 'Parkering') {
                this.type = 0
            } else if (this.typechosen === 'Uppfart') {
                this.type = 1
            } else if (this.typechosen === 'Garage') {
                this.type = 2
            }
            this.specchosen = this.parking['size']
            if (this.specchosen === 'Standard (2x5)' || this.specchosen === 'Vanlig (2x5)') {
                setTimeout(this.size = 0, 100)
            } else {
                setTimeout(this.size = 1, 100)
            }
            this.isRoof = this.parking['isRoof'];
            if (this.parking['isRoof'] === true) {
                document.getElementById("under_tak").checked = true;

            }
            this.isHandicap = this.parking['isHandicap'];
            if (this.parking['isHandicap'] === true) {
                document.getElementById("Handikappsanpassad").checked = true;
            }
            this.isCharging = this.parking['isCharging'];
            if (this.parking['isCharging'] === true) {
                document.getElementById("Laddningstation").checked = true;
            }
            this.description = this.parking['description'];
            // forth part
            this.imageID = this.parking['imageID'];

            // fifth part
            this.price = this.parking['price'];
            this.weekPrice = this.parking['weekPrice'];
            // others
            this.status = this.parking['status'];
            this.docId = this.parking['privateDocumentID'];
            firebase.storage().ref('lotImages/' + vm.parking['imageID'] + ".jpg").getDownloadURL().then(imgUrl => {
                vm.img = imgUrl
            })
        },
        methods: {
            show_and_scroll_Ja_Nej() {
                setTimeout(this.scrollToEnd, 100)
            },
            handleOpenChange(open) {
                this.open = open;
            },
            handleClose() {
                this.open = false;
                this.open2 = true;
            },
            handleClose2() {
                this.show_tider = false;
                this.open = false;
                this.open2 = false;
            },
            on_ok() {
                this.show_specifika_calendar = false;
                this.show_specifika = false
            },
            on_ok_lang() {
                this.show_lang_calendar = false
                this.show_lang = false
            },
            onChangeSpecif(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeReoccuring(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeTimeStart(time, timeString) {
                console.log(timeString);
                this.hourstart = time;
                this.hourstartstring = timeString
            },
            onChangeTimeEnd(time, timeString) {
                console.log(time, timeString);
                this.hourend = time;
                this.hourendstring = timeString;
                this.show_tider_time = false
            },
            moment,
            show_and_scroll_specifica() {
                this.show_specifika = !this.show_specifika;
                setTimeout(this.scrollToEnd, 100)
            },
            show_and_scroll_ater() {
                this.show_tider = !this.show_tider;
                this.open = this.show_tider
                setTimeout(this.scrollToEnd, 100)
                this.show_tider_time = this.show_tider
            },
            show_and_scroll_lang() {
                this.show_lang = !this.show_lang;
                setTimeout(this.scrollToEnd, 100)
            },
            scrollToEnd: function () {
                var objDiv = document.getElementById("B");
                objDiv.scrollTop = objDiv.scrollHeight;
                this.show_specifika_calendar = this.show_specifika
                this.show_lang_calendar = this.show_lang
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.imageData = e.target.files[0]
                this.url = URL.createObjectURL(file);
                this.img = this.url

            },
            signout() {
                firebase.auth().signOut();
                this.$store.dispatch('setToken', null)
                this.$store.dispatch('setUser', null)
                this.$router.push({path: '/'})
                window.location.reload(); //reload on signout !
            },
            showAdress() {
                this.arrow_add = true;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (a.style.display === "none") {
                    a.style.display = "block";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showTider() {
                this.arrow_add = false;
                this.arrow_tid = true;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (b.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "block";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showInfo() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = true;
                this.arrow_bil = false;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (c.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "block";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showBild() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = true;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (d.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "block";
                    e.style.display = "none";
                }
            },
            showPris() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = true;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (e.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "block";
                }
            },
            check() {
                let select = document.querySelector('input[name="Type"]:checked').value;
                this.checktider = select;
                this.show_specifika = false;
                this.show_lang = false;
                this.show_tider = false;
                this.show_tider_time = this.show_tider;
                this.show_lang_calendar = this.show_lang;
                this.show_specifika_calendar = this.show_specifika;
                return select
            },
            specifikation() {
                if (document.querySelector('input[name="specifikationerCharging"]:checked')) {
                    this.isCharging = true
                } else {
                    this.isCharging = false
                }
                if (document.querySelector('input[name="specifikationerUnderTak"]:checked')) {
                    this.isRoof = true
                } else {
                    this.isRoof = false
                }
                if (document.querySelector('input[name="specifikationerHandikap"]:checked')) {
                    this.isHandicap = true
                } else {
                    this.isHandicap = false
                }
            },
            async editParkingSpot() {
                this.loader = 'loading';
                try {
                    if (this.checktider === 'Specifika') {
                        this.fromSpecificDate = new Date(this.range['start']);
                        this.toSpecificDate = new Date(this.range['end']);
                        this.isLongTime = false;
                        this.isReoccuring = false;
                        this.fromReoccurringDate = '';
                        this.toReoccurringDate = '';
                        this.days = ''
                    } else if (this.checktider === 'Aterkommande') {
                        this.fromSpecificDate = "";
                        this.toSpecificDate = "";
                        this.isLongTime = false;
                        this.isReoccuring = true;
                        this.fromReoccurringDate = new Date(this.hourstart);
                        this.toReoccurringDate = new Date(this.hourend);
                    } else if (this.checktider === 'Langtidsuthyrning') {
                        this.fromSpecificDate = new Date(this.range['start']);
                        this.toSpecificDate = new Date(this.range['end']);
                        this.isLongTime = true;
                        this.isReoccuring = false;
                        this.fromReoccurringDate = '';
                        this.toReoccurringDate = '';
                        this.days = ''
                    }
                    let parkingdetails = {
                        // first part
                        "address": this.address,
                        "zipcode": this.zipcode,
                        "city": this.city,
                        "isAnonymousProfile": this.isAnonymousProfile,
                        // second part
                        "fromSpecificDate": this.fromSpecificDate,
                        "toSpecificDate": this.toSpecificDate,
                        "isLongTime": this.isLongTime,
                        "fromReoccuringDate": this.fromReoccurringDate,
                        "toReoccuringDate": this.toReoccurringDate,
                        "days": this.days,
                        "isReoccuring": this.isReoccuring,
                        //third part
                        "type": this.typechosen,
                        "size": this.specchosen,
                        "isRoof": this.isRoof,
                        "isHandicap": this.isHandicap,
                        "isCharging": this.isCharging,
                        "description": this.description,
                        // last part
                        "price": this.price,
                        "dayPrice": this.price_day,
                        "weekPrice": this.price_week,
                        "userId": firebase.auth().currentUser.uid,
                        "docId": this.parking['privateDocumentID']
                    };
                    await ParkingSpotApi.editParkingSpot(parkingdetails).then(async (res) => {
                        if (res.status === 200) {
                            if (this.imageData === null) {
                                this.$router.go(0);
                            } else {
                                await firebase.storage().ref(`lotImages/${res.data}` + '.jpg').put(this.imageData).then(r => {
                                    console.log(r);
                                    this.$router.go(0)
                                }).catch(reason => {
                                    console.log(reason);
                                    this.$router.go(0)
                                })
                            }
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            checkInput() {
                let returnvalue = true
                if (this.address && this.zipcode && this.city) {
                    if (this.type !== null && this.size !== null) {
                        if (this.price > 0) {
                            returnvalue = false
                        }
                    }
                }
                return returnvalue
            },
            editStatus() {
                this.loader1 = 'loading1';
                let status = '';
                if (this.buttonText === 'Aktivera') {
                    status = 'Aktiv'
                } else {
                    status = 'Ej aktiv'
                }
                let vm = this
                window.db.collection('stripe_connected_accounts').doc(firebase.auth().currentUser.uid).get().then(function (thisDoc) {
                    if (thisDoc.exists) {
                        window.db.collection('users').doc(firebase.auth().currentUser.uid).collection('lots').doc(vm.parking['privateDocumentID']).update({
                            status: status
                        }).then(r => {
                            console.log(r)
                            vm.$router.go(0);
                        })
                    } else {
                        vm.$router.push({
                            name: 'bankVerification',
                        });
                    }
                }).catch((reason => {
                    console.log(reason)
                }))
            },
            days_edit() {
                let monday_exists = false
                let tuesday_exists = false
                let wednesday_exists = false
                let thursday_exists = false
                let friday_exists = false
                let saturday_exists = false
                let sunday_exists = false
                this.days.forEach(el => {
                    switch (el) {
                        case 'Monday':
                            this.daysName.forEach(element => {
                                if (element === 'Mån') {
                                    monday_exists = true
                                }
                            })
                            if (monday_exists === false) {
                                this.daysName.push('Mån')
                            }
                            break;
                        case 'Tuesday':
                            this.daysName.forEach(element => {
                                if (element === 'Tis') {
                                    tuesday_exists = true
                                }
                            })
                            if (tuesday_exists === false) {
                                this.daysName.push('Tis')
                            }
                            break;
                        case 'Wednesday':
                            this.daysName.forEach(element => {
                                if (element === 'Ons') {
                                    wednesday_exists = true
                                }
                            })
                            if (wednesday_exists === false) {
                                this.daysName.push('Ons')
                            }
                            break;
                        case 'Thursday':
                            this.daysName.forEach(element => {
                                if (element === 'Tor') {
                                    thursday_exists = true
                                }
                            })
                            if (thursday_exists === false) {
                                this.daysName.push('Tor')
                            }
                            break;
                        case 'Friday':
                            this.daysName.forEach(element => {
                                if (element === 'Fre') {
                                    friday_exists = true
                                }
                            })
                            if (friday_exists === false) {
                                this.daysName.push('Fre')
                            }
                            break;
                        case 'Saturday':
                            this.daysName.forEach(element => {
                                if (element === 'Lör') {
                                    saturday_exists = true
                                }
                            })
                            if (saturday_exists === false) {
                                this.daysName.push('Lör')
                            }
                            break;
                        case 'Sunday':
                            this.daysName.forEach(element => {
                                if (element === 'Sön') {
                                    sunday_exists = true
                                }
                            })
                            if (sunday_exists === false) {
                                this.daysName.push('Sön')
                            }
                            break;
                    }
                })
            }
        }
        ,
        computed: {
            isDisabled() {
                return this.checkInput()
            }
            ,
            getMinDate() {
                const current = new Date();
                return new Date(current.getFullYear(), current.getMonth(), current.getDate());
            }
        }
    }
</script>

<style scoped>
    @import url(//netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css);

    * {
        overflow-x: hidden;
    }

    .right-col {
        overflow-y: scroll;
        float: right;
        margin-right: 47px;
        height: 90vh;
        width: 70vh;
        margin-top: 20px;
        text-align: left;

    }

    .left-col {
        padding-right: 0;
        float: left;
        border-right: solid #0E046E 1px;
        margin-left: 45px;
        height: 90vh;
        width: 90vh;
    }

    input:focus {
        outline: none;
    }

    h4 {
        font-weight: bold;
    }

    p {
        margin-top: -8px;
        font-weight: bold;
        color: #B3B3B3;
    }

    .button {
        height: 84px;
        margin-top: 20px;
        float: left;
        text-align: left;
        border-bottom: solid 1px;
        border-bottom-color: #DFDFDF;
        width: 100%
    }

    .row {
        position: fixed;
        height: 100%;
    }

    .body {
        width: 100%;
        float: right;
    }

    .input {
        padding-left: 15px;
        float: left;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: xx-large;
        background: #eeeeee;
        margin-bottom: 20px;
        width: 70vh;
        height: 91px;
        font-weight: bold;
    }


    .Ja_nej {
        border-bottom: solid 1px lightgray;
    }

    .Type {
        border-bottom: solid 1px lightgray;
    }

    /*** Radio Checkbox style ***/


    input[type=radio].with-font,
    input[type=checkbox].with-font {
        float: right;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-font ~ label:before,
    input[type=checkbox].with-font ~ label:before {
        float: right;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #BFBFBF;
        width: 1.4em;
    }

    input[type=radio].with-font:checked ~ label:before,
    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        font-size: 1.2em;
        color: black;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-font ~ label:before {
        float: right;
        content: "\f1db";
    }

    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        color: black;
    }

    .slutfor {
        margin-left: auto;
        margin-right: 10px;
        font-weight: bold;
        color: white;
        font-size: 25px;
        width: 300px;
        height: 70px;
        background: #0E046E;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .dark-theme {
        overflow-y: hidden;
        width: auto;
        height: 44px;
        margin-right: 15px;
        background: #080056;
        color: white !important;
        border-color: #080056;
    }

    .light-theme {
        overflow-y: hidden;
        width: auto;
        height: 44px;
        margin-right: 15px;
        background: white;
        border-color: #080056;
        color: #080056;
    }

    input[type=radio].with-day,
    input[type=checkbox].with-day {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-day ~ label:before,
    input[type=checkbox].with-day ~ label:before {
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #080056;
        width: 1.4em;
    }

    input[type=radio].with-day:checked ~ label:before,
    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        font-size: 1.2em;
        color: white;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-day ~ label:before {
        content: "\f1db";
    }

    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        color: white;
    }


    .view-picture {
        line-height: 58px;
        color: #0E046E;
        text-align: center;
        display: block;
        position: relative;
        z-index: 2;
        margin-top: -31.5px;
        margin-left: auto;
        margin-right: auto;
        width: 230px;
        height: 63px;
        background: white;
        box-shadow: 0px 4px 20px lightgray;
        border-radius: 50px;
        border: #080056 solid 1px;
        font-size: 25px;
        font-weight: bold;
    }

    label {
        color: #BFBFBF;
    }

    input[type=radio]:checked ~ label {
        color: black;
    }

    .blue {
        color: #080056
    }

    .white {
        color: #FFFFFF;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }


    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #b5b2d3 !important;
    }


    input[type=checkbox].with-font:checked ~ label {
        color: black;
    }
</style>