<template>
    <v-container>
        <v-main>
            <v-row>
                <v-col style="overflow-y: scroll; height: 100vh" cols="6">
                    <div>
                        <p style="font-size: 62px; font-weight: bold; margin-bottom: 1px">Registrera</p>
                        <p style="font-size: 30px;">Skapa ett konto och hantera dina <br>parkeringsplatser.</p>
                    </div>
                    <v-form @submit.prevent="register">
                        <div style="margin-top: 50px">
                            <h6 style="margin-bottom: 20px; font-weight: bold">Personlig information</h6>
                            <label>
                                <input class="input" placeholder="Förnamn" style="margin-bottom: 25px"
                                       v-model="firstName"/>
                            </label>
                            <div v-if="this.$v.firstName.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar8 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar8"
                                >
                                    Förnamn krävs
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar8 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <!--<div style="width: 72%" class="alert alert-danger" role="alert" v-if="this.$v.firstName.$invalid">
                                Name is required
                            </div>-->
                            <label>
                                <input class="input" placeholder="Efternamn" style="margin-bottom: 25px"
                                       v-model="lastName"/>
                            </label>
                            <div v-if="this.$v.lastName.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar1 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar1"
                                >
                                    Efternamn krävs
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar1 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <label>
                                <input class="input" placeholder="Personnummer (ÅÅ-MM-DD-XXXX)"
                                       v-model="socialSecurityNumber" @input="acceptSocial"/>
                            </label>
                            <div v-if="this.$v.socialSecurityNumber.$invalid && this.formSubmitted===true"
                                 style="display: flex">
                                <button @click="snackbar2 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar2"
                                >
                                    Personnummer krävs och måste vara i ett bra format
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar2 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <p class="font-weight-light" style="margin-top: 1px; font-size: 17px; color: black">
                                <i class="fas fa-info-circle"></i>
                                Ditt personnummer visas aldrig för andra användare
                            </p>
                        </div>
                        <br>
                        <div style="margin-top: 20px">
                            <h6 style="font-weight: bold">Adress</h6>
                            <label>
                                <input class="input" placeholder="Adress" v-model="address"/>
                            </label>
                            <div v-if="this.$v.address.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar3 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar3"
                                >
                                    Adress krävs
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar3 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <label>
                                <input class="input" placeholder="Postkod" v-model="postCode"/>
                            </label>
                            <div v-if="this.$v.postCode.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar4 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar4"
                                >
                                    Postkod krävs och måste vara numeriskt
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar4 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <label>
                                <input class="input" placeholder="Stad" v-model="city"/>
                            </label>
                            <div v-if="this.$v.city.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar5 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar5"
                                >
                                    Stad krävs
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar5 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <p class="font-weight-light" style="margin-top: -10px; margin-bottom: 1px; color: black">
                                <i class="fas fa-info-circle"></i>
                                Din privata adress är skyddad och visas aldrig för andra<br>
                                anvandare
                            </p>
                        </div>
                        <br>
                        <div style="margin-top: 20px">
                            <h6 style="font-weight: bold">Kontoinformation</h6>
                            <label>
                                <input class="input" placeholder="E-postadress" v-model="email"/>
                            </label>
                            <div v-if="this.$v.email.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar6 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar6"
                                >
                                    E-post krävs och måste vara i ett bra format
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar6 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <label>
                                <input class="input" placeholder="Telefonnummer" v-model="phoneNumber"
                                       @input="acceptNumber"/>
                            </label>
                            <div v-if="this.$v.phoneNumber.$invalid && this.formSubmitted===true" style="display: flex">
                                <button @click="snackbar7 = true" class=""><span
                                        class="fa fa-info-circle fa-lg firstName"></span></button>
                                <v-snackbar
                                        v-model="snackbar7"
                                >
                                    Telefonnummer krävs och måste vara giltigt
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                                color="pink"
                                                text
                                                v-bind="attrs"
                                                @click="snackbar7 = false"
                                                style="text-transform: none"
                                        >
                                            Stäng
                                        </v-btn>
                                    </template>
                                </v-snackbar>
                            </div>
                            <p class="font-weight-light text-success" style="margin-top: -10px;">
                                <i class="fas fa-lock"></i>
                                Vi tar din integritet på fullaste allvar och har utvecklat <br>FastPark som en tjänst
                                där din säkerhet är av högsta <br>prioritet.
                            </p>
                        </div>
                        <div>
                            <br>
                            <v-btn @click="register"
                                   style="text-transform : none; font-size: 30px; color: white; padding: 10px 170px; width: 450px; height: 65px;background: #00B4FF; border-radius: 50px; font-weight : bold"
                                   :loading="loading" :disabled="isDisabled"
                            >
                                Slutför
                            </v-btn>
                        </div>
                    </v-form>
                    <br><br>
                    <v-snackbar
                            v-model="phone_number_exists_already"
                    >
                        Telefonnummer som redan används
                        <template v-slot:action="{ attrs }">
                            <v-btn
                                    color="red"
                                    text
                                    v-bind="attrs"
                                    @click="phone_number_exists_already = false"
                                    style="text-transform: none"
                            >
                                Stäng
                            </v-btn>
                        </template>
                    </v-snackbar>
                </v-col>
                <v-col
                        cols="6"
                >
                    <v-img class="ml-3" contain style="margin-top: 25px; width: 676px; height: 679px"
                           :src="require('../assets/code_activation.svg')"></v-img>
                </v-col>
            </v-row>
        </v-main>
    </v-container>
</template>

<script>
    import Authentication from "../services/Authentication";
    import {required, minLength, email, numeric} from 'vuelidate/lib/validators'
    import useVuelidate from '@vuelidate/core'


    export default {
        beforeCreate() {
            document.title = "Registrera"
        },
        mounted() {
            let x = 1;
            this.format_security_social = this.template_social_security_number.replace(/X+/g, () => '$' + x++);
            this.template_social_security_number.match(/X+/g).forEach((char, key) => {
                // eslint-disable-next-line no-useless-escape
                this.regex_social_security_number += '(\\d{' + char.length + '})?';
                console.log(key)
            })
            let y = 1
            this.format_tel = this.template_tel.replace(/X+/g, () => '$' + y++);
            this.template_tel.match(/X+/g).forEach((char, key) => {
                // eslint-disable-next-line no-useless-escape
                this.regex_tel += '(\\d{' + char.length + '})?';
                console.log(key)
            })
        },
        setup() {
            return {v$: useVuelidate()}
        },
        data() {
            return {
                loading: false,
                loader: null,
                firstName: '',
                lastName: '',
                socialSecurityNumber: '',
                address: '',
                postCode: '',
                city: '',
                email: '',
                phoneNumber: '',
                snackbar1: false,
                snackbar2: false,
                snackbar3: false,
                snackbar4: false,
                snackbar5: false,
                snackbar6: false,
                snackbar7: false,
                snackbar8: false,
                formSubmitted: false,
                format_security_social: '',
                template_social_security_number: 'XX-XX-XX-XXXX',
                regex_social_security_number: '^',
                format_tel: '',
                template_tel: 'XXX-XXX XX XX',
                regex_tel: '^',
                phone_number_exists_already: false
            }
        },
        validations: {
            firstName: {
                required
            },
            lastName: {
                required
            },
            socialSecurityNumber: {
                required
            },
            address: {
                required
            },
            postCode: {
                required,
                numeric
            },
            city: {
                required
            },
            email: {
                required,
                email
            },
            phoneNumber: {
                required,
                minLength: minLength(10)
            },
        },
        methods: {
            checkInput() {
                let returnvalue = true
                if (this.address && this.postCode && this.city && this.firstName && this.lastName && this.socialSecurityNumber && this.email && this.phoneNumber) {
                    returnvalue = false
                }
                return returnvalue
            },
            acceptSocial() {
                var x = this.socialSecurityNumber.replace(/[^0-9]/g, '').match(/(\d{0,2})(\d{0,2})(\d{0,2})(\d{0,4})/);
                this.socialSecurityNumber = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? '-' + x[3] : '') + (x[4] ? '-' + x[4] : '');
            },
            acceptNumber() {
                var x = this.phoneNumber.replace(/[^0-9]/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
                this.phoneNumber = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
            },
            register() {
                this.v$.$validate();
                if (this.v$.$error) {
                    this.formSubmitted = true
                } else {
                    try {

                        this.phone_number_exists_already = false;
                        let without_zero = this.phoneNumber;
                        if (without_zero.charAt(0) === '0') {
                            without_zero = without_zero.substring(1);
                        }
                        let phone_number_formatted = without_zero.replace(/\s/g, '');
                        let newStr = phone_number_formatted.replace(/-/g, "");
                        let new_phone_number = '+46' + newStr;
                        let vm = this;
                        window.db.collection('users').where('phoneNumber', '==', new_phone_number).get().then(function (querySnapshot) {
                            if (querySnapshot.docs.length > 0) {
                                vm.phone_number_exists_already = true
                            }
                        });
                        if (this.phone_number_exists_already === false) {
                            this.isDisabled = true;
                            this.loader = 'loading';
                            Authentication.register({
                                firstName: this.firstName,
                                lastName: this.lastName,
                                socialSecurityNumber: this.socialSecurityNumber,
                                address: this.address,
                                postCode: this.postCode,
                                city: this.city,
                                email: this.email,
                                phoneNumber: new_phone_number
                            });
                            setTimeout(() => this.$router.push({name: 'Login'}), 5000);
                        }
                    } catch (error) {
                        console.log(error)
                    }
                }
            }
        },
        watch: {
            loader() {
                const l = this.loader;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 6000);
                this.loader = null
            },
        },
        computed: {
            isDisabled() {
                return this.checkInput()
            }
        }
    }
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #B3B3B3;
    }


    .last-btn {
        font-size: 30px;
        color: white;
        padding: 10px 170px;
        width: 450px;
        height: 65px;
        background: #00B4FF;
        border-radius: 50px;
    }

    .input {
        margin-bottom: 25px;
        padding-left: 30px;
        font-weight: bold;
        font-family: Proxima_bold, sans-serif;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: x-large;
        background: #eeeeee;
        width: 460px;
        height: 91px;
    }

    input:focus {
        outline: none;
    }

    .firstName {
        float: right;
        margin-right: -450px;
        margin-top: -90px;
        position: relative;
        z-index: 2;
        color: red;
    }

    ::-webkit-scrollbar {
        display: none;
    }

     #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #9fe3ff !important;
    }
</style>