import Api from '@/services/Api'

export default {
    addParkingSpot(parkingdetails) {
        return Api().post('/addparkingspot', parkingdetails)
    },
    getParkingSpots(userId) {
        return Api().get('/fetchparkingspots', userId)
    },
    editParkingSpot(parkingdetails) {
        return Api().post('/editparkingspot', parkingdetails)
    },
    addNewOwner(new_ownersID) {
        return Api().get('/onBoarding/:id/docs', new_ownersID)
    }
}