import Api from '@/services/Api'

export default {
    getUserDetails(userId) {
        return Api().get('/fetchuserinfos', userId)
    },
    modifyUserDetails(userId) {
        return Api().post('/modifyuserinfos', userId)
    },
    modifyAvatar(userId) {
        return Api().post('/modifyavatar', userId)
    },
    deleteUserAccount(userId) {
        return Api().post('/deleteuser', userId)
    },
    getBankAccount(credentials, userId) {
        return Api().get('/getBankAccount', credentials, userId)
    },
    GetIban(credentials){
         return Api().get('/getIban', credentials)
    }
}