<template>
    <div style="display: flex">
        <div class="body">
            <!-- Header -->
            <Header v-if="edit === true" title="Parkeringar"/>
            <Header :parking="parking_selected" v-if="edit === false" title="Edit"/>
            <!-- Body -->
                <pulse-loader style="text-align: center; margin-top: 35vh" :loading="loading_spinner" :color="color"
                              :size="size"></pulse-loader>
            <div v-if="empty_parking_spots===true">
                <EmptyParkingSpot/>
            </div>
            <v-row v-if="parking_spots.length!==0 && edit === true" class="row">
                <!-- Left body content -->
                <v-col class="left-col">
                    <div style="  overflow-y: scroll; height: 80vh">
                        <div v-if="parking_spots_active.length>=1" class="aktiva">
                            <p style="margin-bottom: 25px; font-size: 30px; margin-top: 25px">
                                <strong>Aktiva</strong>
                            </p>
                            <button v-for="parking in parking_spots_active" v-bind:key="parking"
                                    :class="parking.selected===true ? 'selected_parking' : 'unselected_parking'"
                                    style="border-bottom: solid 1px; border-bottom-color: #DFDFDF; width: 100%; height: 68px; margin-bottom: 20px"
                                    @click="show(parking)">
                                <strong style="float: left; font-size: 20px">{{ parking.address }}</strong>
                                <p style="float: right; margin-right: 28px; vertical-align: middle; text-align: center; font-size: 18px;color: #DFDFDF">
                                    Redigera
                                    <v-icon style="color: black; margin-left: 7px; font-size: 35px">east</v-icon>
                                </p>
                                <br>
                                <p class="text-left"
                                   style="color: darkgrey; font-size: 17px; width: fit-content; font-weight: bold; margin-bottom: 20px">
                                    {{ parking.price }} kr / h</p>
                            </button>
                        </div>
                        <div v-if="parking_spots_non_active.length!==0" style="margin-top: 30px">
                            <!--<a href="http://maps.google.com/?q=Baggensvägen 8, Saltsjö-boo" target="_blank">aaaa</a>-->
                            <p style="margin-bottom: 25px; margin-top: 10px; font-size: 30px">
                                <strong>Ej aktiva</strong>
                            </p>
                            <button :class="parking.selected===true ? 'selected_parking' : 'unselected_parking'"
                                    v-for="parking in parking_spots_non_active" v-bind:key="parking"
                                    style="margin-bottom: 20px; border-bottom: solid 1px; border-bottom-color: #DFDFDF; width: 100%; height: 68px"
                                    @click="show(parking)">
                                <strong style="float: left; font-size: 20px">{{ parking.address }}</strong>
                                <p style="float: right; margin-right: 29px; vertical-align: middle; text-align: center; font-size: 18px; color: #DFDFDF">
                                    Redigera
                                    <v-icon style="color: black; font-size: 35px; margin-left: 7px">east</v-icon>
                                </p>
                                <br>
                                <p class="text-left"
                                   style="color: darkgrey; width: fit-content; font-size: 17px; font-weight: bold; margin-bottom: 20px">
                                    {{ parking.price }} kr / h</p>
                            </button>
                        </div>
                    </div>
                </v-col>

                <!-- Right body content -->
                <v-col class="right-col">
                    <div v-if="showPage === false">
                        <div class="text-center circle" style="margin-bottom: 33px; margin-top: 250px"><i
                                style="color: white; margin-top: 25%; font-size: 67px" class="fas fa-pencil-alt"></i>
                        </div>
                        <p style="color: rgb(178,178,178); text-align: center; font-size: 51px; font-weight: bold">Välj
                            en parkering</p>
                        <p style="color: rgb(178,178,178); text-align: center; font-size: 24px; font-weight: bold">Tryck
                            på en parkeringsplats för att redigera<br> uppgifter.</p>
                    </div>
                    <div v-if="showPage === true" style="display: inline-block; width: 70vh; margin-bottom: 30px">
                        <p style="font-size: 45px; color: black; float: left; font-weight: bold; margin-left: 39px; margin-top: 5px; margin-bottom: 22px">
                            Redigera parkering</p>
                        <button class="button"
                                style=" margin-left: 39px; margin-bottom: 20px; height: auto; display: grid"
                                @click="redirect_to_edit('adress')">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 0">Adress</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; color: #B3B3B3;">
                                {{ parking_selected.address}}, {{ parking_selected.zipcode }} {{ parking_selected.city
                                }}</p>
                        </button>
                        <button class="button"
                                style=" margin-left: 39px; margin-bottom: 20px; height: auto; display: inline-block"
                                @click="redirect_to_edit('tider')">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 0">Tider</p>
                            <div style="display: inline-block; margin-bottom: 0; font-weight: bold"
                                 v-if="parking_selected.days && parking_selected.fromReoccuringDate && parking_selected.isReoccuring ===true">
                                <p style="display: inline-block; margin-bottom: 0; color: #B3B3B3; font-weight: bold"
                                   v-for="day in daysName"
                                   v-bind:key="day">{{
                                    day}}, &nbsp;</p>
                                <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; color: #B3B3B3; display: inline-block">
                                    från
                                    {{moment(new Date
                                    (parking_selected.fromReoccuringDate['_seconds']*1000)).format('hh:mm')}}
                                    till
                                    {{moment(new Date
                                    (parking_selected.toReoccuringDate['_seconds']*1000)).format('hh:mm')}}
                                </p>
                            </div>
                            <div style="display: inline-block" v-if="parking_selected.fromSpecificDate && parking_selected.isReoccuring === false"><p
                                    style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; color: #B3B3B3;">
                                från
                                {{moment(new Date (parking_selected.fromSpecificDate['_seconds']*1000)).format('DD ddd MMM')}}
                                till
                                {{moment(new Date (parking_selected.toSpecificDate['_seconds']*1000)).format('DD ddd MMM')}}</p>
                            </div>

                        </button>

                        <button class="button"
                                style="margin-left: 39px; margin-bottom: 20px; height: auto; display: grid; padding-bottom: 23px"
                                @click="redirect_to_edit('information')">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 0">
                                Information</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; display: contents; color: #B3B3B3;">
                                {{ parking_selected.type }}, {{ parking_selected.size }},</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; display: contents; color: #B3B3B3;"
                               v-if="parking_selected.isRoof===true">Under tak , &nbsp;</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; display: contents; color: #B3B3B3;"
                               v-if="parking_selected.isHandicap===true">Handikappsanpassad, &nbsp;</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; display: contents; color: #B3B3B3;"
                               v-if="parking_selected.isCharging===true">Laddningstation</p>
                        </button>
                        <button class="button"
                                style=" margin-left: 39px; margin-bottom: 20px; height: auto; display: grid"
                                @click="redirect_to_edit('bild')">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 0">Bild</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; color: #B3B3B3;">
                                Du
                                har valt en bild</p>
                        </button>
                        <button class="button"
                                style=" margin-left: 39px; margin-bottom: 20px; height: auto; display: grid"
                                @click="redirect_to_edit('pris')">
                            <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 0">Pris</p>
                            <p style="font-size: 17px; font-weight: bold; margin-bottom: 23px; margin-top: 2px; color: #B3B3B3;">
                                Tryck för att ändra</p>
                        </button>
                        <div style="display: inline-flex">
                            <v-btn id="custom-disabled"
                                   @click="deleteParking"
                                   :loading="loading2"
                                   style=" box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); text-transform: none ;font-weight: bold; color: white; font-size: 25px; width: 300px; height: 70px; background: #0E046E; border-radius: 50px; margin: 20px 10px 50px 30px;"
                            >
                                Radera
                            </v-btn>
                            <v-btn @click="editStatus" :loading="loading1"
                                   style="box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2); text-transform: none ;font-weight: bold; color: white; font-size: 25px; width: 300px; height: 70px; background: #0E046E; border-radius: 50px; margin: 20px 10px 50px 30px;"
                            > {{ buttonText }}
                            </v-btn>
                        </div>
                    </div>
                </v-col>
            </v-row>

            <v-row v-if="edit === false" class="row" no-gutters>
                <EditParkingSpot :parking="parking_selected" :field="field_selected"/>
            </v-row>

        </div>
    </div>
</template>

<script>
    import EditParkingSpot from "./EditParkingSpot";
    import Header from "./Header";
    import firebase from "firebase";
    import ParkingSpotApi from "../services/ParkingSpotApi";
    import EmptyParkingSpot from "@/components/EmptyParkingSpot";
    import moment from 'moment';
    import 'moment/locale/sv';
    import PulseLoader from 'vue-spinner/src/SyncLoader.vue'

    export default {
        components: {
            EditParkingSpot,
            EmptyParkingSpot,
            Header,
            PulseLoader
        },
        data() {
            return {
                showPage: false,
                connected_user: '',
                parking_spots: [],
                parking_spots_active: [],
                parking_spots_non_active: [],
                parking: [],
                address: '',
                postkod: '',
                stad: '',
                tider: '',
                parkingType: '',
                parkingSpec: '',
                parkingInfo: '',
                edit: true,
                daysName: [],
                buttonText: '',
                loader1: null,
                loading1: false,
                parking_selected: [],
                field_selected: '',
                loader2: null,
                loading2: false,
                color: '#0E046E',
                size: '45px',
                margin: '2px',
                radius: '2px',
                loading_spinner: true,
                empty_parking_spots: false
            }
        },
        props: ['user'],
        beforeCreate() {
            document.title = "Parkeringar"
        },
        created() {
            let vm = this;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    try {
                        ParkingSpotApi.getParkingSpots({
                            params: {
                                current: firebase.auth().currentUser.uid
                            }
                        }).then((res) => {
                            if(res.data.length>0) {
                                vm.parking_spots = res['data'];
                                vm.parking_spots.forEach(function (el, i) {
                                    el.selected = true;
                                    if (el['status'] === 'Ej aktiv') {
                                        vm.parking_spots_non_active.push(el)
                                    } else if (el['status'] === 'Aktiv') {
                                        vm.parking_spots_active.push(el)
                                    }
                                    if (vm.parking_spots.length === i + 1) {
                                        if (vm.$route.params.parkingSpotId) {
                                            vm.parking_spots.forEach(el => {
                                                if (el['privateDocumentID'] === vm.$route.params.parkingSpotId) {
                                                    vm.show(el)
                                                }
                                            })
                                        }
                                    }
                                });
                                if (vm.parking_spots.length === 0) {
                                    vm.loading_spinner = false;
                                    vm.empty_parking_spots = true
                                } else {
                                    vm.loading_spinner = false
                                }
                            }else{
                                this.loading_spinner = false;
                                this.empty_parking_spots = true
                            }
                        }).catch(()=>{
                            vm.loading_spinner = false;
                            vm.empty_parking_spots = true;
                        });
                    } catch (e) {
                        this.loading_spinner = false;
                        this.empty_parking_spots = true;
                        console.log(e)
                    }
                }
            })
        },
        methods: {
            editStatus() {
                this.loader1 = 'loading1';
                let status = '';
                if (this.buttonText === 'Aktivera') {
                    status = 'Aktiv'
                } else {
                    status = 'Ej aktiv'
                }
                let vm = this;
                window.db.collection('stripe_connected_accounts').doc(firebase.auth().currentUser.uid).get().then(function (thisDoc) {
                    if (thisDoc.exists) {
                        window.db.collection('users').doc(firebase.auth().currentUser.uid).collection('lots').doc(vm.parking_selected['privateDocumentID']).update({
                            status: status
                        }).then(r => {
                            console.log(r);
                            window.db.collection('lots').doc(vm.parking_selected['publicDocumentID']).update({
                                status: status
                            }).then(() => {
                                vm.$router.go(0);
                            });
                        }).catch(error => {
                            console.log(error)
                        })
                    } else {
                        vm.$router.push({
                            name: 'bankVerification',
                        });
                    }
                }).catch((reason => {
                    console.log(reason)
                }))
            },
            moment,
            redirect_to_edit(field) {
                this.field_selected = field;
                this.edit = !this.edit
            },
            signout() {
                firebase.auth().signOut();
                this.$store.dispatch('setToken', null)
                this.$store.dispatch('setUser', null)
                this.$router.push({path: '/'});
                window.location.reload(); //reload on signout !
            },
            deleteParking() {
                this.loader2 = 'loading2';
                let vm = this;
                window.db.collection('users').doc(firebase.auth().currentUser.uid).collection('lots').doc(this.parking_selected['privateDocumentID']).delete().then(r => {
                    console.log(r);
                    window.db.collection('lots').doc(vm.parking_selected['publicDocumentID']).delete().then(() => {
                        this.$router.go(0)
                    });
                });
            },
            show(parking) {
                this.daysName = []
                parking.selected = true;
                this.parking_selected = parking;
                this.showPage = true;
                if (this.parking_spots_non_active.length > 0) {
                    this.parking_spots_non_active.forEach(el => {
                        if (el['privateDocumentID'] !== this.parking_selected['privateDocumentID']) {
                            el.selected = false
                        }
                    })
                }
                if (this.parking_spots_active.length > 0) {
                    this.parking_spots_active.forEach(el => {
                        if (el['privateDocumentID'] !== this.parking_selected['privateDocumentID']) {
                            el.selected = false
                        }
                    })
                }
                if (this.parking_selected) {
                    if (this.parking_selected['status'] === 'Aktiv') {
                        this.buttonText = 'Inaktivera'
                    } else {
                        this.buttonText = 'Aktivera'
                    }
                    if (this.parking_selected['days']) {
                        this.parking_selected['days'].forEach(el => {
                            switch (el) {
                                case 'Monday':
                                    this.daysName.push('Mån');
                                    break;
                                case "Tuesday":
                                    this.daysName.push('Tis');
                                    break;
                                case "Wednesday":
                                    this.daysName.push('Ons');
                                    break;
                                case "Thursday":
                                    this.daysName.push('Tor');
                                    break;
                                case "Friday":
                                    this.daysName.push('Fre');
                                    break;
                                case "Saturday":
                                    this.daysName.push('Lör');
                                    break;
                                case "Sunday":
                                    this.daysName.push('Sön');
                                    break;
                            }
                        })
                    }
                }
            }
        },
        watch: {
            loader1() {
                const l = this.loader1;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 3000);
                this.loader1 = null
            },
            loader2() {
                const l = this.loader2;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 3000);
                this.loader2 = null
            }
        }
    }
</script>

<style scoped>
    input:focus {
        outline: none;
    }

    button {
        height: 50px;
        border-bottom: solid 1px;
        border-bottom-color: lightgray;
        width: 100%;
    }

    .aktiva {
        margin-top: 25px;
        margin-bottom: 25px
    }

    * {
        padding: 0;
        margin: 0;
    }

    .circle {
        margin-top: 25%;
        margin-left: auto;
        margin-right: auto;
        background: rgb(178, 178, 178);
        border-radius: 50%;
        width: 119px;
        height: 119px;
    }

    .right-col {
        overflow-y: scroll;
        float: right;
        margin-right: 47px;
        height: 90vh;
        width: 90vh;
        margin-top: 20px;
        text-align: left;

    }

    .left-col {
        float: left;
        border-right: solid #0E046E 1px;
        margin-left: 45px;
        height: 90vh;
        width: 90vh;
        padding-right: 0;
    }


    .row {
        position: fixed;
        height: 100%;
    }

    .body {
        width: 100%;
        float: right;
    }

    ::-webkit-scrollbar {
        display: none;
    }
</style>

<style lang="scss">

    /* Slide */
    .slide {
        &-enter-active,
        &-leave-active {
            position: fixed;
            width: 100%;
            background: white;
            min-height: 100vh;
        }
    }

    // router view
    .slide-enter-active {
        transition: 500ms;
        z-index: 2;
        transform: translateX(100%);
    }

    .slide-enter-to {
        z-index: 2;
        transform: translateX(0%);
    }

    .slide-leave-active {
        z-index: -1;
    }

    .slide-leave-to {
        z-index: -1;
    }

    // router view back

    .slide-back-leave-active {
        transition: 500ms;
        z-index: 2;
        transform: translateX(0%);
    }

    .slide-back-leave-to {
        z-index: 2;
        transform: translateX(100%);
    }

    .selected_parking {
        font-weight: bold;
        color: black;
    }

    .unselected_parking {
        color: grey;
        font-weight: normal;
    }

    .button {
        margin-top: 20px;
        float: left;
        text-align: left;
        border-bottom: solid 2px;
        border-bottom-color: #DFDFDF;
        width: 100%;
        height: auto;
    }
</style>
