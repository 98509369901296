var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-main',[_c('v-row',[_c('v-col',{staticStyle:{"overflow-y":"scroll","height":"100vh"},attrs:{"cols":"6"}},[_c('div',[_c('p',{staticStyle:{"font-size":"62px","font-weight":"bold","margin-bottom":"1px"}},[_vm._v("Registrera")]),_c('p',{staticStyle:{"font-size":"30px"}},[_vm._v("Skapa ett konto och hantera dina "),_c('br'),_vm._v("parkeringsplatser.")])]),_c('v-form',{on:{"submit":function($event){$event.preventDefault();return _vm.register.apply(null, arguments)}}},[_c('div',{staticStyle:{"margin-top":"50px"}},[_c('h6',{staticStyle:{"margin-bottom":"20px","font-weight":"bold"}},[_vm._v("Personlig information")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.firstName),expression:"firstName"}],staticClass:"input",staticStyle:{"margin-bottom":"25px"},attrs:{"placeholder":"Förnamn"},domProps:{"value":(_vm.firstName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.firstName=$event.target.value}}})]),(this.$v.firstName.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar8 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar8 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,1302233913),model:{value:(_vm.snackbar8),callback:function ($$v) {_vm.snackbar8=$$v},expression:"snackbar8"}},[_vm._v(" Förnamn krävs ")])],1):_vm._e(),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.lastName),expression:"lastName"}],staticClass:"input",staticStyle:{"margin-bottom":"25px"},attrs:{"placeholder":"Efternamn"},domProps:{"value":(_vm.lastName)},on:{"input":function($event){if($event.target.composing){ return; }_vm.lastName=$event.target.value}}})]),(this.$v.lastName.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar1 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar1 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,2074264816),model:{value:(_vm.snackbar1),callback:function ($$v) {_vm.snackbar1=$$v},expression:"snackbar1"}},[_vm._v(" Efternamn krävs ")])],1):_vm._e(),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.socialSecurityNumber),expression:"socialSecurityNumber"}],staticClass:"input",attrs:{"placeholder":"Personnummer (ÅÅ-MM-DD-XXXX)"},domProps:{"value":(_vm.socialSecurityNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.socialSecurityNumber=$event.target.value},_vm.acceptSocial]}})]),(this.$v.socialSecurityNumber.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar2 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar2 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,2966373683),model:{value:(_vm.snackbar2),callback:function ($$v) {_vm.snackbar2=$$v},expression:"snackbar2"}},[_vm._v(" Personnummer krävs och måste vara i ett bra format ")])],1):_vm._e(),_c('p',{staticClass:"font-weight-light",staticStyle:{"margin-top":"1px","font-size":"17px","color":"black"}},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Ditt personnummer visas aldrig för andra användare ")])]),_c('br'),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('h6',{staticStyle:{"font-weight":"bold"}},[_vm._v("Adress")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.address),expression:"address"}],staticClass:"input",attrs:{"placeholder":"Adress"},domProps:{"value":(_vm.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.address=$event.target.value}}})]),(this.$v.address.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar3 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar3 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,1069623282),model:{value:(_vm.snackbar3),callback:function ($$v) {_vm.snackbar3=$$v},expression:"snackbar3"}},[_vm._v(" Adress krävs ")])],1):_vm._e(),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.postCode),expression:"postCode"}],staticClass:"input",attrs:{"placeholder":"Postkod"},domProps:{"value":(_vm.postCode)},on:{"input":function($event){if($event.target.composing){ return; }_vm.postCode=$event.target.value}}})]),(this.$v.postCode.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar4 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar4 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,3725245493),model:{value:(_vm.snackbar4),callback:function ($$v) {_vm.snackbar4=$$v},expression:"snackbar4"}},[_vm._v(" Postkod krävs och måste vara numeriskt ")])],1):_vm._e(),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"input",attrs:{"placeholder":"Stad"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}})]),(this.$v.city.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar5 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar5 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,3228466164),model:{value:(_vm.snackbar5),callback:function ($$v) {_vm.snackbar5=$$v},expression:"snackbar5"}},[_vm._v(" Stad krävs ")])],1):_vm._e(),_c('p',{staticClass:"font-weight-light",staticStyle:{"margin-top":"-10px","margin-bottom":"1px","color":"black"}},[_c('i',{staticClass:"fas fa-info-circle"}),_vm._v(" Din privata adress är skyddad och visas aldrig för andra"),_c('br'),_vm._v(" anvandare ")])]),_c('br'),_c('div',{staticStyle:{"margin-top":"20px"}},[_c('h6',{staticStyle:{"font-weight":"bold"}},[_vm._v("Kontoinformation")]),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"input",attrs:{"placeholder":"E-postadress"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}})]),(this.$v.email.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar6 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar6 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,4120575031),model:{value:(_vm.snackbar6),callback:function ($$v) {_vm.snackbar6=$$v},expression:"snackbar6"}},[_vm._v(" E-post krävs och måste vara i ett bra format ")])],1):_vm._e(),_c('label',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phoneNumber),expression:"phoneNumber"}],staticClass:"input",attrs:{"placeholder":"Telefonnummer"},domProps:{"value":(_vm.phoneNumber)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.phoneNumber=$event.target.value},_vm.acceptNumber]}})]),(this.$v.phoneNumber.$invalid && this.formSubmitted===true)?_c('div',{staticStyle:{"display":"flex"}},[_c('button',{on:{"click":function($event){_vm.snackbar7 = true}}},[_c('span',{staticClass:"fa fa-info-circle fa-lg firstName"})]),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"pink","text":""},on:{"click":function($event){_vm.snackbar7 = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}],null,false,2223824630),model:{value:(_vm.snackbar7),callback:function ($$v) {_vm.snackbar7=$$v},expression:"snackbar7"}},[_vm._v(" Telefonnummer krävs och måste vara giltigt ")])],1):_vm._e(),_c('p',{staticClass:"font-weight-light text-success",staticStyle:{"margin-top":"-10px"}},[_c('i',{staticClass:"fas fa-lock"}),_vm._v(" Vi tar din integritet på fullaste allvar och har utvecklat "),_c('br'),_vm._v("FastPark som en tjänst där din säkerhet är av högsta "),_c('br'),_vm._v("prioritet. ")])]),_c('div',[_c('br'),_c('v-btn',{staticStyle:{"text-transform":"none","font-size":"30px","color":"white","padding":"10px 170px","width":"450px","height":"65px","background":"#00B4FF","border-radius":"50px","font-weight":"bold"},attrs:{"loading":_vm.loading,"disabled":_vm.isDisabled},on:{"click":_vm.register}},[_vm._v(" Slutför ")])],1)]),_c('br'),_c('br'),_c('v-snackbar',{scopedSlots:_vm._u([{key:"action",fn:function(ref){
var attrs = ref.attrs;
return [_c('v-btn',_vm._b({staticStyle:{"text-transform":"none"},attrs:{"color":"red","text":""},on:{"click":function($event){_vm.phone_number_exists_already = false}}},'v-btn',attrs,false),[_vm._v(" Stäng ")])]}}]),model:{value:(_vm.phone_number_exists_already),callback:function ($$v) {_vm.phone_number_exists_already=$$v},expression:"phone_number_exists_already"}},[_vm._v(" Telefonnummer som redan används ")])],1),_c('v-col',{attrs:{"cols":"6"}},[_c('v-img',{staticClass:"ml-3",staticStyle:{"margin-top":"25px","width":"676px","height":"679px"},attrs:{"contain":"","src":require('../assets/code_activation.svg')}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }