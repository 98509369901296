import Vue from 'vue'
import Router from 'vue-router';
import ParkingSpots from "../components/ParkingSpots";
import Bookings from "../components/Bookings";
import Register from "../components/Register";
import store from '../store/store'
import Logintest from "../components/Logintest";
import Bankaccount from "@/components/Bankaccount"
import AddParkingSpot from "../components/AddParkingSpot";
import ParkingSpotConfirmation from "../components/ParkingSpotConfirmation";
import Settings from "../components/Settings";
import Policy from "../components/Policy";
import Anvandarvillkor from "../components/Anvandarvillkor";
import Chat from "@/components/Chat";
import BookingDetails from "@/components/BookingDetails";
import onBoarding from "../components/onBoarding";
Vue.use(Router);

export default new Router({
    mode: 'history',
    routes: [
        {
            path: '/',
            beforeEnter: (to, from, next) => {
                if (!store.state.user) {
                    next()
                } else {
                    next('/parkingspots')
                }
            },
            name: 'Login',
            component: Logintest,
            meta: {
                hideNavbar: true,
            }
        },
        {
            path: '/register',
            beforeEnter: (to, from, next) => {
                if (!store.state.user) {
                    next()
                } else {
                    next('/parkingspots')
                }
            },
            name: 'Register',
            component: Register,
            meta: {
                hideNavbar: true,
            }
        },
        {
            path: '/parkingspots',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            name: 'ParkingSpots',
            component: ParkingSpots
        },
        {
            path: '/parkingspotsconfirmation',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            }, name: 'ParkingSpotsConfirmation',
            component: ParkingSpotConfirmation
        },
        {
            path: '/bookings',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            }, name: 'Bookings',
            component: Bookings
        },
        {
            path: '/chats',
            name: 'testChat',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: Chat
        },
        {
            path: '/addparkingspot',
            name: 'Addparkings',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: AddParkingSpot
        },
        {
            path: '/bankverification',
            name: 'bankVerification',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: Bankaccount
        },
        {
            path: '/settings',
            name: 'settings',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: Settings
        },
        {
            path: '/policy',
            name: 'Policy',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: Policy
        },
        {
            path: '/Anvandarvillkor',
            name: 'Anvandarvillkor',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: Anvandarvillkor
        },
        {
            path: '/bookingsdetails',
            name: 'bookingsdetails',
            beforeEnter: (to, from, next) => {
                if (store.state.user) {
                    next()
                } else {
                    next('/')
                }
            },
            component: BookingDetails
        },
        {
            path: '/onBoarding/:id',
            name: 'onBoarding',
            component: onBoarding,
            meta: {
                hideNavbar: true,
            }
        }
    ]
})

