<template>
    <div style="float: left; margin-top: 0; border-right: #080056 solid 1px; width: 320px">
        <v-navigation-drawer style="background: rgb(14,4,110); z-index: 1; margin-top: 0; width: 320px"
                             class="sidebar" app permanent>
            <div style="background: #080056 ; border-bottom: solid 1px; border-bottom-color: rgb(56,50,119)">
                <v-img role="button" src="../assets/fastpark-liggande-light.png" alt="#" @click="parking()"></v-img>
            </div>
            <div>
                <button class="setting" @click="settings">
                    <p style="color: white;  font-weight: bold;"><i class="fas fa-cog" style="margin-right: 10px"></i>
                        Inställningar
                    </p>
                </button>

                <p style="margin-left: 10px; color: rgb(189,189,189); font-size: 15px; margin-top: 45px">
                    <strong>Hantera</strong></p>

                <!--- Add --->
                <router-link :to="{path: '/addparkingspot'}" style="text-decoration: None">
                    <button v-if="$route.name!=='Addparkings'" class="btn-no-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-plus btn-left-logo"></i>
                            Ny parkeringsplats </p>
                    </button>
                    <button v-else style="line-height: 38px" class="btn-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-plus btn-left-logo"></i>
                            Ny parkeringsplats
                        </p>
                        <p style="margin-left: auto; margin-right: 18px"><i v-if="$route.name==='Addparkings'" class="fas fa-arrow-right btn-right-logo"></i></p>
                    </button>
                </router-link>

                <!-- Parkings Spots -->
                <router-link :to="{path: '/parkingspots'}" style="text-decoration: None">
                    <button v-if="$route.name!=='ParkingSpots'" class="btn-no-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-car btn-left-logo"></i>
                            Parkeringar</p>
                    </button>
                    <button v-else style="line-height: 38px" class="btn-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-car btn-left-logo"></i>
                            Parkeringar
                        </p>
                        <p style="margin-left: auto; margin-right: 18px"><i v-if="$route.name==='ParkingSpots'" class="fas fa-arrow-right btn-right-logo"></i></p>
                    </button>
                </router-link>

                <!-- Booking -->
                <router-link :to="{path: '/bookings'}" style="text-decoration: None">
                    <button v-if="$route.name!=='Bookings'" class="btn-no-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-book btn-left-logo"></i>
                            Bokningar</p>
                    </button>
                    <button v-else style="line-height: 38px" class="btn-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-book btn-left-logo"></i>
                            Bokningar
                        </p>
                        <p style="margin-left: auto; margin-right: 18px"><i v-if="$route.name==='Bookings'"
                           class="fas fa-arrow-right"></i></p>
                    </button>
                </router-link>

                <!-- Chat -->
                <router-link :to="{path: '/chats'}" style="text-decoration: None">
                    <button v-if="$route.name!=='testChat'" class="btn-no-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-comment-alt btn-left-logo"></i>
                            Chattar</p>
                    </button>
                    <button v-else style="line-height: 38px" class="btn-active">
                        <p class="btn-text" style="font-weight: bold;"><i class="fas fa-comment-alt btn-left-logo"></i>
                            Chattar
                        </p>
                        <p style="margin-left: auto; margin-right: 18px"><i v-if="$route.name==='testChat'" style="display: inline-block;"
                                       class="fas fa-arrow-right btn-right-logo"></i></p>
                    </button>
                </router-link>
            </div>
            <!-- Footer -->
            <v-footer bottom fixed padless style="background: white;">
                <a style="text-decoration: none; color: black" target="_blank"
                   href="https://apps.apple.com/se/app/fastpark/id1456140053?l=en">
                    <v-row>
                        <v-col cols="3" style="margin-left: 5px">
                            <v-img :src="require('../assets/app-store-logo.png')"></v-img>
                        </v-col>
                        <v-col cols="4" style="font-size: 15px">
                            <p>Upplev FastPark <br> till max.</p>
                        </v-col>
                    </v-row>
                </a>
            </v-footer>
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        name: "Sidebar",
        methods: {
            settings() {
                this.$router.push({path: '/settings'})
            },
            parking() {
                this.$router.push({path: '/parkingspots'})
            }
        }
    }
</script>

<style scoped>
    .sidebar {
        position: relative;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        background: rgb(14, 4, 110);
    }

    .btn-no-active {
        background: rgb(14, 4, 110);
        width: 100%;
        height: 55px;
        text-align: left;
        padding-left: 15px;
        padding-top: 3.5%;
        font-size: large;
        display: flex;
        color: white;
        margin-bottom: 10px;
    }

    .btn-active {
        background: rgb(61, 53, 138);
        width: 100%;
        height: 55px;
        padding-left: 15px;
        padding-top: 3.5%;
        text-align: left;
        font-size: 18px;
        display: flex;
        color: #ffffff;
        margin-bottom: 10px;
    }

    .btn-left-logo {
        margin-right: 10px
    }

    .btn-right-logo {
        margin-left: 75px;
    }

    .setting {
        font-size: large;
        background: #080056;
        text-align: left;
        width: 100%;
        padding-left: 15px;
        padding-top: 12px;
        height: 65px
    }
</style>