<template>
  <div style="display: inline-block; width: 97%; margin-left: 25px;">
    <div class="information">
      <div style="height: 250px; width: 175px;">
        <v-img style="width: 175px; height: 175px; border-radius: 5px"
               :src=img></v-img>
        <v-app id="inspire" style=" margin-left: auto;margin-right: auto;width: 95px;height: 35px;">
          <div class="text-center">
            <v-dialog
                v-model="dialog"
                width="800"
            >
              <template v-slot:activator="{ on, attrs }">
                <button
                    class="view-picture"
                    v-bind="attrs"
                    v-on="on"
                >
                  Visa bild
                </button>
              </template>

              <v-card>
                <v-card-title class="text-h5 grey lighten-2">
                  Visa bild
                </v-card-title>

                <v-img style="width: auto; height: auto;" :src=img></v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                      color="primary"
                      text
                      @click="dialog = false"
                      style="text-transform: none"
                  >
                    Stäng
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </div>
        </v-app>
      </div>
      <div style="margin-left: 15px;">
        <p style="color: black; font-size: 26px">
          Garage i <strong>{{ booking.city }}</strong>
        </p>
        <div style="color: rgb(255,205,34)">
            <div style="display: inline-block">
            <star-rating :show-rating="false" :increment="0.1" v-model="booking['moyenne']" read-only=true star-size="30" style="display: inline-block"></star-rating>
              <p style="color: #9E9E9E; display: inline-block; font-size: 18px"> &nbsp; ({{booking['nb_reviews']}} omdömen)</p>
          </div>
          <p style="color: #9E9E9E; margin-top:10px; margin-bottom: 5px; font-size: 20px"><small>Timpris: {{ booking.price }} kr</small></p>
          <p style="color: #9E9E9E; font-size: 20px"><small>Korttidsparkering</small></p>
        </div>
      </div>
    </div>
    <div class="user" style="margin-top: -15px">
      <p style="color: black; font-size: 33px; font-weight: bold">Användare</p>
      <p style="color: #A1A1A1; font-size: 28px">Bokad av <strong style="color: black">{{ booking.senderName }}</strong></p>
       <p style="color: #150B72; margin-bottom: 5px;  margin-top: 20px; font-weight: bold; font-size: 15px">Kontakt:</p>
      <button style="position: relative; margin-left: -236px; color: black; font-size: 22px; width: 100%" @click="openChat"><strong>Öppna chatten</strong> <i style="color: darkgrey"
                                                                                       class="fas fa-chevron-right"></i>
      </button>
    </div>
    <div class="parkering">
      <p style="font-size: 33px; color: black; font-weight: bold;margin-top: 21px">Parkering</p>
      <div>
        <p style="color: #150B72; margin-bottom: 0;  margin-top: 20px; font-weight: bold; font-size: 15px">Parkering:</p>
        <button style="position: relative; margin-left: -236px; color: black; font-size: 22px; width: 100%" @click="openParking"><strong>Visa parkering </strong><i style="color: darkgrey"
                                                                                             class="fas fa-chevron-right"></i>
        </button>
      </div>
      <div>
        <p style="color: #150B72; margin-bottom: 0; margin-top: 20px; font-weight: bold; font-size: 15px">Parkeringens adress:</p>
        <a style="text-decoration: underline; color: black; font-size: 22px; font-weight: bold; margin-bottom: 0; width: 100%; height: 100%" :href="href" target="_blank">{{ booking.address }}, {{ booking.postkod }} {{ booking.city }}&nbsp; <i style="text-decoration: none" class="fas fa-map-pin"></i></a>
      </div>
    </div>

    <div class="tider">
      <p style="font-size: 33px; color: black; font-weight: bold;margin-top: 21px">Tider</p>
      <p  style="font-size: 33px; color: black; font-weight: bold; display: inline-block">{{ booking.from.split(':')[0]}}:{{ booking.from.split(':')[1]}} {{booking.from.substr(booking.from.length-2, booking.from.length)}} - {{ booking.to.split(':')[0] }}:{{ booking.to.split(':')[1] }} {{booking.from.substr(booking.to.length-2, booking.to.length)}} </p>
      <p style="color: grey; font-size: 30px; display: inline-block"> &nbsp;({{ booking.hoursNb }} timmar)</p>
    </div>

    <div>
      <p style="font-size: 33px; color: black; font-weight: bold;margin-top: 21px">Betalning</p>
      <div>
        <p style="display: inline-block; font-size: 21px">Pris &nbsp;</p>
        <p style="display: inline-block;  float: right; margin-right: 3px; font-size: 21px; color: #4A4A4A">{{ price.toFixed(2) }} SEK ({{ booking.hoursNb }}
          timmar)</p>
      </div>
      <div style="color:#9A9A9A;">
        <p style="display: inline-block; font-size: 21px">Transaktionsavgift &nbsp;</p>
        <p style="display: inline-block; float: right; margin-right: 3px; font-size: 21px"> -{{ transaktionsavgift.toFixed(2) }} SEK</p>
      </div>
      <div>
        <p style="display: inline-block; font-weight: bold; font-size: 21px">Total summa &nbsp;</p>
        <p style="font-size: 21px; display: inline-block;  float: right; margin-right: 3px; font-weight: bold; margin-bottom: 20px">{{ total.toFixed(2) }} SEK</p>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import StarRating from 'vue-star-rating'

export default {
  name: "BookingDetails",
  props: ['booking'],
  components: {
    StarRating
  },
  data() {
    return {
      transaktionsavgift: 2.5,
      dialog: false,
      img: '',
      href: ''
    }
  },
  mounted() {
    this.href = "https://maps.google.com/maps?q=" + this.booking.address + ", " + this.booking.postkod +  this.booking.city
    let vm = this
    firebase.storage().ref('lotImages/' + vm.booking.img + ".jpg").getDownloadURL().then(imgUrl => {
      vm.img = imgUrl
    })
  },
  methods: {
    openChat() {
      this.$router.push({
        name: 'testChat',
        params: {senderUID: this.booking.senderUID}
      });
    },
    openParking() {
      this.$router.push({
        name: 'ParkingSpots',
        params: {parkingSpotId: this.booking.parkingSpotId}
      });
    }
  },
  computed: {
    price() {
      let timeprice = this.booking.price
      let nb_hours = this.booking.hoursNb
      return timeprice * nb_hours
    },
    total() {
      return this.price - this.transaktionsavgift
    }
  }
}
</script>

<style scoped>
html {
  height: 100%;
}

.view-picture {
  display: block;
  position: relative;
  z-index: 10;
  margin-top: -17.5px;
  margin-left: auto;
  margin-right: auto;
  width: 95px;
  height: 35px;
  background: white;
  box-shadow: 0 3px 5px 0 rgba(0,0,0,0.2);
  border-radius: 50px;
  font-weight: bold;
  font-size: 16px;
  color: black;
}

.information {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
}

.user {
  padding-bottom: 25px;
  border-bottom: solid 1px;
  border-bottom-color: lightgray;
}

.body {
  width: 100%;
  height: 100%;
  float: right;
}

.parkering {
  padding-bottom: 25px;
  border-bottom: solid 1px;
  border-bottom-color: lightgray;
}

.tider {
  padding-bottom: 25px;
  border-bottom: solid 1px;
  border-bottom-color: lightgray;
}
</style>