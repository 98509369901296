<template>
    <div>
        <v-col style="margin-top: 12%; text-align: center; height: 100%">
            <div class="circle" style="margin-left: 46%;"><i
                    style="color: white; margin-top: 25%; font-size: 67px" class="fas fa-book"></i></div>
            <p style="color: rgb(178,178,178); font-weight: bold; margin-top: 33px; margin-bottom: 5px; font-size: 51px">Inga chattar</p>
            <p style="color: rgb(178,178,178); font-weight: bold; font-size: 24px">Kommunicera med dina hyrsgäster här.</p>
        </v-col>
    </div>
</template>

<script>

    export default {}
</script>

<style scoped>
    html {
        height: 100%;
    }

    .circle {
        background: rgb(178, 178, 178);
        border-radius: 50%;
        width: 137px;
        height: 137px;
    }
</style>