<template>
    <div>
        <div v-show="!token">
            <div class="split left">
                <div class="centered">
                    <img class="ml-3" style="height: 119px; width: 167px"
                         :src="require('../assets/fastpark-staende.svg')"
                         alt="#">
                    <p style="font-size: 62px ;font-family: Proxima_thin_it,sans-serif;"><strong>Välkommen</strong></p>
                    <p style="font-size: 30px; font-weight: bold; font-family: Proxima_thin_it,sans-serif; margin-bottom: 30px">
                        Logga in eller skapa ett konto</p>
                    <input type="tel"
                           style="margin-top: 25px; font-weight: bold; font-family: Proxima_bold,sans-serif; height: 90px; width: 468px"
                           class="input" v-model="phNo" placeholder="Telefonnummer" @input="acceptNumber"
                           v-on:keyup.enter="submit"/>

                    <v-btn
                            style="text-transform: none;margin-top: 45px;  font-weight: bold; color: white;font-size: 25px; margin-bottom: 25px; position: center; width: 300px; height: 70px; background: #00B4FF; border-radius: 50px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);"
                            :loading="loading"
                            @click="submit"
                    >
                        Logga in
                    </v-btn>

                    <div v-show="captcha" id="recaptcha-container"></div>
                    <v-spacer></v-spacer>
                    <button class="apple-sign-in" @click="loginWithApple">
                        <i class="fab fa-apple"></i> Logga in med Apple
                    </button>
                    <v-spacer></v-spacer>
                    <router-link :to="{name: 'Register'}"
                                 style="text-decoration: none; color: #00B4FF; font-size: 20px; font-weight: bold">
                        <button class="register">
                            Registrera ett konto
                        </button>
                    </router-link>

                        <v-snackbar
                                style="margin-bottom: -60px"
                                bottom
                                v-if="empty_phone_number.length>0 && phNo.length===0"
                                v-model="snackbar1"
                        >
                            {{empty_phone_number}}
                            <template v-slot:action="{ attrs }">
                                <v-btn
                                        color="pink"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar1 = false"
                                        style="text-transform: none"
                                >
                                    Stäng
                                </v-btn>
                            </template>
                        </v-snackbar>
                </div>

            </div>


            <div class="split right">
                <div class="centered">
                </div>
            </div>

        </div>
        <br>
        <div v-show="token">
            <div style="width: 100%">
                <v-row style="margin-top: 10%">
                    <v-col style="margin-left: 10%; width: 460px; margin-top: 50px">
                        <p style="font-size: 62px"><strong>Verifiera</strong></p>
                        <a style="font-size: 30px">Ange koden som skickades till <br>{{ phone_number_formatted }}</a>
                        <div>
                            <input type="number"
                                   style="height:90px; width: 460px; font-weight: bold; font-family: Proxima_bold,sans-serif; text-align: left; padding-left: 15px; margin-top: 45px"
                                   class="input" v-model="otp" placeholder="6-siffrig kod"/>
                        </div>
                        <div style="display: flex" v-if="showProgress===true">
                            <v-progress-circular
                                    indeterminate
                                    color="primary"
                                    class="firstName"
                            ></v-progress-circular>

                        </div>
                    </v-col>
                    <v-col style="margin-right: 10%; margin-top: -65px">
                        <v-img width="70vh" :src="require('../assets/code_activation.svg')" alt="#"></v-img>
                    </v-col>
                    <div v-if="code_incorrect.length>0">
                        <v-snackbar
                                v-model="snackbar2"
                        >
                            {{code_incorrect}}
                            <template v-slot:action="{ attrs }">
                                <v-btn
                                        color="pink"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar2 = false"
                                        style="text-transform: none"
                                >
                                    Stäng
                                </v-btn>
                            </template>
                        </v-snackbar>
                    </div>
                </v-row>
            </div>
        </div>
        <br>
    </div>
</template>

<script>
    import firebase from 'firebase'
    import Authentication from "../services/Authentication";
    import "firebase/auth" // 👈 this could also be in your `firebase.js` file

    export default {
        data() {
            return {
                phNo: '',
                appVerifier: '',
                otp: '',
                token: '',
                recaptchaVerifier: null,
                recaptchaWidgetId: null,
                confirmResult: null,
                loader: null,
                loading: false,
                showProgress: false,
                format_tel: '',
                template_tel: 'XXX-XXX XX XX',
                regex_tel: '^',
                phone_number_formatted: '',
                captcha: false,
                code_incorrect: '',
                snackbar1: false,
                snackbar2: false,
                empty_phone_number: ''
            }
        },
        beforeCreate() {
            document.title = "Logga in"
        },
        mounted() {
            let vm = this
            setTimeout(() => {
                window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container', {
                    'size': 'invisible',
                    'callback': function (response) {
                        vm.token = response
                        // reCAPTCHA solved, allow signInWithPhoneNumber.
                        // ...
                    },
                    'expired-callback': function () {
                        // Response expired. Ask user to solve reCAPTCHA again.
                        // ...
                    }
                });
                //
                this.appVerifier = window.recaptchaVerifier
            }, 1000)
            let y = 1
            this.format_tel = this.template_tel.replace(/X+/g, () => '$' + y++);
            this.template_tel.match(/X+/g).forEach((char, key) => {
                // eslint-disable-next-line no-useless-escape
                this.regex_tel += '(\\d{' + char.length + '})?';
                console.log(key)
            })
        },
        methods: {
            acceptNumber() {
                var x = this.phNo.replace(/[^0-9]/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,2})(\d{0,2})/);
                this.phNo = !x[2] ? x[1] : '' + x[1] + '-' + x[2] + (x[3] ? ' ' + x[3] : '') + (x[4] ? ' ' + x[4] : '');
            },
            submit() {
                if (this.phNo.length > 0) {
                    let without_zero = this.phNo;
                    if (without_zero.charAt(0) === '0') {
                        without_zero = without_zero.substring(1);
                    }
                    let phone_number_formatted = without_zero.replace(/\s/g, '')
                    let newStr = phone_number_formatted.replace(/-/g, "");
                    let new_phone_number = '+46' + newStr;
                    this.phone_number_formatted = new_phone_number;
                    this.loader = 'loading';
                    Authentication.checkIfUserIsRegistered({
                        phone_number: new_phone_number
                    }).then(r => {
                        if (r.data.result === true) {
                            this.recaptchaVerifier = new firebase.auth.RecaptchaVerifier('recaptcha-container');
                            this.recaptchaVerifier.render().then((widgetId) => {
                                this.recaptchaWidgetId = widgetId
                            })
                            let number = this.phone_number_formatted;
                            let appVerifier = this.appVerifier;

                            firebase.auth().signInWithPhoneNumber(number, appVerifier)
                                .then((confirmationResult) => {
                                    this.confirmResult = confirmationResult;
                                })
                                .catch((error) => {
                                    alert("Error" + error)
                                })
                        } else {
                            this.$router.push({name: 'Register'})
                        }
                    })
                } else {
                    this.snackbar1 = true
                    this.empty_phone_number = "Vänligen ange ett telefonnummer"
                }
            },
            verifyCode() {
                this.confirmResult.confirm(this.otp)
                    .then((result) => {
                        this.showProgress = true
                        var user = result.user;
                        this.$store.dispatch('setToken', this.token);
                        this.$store.dispatch('setUser', user);
                        this.$router.go({name: 'ParkingSpots'})
                    })
                    .catch((error) => {
                        this.code_incorrect = 'Koden felaktig';
                        this.snackbar2 = true;
                        console.log(error)
                    })
            },
            async loginWithApple() {
                const provider = new firebase.auth.OAuthProvider('apple.com');
                await firebase.auth().signInWithPopup(provider)
                    .then((result) => {
                        var user = result.user;
                        if (user.uid) {
                            let vm = this
                            window.db.collection('users').where('email', '==', result.user.email).get().then(function (querySnapshot) {
                                if (querySnapshot.docs.length > 0) {
                                    vm.$store.dispatch('setToken', result.credential.accessToken);
                                    vm.$store.dispatch('setUser', JSON.stringify(result.user));
                                    vm.$router.push({name: 'ParkingSpots'})
                                }
                            });

                        } else {
                            this.$router.push({name: 'Register'})
                        }
                    })
                    .catch((error) => {
                        console.log("error");
                        console.log(error);
                        // Handle Errors here.
                        const errorCode = error.code;
                        const errorMessage = error.message;
                        // The credential that was used.
                        console.log(errorCode);
                        console.log(errorMessage);
                    });
            }
        },
        watch: {
            loader() {
                const l = this.loader;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 6000);
                this.loader = null
            },
            otp(value) {
                this.otp = value;
                if (this.otp.length === 6) {
                    this.verifyCode()
                } else {
                    this.snackbar2 = false;
                    this.code_incorrect = ''
                }
            }
        }
    }
</script>

<style scoped>
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #B3B3B3;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .input {
        border-radius: 7px;
        color: black;
        text-align: center;
        font-size: xx-large;
        background: #eeeeee;
        margin-bottom: 20px;
        width: 450px;
        height: 65px;
    }

    .firstName {
        float: right;
        margin-left: 400px;
        margin-top: -83px;
        position: relative;
        z-index: 2;
    }

    .register {
        font-size: 25px;
        position: center;
        width: 300px;
        height: 70px;
        border: 3px solid #00B4FF;
        border-radius: 50px;
    }

    v-text-field {
        background: rgba(174, 171, 171, 0.4);
        border-radius: 10px;
    }

    /* Split the screen in half */
    .split {
        height: 100%;
        width: 50%;
        position: fixed;
        z-index: 1;
        top: 0;
        overflow-x: hidden;
        padding-top: 20px;
    }

    /* Control the left side */
    .left {
        left: 0;
        background-color: white;
    }

    /* Control the right side */
    .right {
        right: 0;
        background: url("../assets/login_home.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    /* If you want the content centered horizontally and vertically */
    .centered {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-align: center;
    }

    /* Style the image inside the centered container, if needed */
    .centered v-img {
        width: 150px;
        border-radius: 50%;
    }

    input:focus {
        outline: none;
    }

    .custom-loader {
        animation: loader 1s infinite;
        display: flex;
    }

    @-moz-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-webkit-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @-o-keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    @keyframes loader {
        from {
            transform: rotate(0);
        }
        to {
            transform: rotate(360deg);
        }
    }

    .apple-sign-in {
        background-color: black;
        color: white;
        font-weight: bold;
        font-size: 25px;
        position: center;
        width: 300px;
        height: 70px;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
        margin-bottom: 25px;
    }
</style>
