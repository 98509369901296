import { render, staticRenderFns } from "./onBoarding.vue?vue&type=template&id=6463b5f6&scoped=true&style=height%3A%20100%25%3B%20width%3A%20100%25%3B&"
import script from "./onBoarding.vue?vue&type=script&lang=js&"
export * from "./onBoarding.vue?vue&type=script&lang=js&"
import style0 from "./onBoarding.vue?vue&type=style&index=0&id=6463b5f6&scoped=true&lang=css&"
import style1 from "./onBoarding.vue?vue&type=style&index=1&id=6463b5f6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6463b5f6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VApp } from 'vuetify/lib/components/VApp';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VApp,VBtn,VCard,VCardText,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VIcon,VImg,VTab,VTabs})
