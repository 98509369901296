<template>
    <div>
        <v-col style="margin-top: 12%; text-align: center; height: 100%">
            <div class="circle" style="margin-left: 46%; margin-bottom: 33px"><i
                    style="color: white; margin-top: 25%; font-size: 67px" class="fas fa-book"></i></div>
            <p style="font-weight: bold; font-size: 50px; color: darkgrey">Inga bokningar</p>
            <p style="margin-top: 15px; font-weight: bold; font-size: 24px; color: darkgrey">Registrera en attraktiv parkeringsplats med <br>tydlig information för att öka dina chanser <br> till bokningar.</p>
        </v-col>
    </div>
</template>

<script>

    export default {}
</script>

<style scoped>
    html {
        height: 100%;
    }
    .circle {
        background: rgb(178, 178, 178);
        border-radius: 50%;
        width: 137px;
        height: 137px;
    }
</style>