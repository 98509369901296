<template style="height: 100%; width: 100%;">
    <div style="display: flex;">
        <img style="text-align: center ; height: 68px; width: 164px; left: 0; top: 0; position: fixed"
             :src="require('../assets/fastpark-liggande.svg')"
             alt="#">
        <div v-if="step === 0" style="height: 100%; width: 100%;">
            <v-img
                    style="width: 232px; height: 232px; border-radius: 5px; margin-left: auto; margin-right: auto; margin-top: 70px"
                    :src=img></v-img>
            <div>
                <p style="margin-left: auto; margin-right: auto; text-align: center; font-size: 50px; height: 182px; width: 926px; color: black; border-bottom: grey solid 1px">
                    Du har en parkering tillgänglig för uthyrning på <strong>{{ address }}.</strong></p>
                <p style=" height: auto; width: 926px; font-size: 30px;  text-align: center; margin-left: auto; margin-right: auto; margin-top: 40px;">
                    Vi har registrerat din parkering åt dig, om du vill göra den aktiv för uthyrning under de tider du
                    inte
                    använder den så kan du komma igång på mindre än 1 minut genom att trycka på knappen nedan.</p>
            </div>
            <div style="margin-left: auto; margin-right: auto; margin-top: 50px; width: fit-content">
                <button @click="step += 1" class="rectangle">Fortsätt</button>
            </div>
        </div>

        <div v-if="step === 1" style="height: 100%; width: 100%">
            <v-img
                    style="width: 113px; height: 113px; border-radius: 5px; margin-left: auto; margin-right: auto; margin-top: 70px"
                    :src=img></v-img>
            <div>
                <p style="height: 61px; width: 926px;  font-size: 50px; font-weight: bold; margin-left: auto; margin-right: auto; text-align: center;  color: black">
                    När vill du hyra ut din parkering?</p>
            </div>

            <div style="margin-top: 76px">
                <div class="little-left-col" style="overflow-y: hidden">
                    <div style=" width: 526px; float: right; margin-right: 108px">
                        <p style="color: black; font-size: 24px; margin-bottom: 26px; font-weight: bold">
                            Uthyrningstyp: </p>
                        <div class="Type">
                            <div style="height: 64px; font-size: 30px; display: block; margin-bottom: 70px">
                                <input id="Specifika" name="Type" type="radio" class="with-font" @click="check_tider()"
                                       value="Specifika"/>
                                <label style="width: 526px; font-size: 47px; float: right" for="Specifika">Specifika
                                    tider</label>
                            </div>

                            <div style="height: 64px; font-size: 30px; display: block; width: 526px; margin-bottom: 70px">
                                <input style="width: 100%" id="Aterkommande" name="Type" type="radio"
                                       class="with-font"
                                       @click="check_tider()" value="Aterkommande"/>
                                <label style="width: 526px; font-size: 47px" for="Aterkommande">Aterkommande</label>
                            </div>

                            <div style="height: 64px; font-size: 30px; display: block; width: 526px; margin-bottom: 10px">
                                <input id="Langtidsuthyrning" name="Type" type="radio"
                                       class="with-font"
                                       @click="check_tider()" value="Langtidsuthyrning"/>
                                <label style="width: 526px; font-size: 47px"
                                       for="Langtidsuthyrning">Langtidsuthyrning</label>
                            </div>
                        </div>
                    </div>

                </div>
                <div class="little-right-col">
                    <div style="margin-left: 108px">
                        <!--- IF SPECIFIKA IS SELECT --->
                        <div v-if="checktider==='Specifika'">
                            <p style="color: black; margin-left: 12px;  font-size: 23px; font-weight: bold;">Tider: </p>
                            <p role="button" @click="show_and_scroll_specifica"
                               style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                        class="material-icons-two-tone">calendar_today
                                </v-icon>
                                Ändra
                                datum och tid
                            </p>

                            <div v-show="show_specifika === true" style="height: 420px;">
                                <a-range-picker :open="show_specifika_calendar" size="large"
                                                style="width: 60vh; height: 91px; font-size: 15px"
                                                @change="onChangeSpecif"
                                                :show-time='{format: "HH:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                @ok="on_ok"
                                                :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: 50px; margin-bottom: 30px; float: left; display: block;  width: 70vh; margin-left: 12px">
                                <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">Hyrs ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                   v-if="range.start !=null">
                                    {{range.start.locale('sv').format('DD')}}
                                    {{range.start.locale('sv').format('MMM')}}, {{range.start.format("HH:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: 50px; float: left; display: block; width: 70vh; margin-left: 12px; margin-top: 50px;">
                                <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">Hyrs ut
                                    till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{range.end.locale('sv').format('DD')}} {{range.end.locale('sv').format('MMM')}},
                                    {{range.end.format("HH:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>
                        </div>

                        <!--- IF ATTERKOMMANDE IS SELECT --->
                        <div v-if="checktider==='Aterkommande'">
                            <div style="width: 100%; position: relative">
                                <p style="color: black; margin-left: 12px;  font-size: 23px; font-weight: bold;">Tider
                                    för uthyrning :</p>
                                <p role="button" @click="show_and_scroll_ater"
                                   style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                    <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                            class="material-icons-two-tone">calendar_today
                                    </v-icon>
                                    Ändra tider
                                </p>

                                <div id="time" style="display: flex">
                                    <div v-show="show_tider === false"
                                         style="height: 50px; margin-bottom: 30px;  width: fit-content; margin-left: 12px">
                                        <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">
                                            Hyrs ut från:</p>
                                        <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                            hourstartstring }}</p>
                                    </div>

                                    <div v-show="show_tider === false"
                                         style="height: 50px; width: fit-content; margin-left: 12px;">
                                        <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">
                                            Hyrs ut till:</p>
                                        <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{ hourendstring
                                            }}</p>
                                    </div>
                                </div>

                                <b-row v-if="show_tider === true"
                                       style="height: auto; width: 100%; position: relative; margin-bottom: 20px">
                                    <b-col md="auto">
                                        <a-time-picker :open="open" @openChange="handleOpenChange"
                                                       size="large" format="HH:mm"
                                                       @change="onChangeTimeStart"
                                                       v-model="hourstart">
                                            <a-button slot="addon" size="small" type="primary"
                                                      @click="handleClose">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                    <b-col md="auto" style="margin-left: 40px">
                                        <a-time-picker :open.sync="open2" format="HH:mm" size="large"
                                                       @change="onChangeTimeEnd"
                                                       v-model="hourend">
                                            <a-button slot="addon" size="small" type="primary" @click="handleClose2">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                </b-row>
                            </div>

                            <div v-if="checktider==='Aterkommande'"
                                 style="width: 70vh; display: inline-block; overflow-x: auto; margin-top: 25px; margin-left: 12px">
                                <div :class="monday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Monday" value="Monday"
                                           @click="monday = !monday"
                                           v-model="checkedDays">
                                    <label :class="monday===true ? 'white' : 'blue'" for="Monday">Måndag</label>
                                </div>
                                <div :class="tuesday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Tuesday" value="Tuesday"
                                           @click="tuesday = !tuesday"
                                           v-model="checkedDays">
                                    <label :class="tuesday===true ? 'white' : 'blue'" for="Tuesday">Tisdag</label>
                                </div>
                                <div :class="wednesday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Wednesday" value="Wednesday"
                                           @click="wednesday = !wednesday" v-model="checkedDays">
                                    <label :class="wednesday===true ? 'white' : 'blue'" for="Wednesday">Onsdag</label>
                                </div>
                                <div :class="thursday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Thursday" value="Thursday"
                                           @click="thursday = !thursday"
                                           v-model="checkedDays">
                                    <label :class="thursday===true ? 'white' : 'blue'" for="Thursday">Torsdag</label>
                                </div>
                                <div :class="friday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Friday" value="Friday"
                                           @click="friday = !friday"
                                           v-model="checkedDays">
                                    <label :class="friday===true ? 'white' : 'blue'" for="Friday">Fredag</label>
                                </div>
                                <div :class="saturday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Saturday" value="Saturday"
                                           @click="saturday = !saturday"
                                           v-model="checkedDays">
                                    <label :class="saturday===true ? 'white' : 'blue'" for="Saturday">Lördag</label>
                                </div>
                                <div :class="sunday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Sunday" value="Sunday"
                                           @click="sunday = !sunday"
                                           v-model="checkedDays">
                                    <label :class="sunday===true ? 'white' : 'blue'" for="Sunday">Söndag</label>
                                </div>
                            </div>
                        </div>

                        <!--- IF Langtidsuthyrning IS SELECT --->
                        <div v-if="checktider==='Langtidsuthyrning'">
                            <p style="font-size: 20px; color: black; margin-left: 12px">Tider för uthyrning :</p>
                            <p role="button" @click="show_and_scroll_lang"
                               style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                        class="material-icons-two-tone">calendar_today
                                </v-icon>
                                Ändra
                                datum och tid
                            </p>
                            <div v-show="show_lang === true"
                                 style="text-align: center; margin-right: auto; margin-left: auto; border-radius: 50px; width: 100%; display: block;">
                                <a-range-picker :open="show_lang_calendar" size="large"
                                                @change="onChangeReoccuring"
                                                :show-time='{format: "HH:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                style="width: 60vh; height: 100px; font-size: 20px"
                                                @ok="on_ok_lang"
                                                :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                            </div>
                            <div v-show="show_lang === false"
                                 style="height: 50px; margin-bottom: 30px;  width: fit-content; margin-left: 12px">
                                <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">Hyrs ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                   v-if="range.start !=null">
                                    {{range.start.locale('sv').format('DD')}}
                                    {{range.start.locale('sv').format('MMM')}}, {{range.start.format("HH:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>
                            <div v-show="show_lang === false"
                                 style="height: 50px; float: left; display: block; width: 70vh; margin-left: 12px; margin-top: 50px">
                                <p style="margin-bottom: 0; font-size: 22px; font-weight: bold; color: #C1C1C1">Hyrs ut
                                    till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{range.end.locale('sv').format('DD')}} {{range.end.locale('sv').format('MMM')}},
                                    {{range.end.format("HH:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div style="margin-left: auto; margin-right: auto; width: fit-content">
                <button style="margin-top: 52px" @click="step += 1, days()" class="rectangle">Klar</button>
            </div>

        </div>


        <!--- FINAL --->
        <div v-if="step === 2" style="height: 100vh; width: 100%;  overflow-y: scroll">
            <v-img
                    style="width: 113px; height: 113px; border-radius: 5px; margin-left: auto; margin-right: auto; margin-top: 73px"
                    :src=img></v-img>
            <div>
                <p style="color: black; font-size: 50px; margin-top: 19px; text-align: center; margin-bottom: 61px">
                    <strong>Grattis!</strong>
                    Du kan nu tjäna pengar när du inte <br> använder din parkering.</p>
                <v-app
                        style="margin-bottom: 0; height: fit-content; text-align: center; width: 926px; margin-left: auto; margin-right: auto"
                        id="inspire">
                    <p style="color: black; text-align: left; font-size: 20px; margin-bottom: 20px; font-weight: bold">
                        Pakeringsinformation:</p>
                    <v-expansion-panels
                            multiple>
                        <!--- Adrress --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);"
                                :readonly='true'
                        >
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Adress: &nbsp;</p>
                                <p style="display: inline-block; color: #5B5B5B; font-weight: bold"> {{ address }}, {{
                                    postCode }},
                                    {{ city }}</p>
                                <template v-slot:actions>
                                    <v-icon>
                                        lock
                                    </v-icon>
                                </template>

                            </v-expansion-panel-header>
                        </v-expansion-panel>

                        <!--- Tider --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Tider: &nbsp;</p>
                                <div v-if="checktider==='Aterkommande'">
                                    <p style="display: inline-block; color: black; font-weight: bold"
                                       v-for="day in daysName" :key="day">
                                        {{ day }}, &nbsp;</p>
                                    <p style="display: contents; color: black; font-weight: bold"
                                       v-if="checktider==='Aterkommande' && hourstart">
                                        {{ hourstartstring }} - {{ hourendstring }}</p>
                                </div>

                                <p style="display: inline-block; color: black; font-weight: bold"
                                   v-if="checktider==='Langtidsuthyrning' || checktider==='Specifika'"> {{
                                    range.start.toString().substr(0, 21) }} / {{ range.end.toString().substr(0, 21)
                                    }}</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <p style="color: black; font-size: 23px">Uthyrningstyp:</p>
                                <div class="Type" style="margin-bottom: 30px">
                                    <div style="font-size: 30px; display: block; width: 80vh">
                                        <input id="Spec" name="Type_step_two" type="radio" class="with-font"
                                               @click="check_tider_two()"
                                               value="Specifika"/>
                                        <label class="radio-custom" style="width: 100%; font-size: 45px" for="Spec">Specifika
                                            tider</label>
                                    </div>

                                    <div style="font-size: 30px; display: block; width: 80vh">
                                        <input style="width: 100%" id="Ater" name="Type_step_two" type="radio"
                                               @click="check_tider_two()"
                                               class="with-font" value="Aterkommande"/>
                                        <label style="width: 100%; font-size: 45px;" for="Ater">Aterkommande</label>
                                    </div>

                                    <div style="font-size: 30px; display: block; width: 80vh">
                                        <input style="width: 100%" id="Lang" name="Type_step_two" type="radio"
                                               @click="check_tider_two()"
                                               class="with-font"
                                               value="Langtidsuthyrning"
                                               :checked="isLongTime === true"/>
                                        <label style="width: 100%; font-size: 45px" for="Lang">Langtidsuthyrning</label>
                                    </div>
                                </div>

                                <div id="Speci" v-if="checktider_step_two==='Specifika'">
                                    <p style="font-size: 20px; color: black; margin-left: 12px;">
                                        Tider för uthyrning
                                        :</p>
                                    <div style=" margin-left: 12px;">
                                        <p role="button" @click="show_and_scroll_specifica()"
                                           style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 36px">
                                            <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                                    class="material-icons-two-tone">calendar_today
                                            </v-icon>
                                            Ändra datum och tid
                                        </p>
                                    </div>


                                    <div v-show="show_specifika === true"
                                         style="text-align: center; margin-top: -25px; margin-right: auto; margin-left: 12px; border-radius: 50px; width: 78.5vh; float: left; display: block;">
                                        <a-range-picker :open="show_specifika_calendar" size="large"
                                                        style="width: 60vh; height: 91px; font-size: 15px"
                                                        @change="onChangeSpecif"
                                                        :show-time='{format: "HH:mm"}'
                                                        format="YYYY-MM-DD HH:mm"
                                                        @ok="on_ok"
                                                        :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                                    </div>

                                    <div id="mescouilles" style="height: 100%; display: inline-flex; width: 100%;">
                                        <div v-show="show_specifika === false"
                                             style="width: 50%">
                                            <p style="margin-bottom: 0">Hyrs ut från:</p>
                                            <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                               v-if="range.start !=null">
                                                {{range.start.locale('sv').format('DD')}}
                                                {{range.start.locale('sv').format('MMM')}},
                                                {{range.start.format("HH:mm")}} </p>
                                            <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                                        </div>

                                        <div v-show="show_specifika === false"
                                             style="width: 50%;">
                                            <p style="margin-bottom: 0">Hyrs ut till:</p>
                                            <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                               v-if="range.end !=null">
                                                {{range.end.locale('sv').format('DD')}}
                                                {{range.end.locale('sv').format('MMM')}},
                                                {{range.end.format("HH:mm")}} </p>
                                            <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                                        </div>
                                    </div>
                                </div>

                                <div v-if="checktider_step_two==='Aterkommande'">
                                    <div style="width: 100%; position: relative">
                                        <p style="font-size: 20px; color: black; margin-left: 12px">Tider för uthyrning
                                            :</p>
                                        <p role="button" @click="show_tider = !show_tider"
                                           style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 30px; margin-top: 15px">
                                            <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                                    class="material-icons-two-tone">calendar_today
                                            </v-icon>
                                            Ändra tider
                                        </p>

                                        <div style="display: flex; margin-left: 34%">
                                            <div v-show="show_tider === false"
                                                 style="height: 50px; margin-bottom: 30px;  width: fit-content; margin-left: 12px">
                                                <p style="margin-bottom: 0">Hyrs ut från:</p>
                                                <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                                    hourstartstring }}</p>
                                            </div>

                                            <div v-show="show_tider === false"
                                                 style="height: 50px; width: fit-content; margin-left: 12px;">
                                                <p style="margin-bottom: 0">Hyrs ut till:</p>
                                                <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                                    hourendstring }}</p>
                                            </div>
                                        </div>

                                        <div v-if="show_tider === true"
                                             style="height: auto; width: 100%; text-align: center">
                                            <div style="display: inline-flex; ">
                                                <b-col md="auto">
                                                    <a-time-picker :open="open" @openChange="handleOpenChange"
                                                                   size="large" format="HH:mm"
                                                                   @change="onChangeTimeStart"
                                                                   v-model="hourstart">
                                                        <a-button slot="addon" size="small" type="primary"
                                                                  @click="handleClose">
                                                            Ok
                                                        </a-button>
                                                    </a-time-picker>
                                                </b-col>
                                                <b-col md="auto" style="margin-left: 40px">
                                                    <a-time-picker :open.sync="open2" format="HH:mm" size="large"
                                                                   @change="onChangeTimeEnd"
                                                                   v-model="hourend">
                                                        <a-button slot="addon" size="small" type="primary"
                                                                  @click="handleClose2">
                                                            Ok
                                                        </a-button>
                                                    </a-time-picker>
                                                </b-col>
                                            </div>
                                        </div>
                                    </div>

                                    <div v-if="checktider_step_two==='Aterkommande'" class="days"
                                         style="margin-top: 30px; margin-left: 65px">
                                        <div :class="monday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Mon" value="Monday"
                                                   @click="monday = !monday"
                                                   v-model="checkedDays">
                                            <label :class="monday===true ? 'white-two' : 'blue-two'"
                                                   for="Mon">Måndag</label>
                                        </div>
                                        <div :class="tuesday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Tues" value="Tuesday"
                                                   @click="tuesday = !tuesday"
                                                   v-model="checkedDays">
                                            <label :class="tuesday ===true ? 'white-two' : 'blue-two'"
                                                   for="Tues">Tisdag</label>
                                        </div>
                                        <div :class="wednesday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Wednes" value="Wednesday"
                                                   @click="wednesday = !wednesday" v-model="checkedDays">
                                            <label :class="wednesday===true ? 'white-two' : 'blue-two'" for="Wednes">Onsdag</label>
                                        </div>
                                        <div :class="thursday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Thurs" value="Thursday"
                                                   @click="thursday = !thursday"
                                                   v-model="checkedDays">
                                            <label :class="thursday===true ? 'white-two' : 'blue-two'" for="Thurs">Torsdag</label>
                                        </div>
                                        <div :class="friday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Fri" value="Friday"
                                                   @click="friday = !friday"
                                                   v-model="checkedDays">
                                            <label :class="friday===true ? 'white-two' : 'blue-two'"
                                                   for="Fri">Fredag</label>
                                        </div>
                                        <div :class="saturday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Satur" value="Saturday"
                                                   @click="saturday = !saturday"
                                                   v-model="checkedDays">
                                            <label :class="saturday===true ? 'white-two' : 'blue-two'" for="Satur">Lördag</label>
                                        </div>
                                        <div :class="sunday ? 'dark-theme' : 'light-theme'"
                                             style="border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px">
                                            <input class="with-day" type="checkbox" id="Sun" value="Sunday"
                                                   @click="sunday = !sunday"
                                                   v-model="checkedDays">
                                            <label :class="sunday===true ? 'white-two' : 'blue-two'"
                                                   for="Sun">Söndag</label>
                                        </div>
                                    </div>
                                </div>


                                <div v-if="checktider_step_two==='Langtidsuthyrning'">
                                    <p style="font-size: 20px; color: black; margin-left: 12px;">
                                        Tider för uthyrning
                                        :</p>
                                    <div style=" margin-left: 12px;">
                                        <p role="button" @click="show_and_scroll_specifica()"
                                           style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px; margin-bottom: 36px">
                                            <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                                    class="material-icons-two-tone">calendar_today
                                            </v-icon>
                                            Ändra datum och tid
                                        </p>
                                    </div>


                                    <div v-show="show_specifika === true"
                                         style="text-align: center; margin-top: -25px; margin-right: auto; margin-left: 12px; border-radius: 50px; width: 78.5vh; float: left; display: block;">
                                        <a-range-picker size="large" style="width: 60vh; height: 91px; font-size: 15px"
                                                        @change="onChangeSpecif"
                                                        :show-time='{format: "HH:mm"}'
                                                        format="YYYY-MM-DD HH:mm"
                                                        :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                                    </div>

                                    <div style="height: 100%; display: inline-flex; width: 100%;">
                                        <div v-show="show_specifika === false"
                                             style="width: 50%">
                                            <p style="margin-bottom: 0">Hyrs ut från:</p>
                                            <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                               v-if="range.start !=null">
                                                {{range.start.locale('sv').format('DD')}}
                                                {{range.start.locale('sv').format('MMM')}},
                                                {{range.start.format("HH:mm")}} </p>
                                            <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                                        </div>

                                        <div v-show="show_specifika === false"
                                             style="width: 50%;">
                                            <p style="margin-bottom: 0">Hyrs ut till:</p>
                                            <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                               v-if="range.end !=null">
                                                {{range.end.locale('sv').format('DD')}}
                                                {{range.end.locale('sv').format('MMM')}},
                                                {{range.end.format("HH:mm")}} </p>
                                            <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                                        </div>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!--- Info --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Information: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold"> {{ type_chosen }}, {{
                                    size_chosen }},
                                    {{ checkinfo }}</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="margin-bottom: 42px">
                                    <p style="font-size: 24px; color: black">Parkeringtyp</p>
                                    <div>
                                        <v-card style="width: 100%">
                                            <v-tabs fixed-tabs card v-model="type">
                                                <v-tab title="Tab 1" style="width: 33%" key="first">
                                                    <v-card-text>Parkering</v-card-text>
                                                </v-tab>
                                                <v-tab title="Tab 2" style="width: 33%" key="second">
                                                    <v-card-text>Uppfart</v-card-text>
                                                </v-tab>
                                                <v-tab title="Tab 2" style="width: 33%" key="third">
                                                    <v-card-text>Garage</v-card-text>
                                                </v-tab>
                                            </v-tabs>
                                        </v-card>
                                    </div>
                                </div>
                                <div style="margin-bottom: 42px">
                                    <p style="font-size: 24px; color: black">Storlek:</p>
                                    <v-card style="width: 100%" no-body>
                                        <v-tabs fixed-tabs card v-model="size">
                                            <v-tab title="Tab 1" key='first'>
                                                <v-card-text>Standard (2x5)</v-card-text>
                                            </v-tab>
                                            <v-tab title="Tab 2" key='second'>
                                                <v-card-text>Handikapp (3x5)</v-card-text>
                                            </v-tab>
                                        </v-tabs>
                                    </v-card>
                                </div>
                                <div>
                                    <div class="Specifikationer" style=" width: 100%">
                                        <p style="color: black; font-size: 23px; margin-bottom: 26px">
                                            Specifikationer:</p>
                                        <div style="font-size: 30px; display: block">
                                            <input id="Laddningstation" name="specifikationerCharging" type="checkbox"
                                                   class="with-font"
                                                   @click="specifikation()" value="Laddningstation"/>
                                            <label style="width: 100%" for="Laddningstation">Laddningstation</label>
                                        </div>

                                        <div style="font-size: 30px; display: block">
                                            <input style="width: 100%" id="under_tak" name="specifikationerUnderTak"
                                                   type="checkbox"
                                                   class="with-font"
                                                   @click="specifikation()" value="Under tak"/>
                                            <label style="width: 100%" for="under_tak">Under tak</label>
                                        </div>

                                        <div style="font-size: 30px; display: block">
                                            <input style="width: 100%" id="Handikappsanpassad"
                                                   name="specifikationerHandikap"
                                                   type="checkbox"
                                                   class="with-font" @click="specifikation()"
                                                   value="Handikappsanpassad"/>
                                            <label style="width: 100%"
                                                   for="Handikappsanpassad">Handikappsanpassad</label>
                                        </div>
                                    </div>
                                    <div>
                                        <p style="font-size: 23px; color: black; margin-top: 39px">Övriga detaljer:</p>
                                        <textarea
                                                style="height: 150px; font-size: 20px; text-align: left; margin-bottom: 55px;  color: black"
                                                placeholder="Ange övriga specifikationer och eventuella tips som kan hjälpa för att lättare hitta din parkering…"
                                                v-model="description"
                                                class="input"
                                        ></textarea>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Bild: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold"> Ingen vald</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="margin-left: auto; margin-right: auto; width: 225px;">
                                    <input id="file" type="file" style="display: none;" @change="onFileChange"
                                           accept="image/*"/>
                                    <div id="preview" style="width: 225px; height: 225px; border-radius: 11px">
                                        <v-img v-if="url"
                                               style="width: 225px; height: 225px; z-index: 1; border-radius: 50px"
                                               v-bind:src="url"
                                               alt=""/>
                                    </div>
                                    <label for="file" class="view-picture"
                                           style="margin-left: auto; margin-right: auto ; color: #080056; font-weight: bold"
                                           role="button">Lägg till bild</label>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Pris: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold">
                                    {{ price }} kr / h</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="border-bottom: solid 2px grey">
                                    <div style="width: 100%; margin-bottom: 25px">
                                        <p style="color: black; font-size: 23px">Tispris:</p>
                                        <input class="input"
                                               style="font-size: 75px; background-color: white; color: black; height: auto;  text-align: center;"
                                               placeholder="Ange pris" v-model="price">
                                    </div>

                                    <div style="width: 100%; padding: 0">
                                        <div style="width: fit-content; display: inline-block">
                                            <p style="color: black;font-size: 23px;">Dagspris:</p>
                                            <h1 style="color: black">{{ price_day }}kr</h1>
                                        </div>
                                        <div style="margin-left: 178px; width: fit-content; display: inline-block">
                                            <p style="color: black; font-size: 23px;">Veckopris:</p>
                                            <h1 style="color: black"> {{ price_week }}kr</h1>
                                        </div>
                                    </div>

                                </div>
                                <div style="margin-top: 20px">
                                    <div>
                                        <p style="margin-bottom: 17px; color: black; margin-top: 40px">Priszon:</p>
                                        <p style="color: black; font-size: 47px">{{ city }} <i
                                                class="fas fa-map-pin"></i></p>
                                    </div>
                                    <div>
                                        <p style="margin-bottom: 16px; color: black;">Maxpris</p>
                                        <p style="font-size: 52px; color: red; margin-bottom: 18px">{{ price_zone
                                            }}kr</p>
                                        <p style="color: black; margin-bottom: 16px">Uppskattat omrade:</p>
                                        <p v-if="price > price_zone"
                                           style="color: black; font-size: 47px; margin-bottom: 45px">
                                            Inget
                                            resultat</p>
                                        <p v-if="price <= price_zone && price > yellow_price"
                                           style="color: red; font-size: 47px; margin-bottom: 45px">
                                            I centrum</p>
                                        <p v-if="yellow_price >= price && price > green_price"
                                           style="color: yellow; font-size: 47px; margin-bottom: 45px">Centralt</p>
                                        <p v-if="green_price >= price && price > blue_price"
                                           style="color: green; font-size: 47px; margin-bottom: 45px">Nära stan</p>
                                        <p v-if="price <= blue_price && price > 0"
                                           style="color: blue; font-size: 47px; margin-bottom: 45px">Landsbygd</p>
                                        <p v-if="price === null || price <= 0 "
                                           style="color: black; font-size: 47px; margin-bottom: 45px">Inget
                                            resultat</p>
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-app>


                <!--- Kontoinformation --->
                <v-app
                        style="margin-bottom: 0; height: fit-content; text-align: center; width: 926px; margin-left: auto; margin-right: auto"
                        id="nik">
                    <p style="color: black; text-align: left; font-size: 20px; margin-bottom: 20px; font-weight: bold; margin-top: 45px">
                        Kontoinformation:</p>
                    <v-expansion-panels
                            multiple>
                        <!--- Name --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Namn: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold"> {{ firstName }}, {{
                                    lastName }}</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>

                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <div style="display: inline-flex; text-align: center">
                                    <div style="margin-right: 10px">
                                        <p>First Name :</p>
                                        <input class="little_input" id="firstName" v-model="firstName">
                                    </div>
                                    <div style="margin-right: 10px">
                                        <p>Last Name :</p>
                                        <input class="little_input" id="lastName" v-model="lastName">
                                    </div>
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <!--- Personnummer --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);"
                                :readonly='true'
                        >
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Personnummer: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold"> {{
                                    socialSecurityNumber }}</p>
                                <template v-slot:actions>
                                    <v-icon>
                                        lock
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                        </v-expansion-panel>

                        <!--- Info --->
                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">E-postadress: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold">{{ email }}</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="margin-right: 10px">
                                    <p>E-postadress:</p>
                                    <input class="little_input" id="email" v-model="email">
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>

                        <v-expansion-panel
                                style=" box-sizing: border-box; margin-bottom: 10px; width: 926px; border: 1px solid #979797; border-radius: 5px; background-color: #FFFFFF; box-shadow: 0 2px 4px 0 rgba(0,0,0,0.2);">
                            <v-expansion-panel-header>
                                <p style="display: contents; color: #ADADAD">Telefonnummer: &nbsp;</p>
                                <p style="display: inline-block; color: black; font-weight: bold">{{ phoneNumber }}</p>
                                <template v-slot:actions>
                                    <v-icon style="color: #0E046E">
                                        south
                                    </v-icon>
                                </template>
                            </v-expansion-panel-header>
                            <v-expansion-panel-content>
                                <div style="margin-right: 10px">
                                    <p>Telefonnummer: </p>
                                    <input class="little_input" id="phoneNumber" v-model="phoneNumber">
                                </div>
                            </v-expansion-panel-content>
                        </v-expansion-panel>
                    </v-expansion-panels>
                </v-app>
            </div>

            <div id="button" style="text-align: center; margin-bottom: 82px; margin-top: 65px">
                <v-btn style="text-transform: none ;margin-top: 52px; padding-left: 10px; padding-right: 10px; height: 96px; width: 408px; border-radius: 47.87px; background-color: #00B4FF; box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2); color: #FFFFFF; font-weight: bold;  font-size: 32px;"
                       :disabled="isDisabled" :loading="loading"
                       @click="create_parking_and_user()" class="rectangle">Logga in och aktivera
                </v-btn>
            </div>


        </div>
        <div hidden class="right-col">
            <div class="centered">
            </div>
        </div>
    </div>
</template>

<script>

    import Authentication from "../services/Authentication";
    import ParkingSpotApi from "../services/ParkingSpotApi";
    import firebase from "firebase";
    import moment from 'moment';
    import 'moment/locale/sv'

    export default {
        data() {
            return {
                range: {
                    start: this.getDate,
                    end: null,
                },
                url: require('../assets/empty-picture.jpg'),
                checkedDays: [],
                hourstart: this.date_of_today,
                hourend: null,
                date: new Date(),
                date_of_today: new Date(),
                show_specifika: false,
                show_tider: false,
                show_lang: false,
                checktider: null,
                type: null,
                type_chosen: null,
                size: null,
                size_chosen: null,
                description: null,
                lastName: null,
                firstName: null,
                address: null,
                city: null,
                postCode: null,
                email: null,
                price: null,
                socialSecurityNumber: null,
                appVerifier: '',
                otp: '',
                token: '',
                recaptchaVerifier: null,
                recaptchaWidgetId: null,
                confirmResult: null,
                loader: null,
                loading: false,
                showProgress: false,
                blue_price: null,
                price_zone: null,
                dayMultiplier: null,
                weekMultiplier: null,
                price_day: null,
                price_week: null,
                phoneNumber: null,
                format_security_social: '',
                template_social_security_number: 'XX-XX-XX-XXXX',
                regex_social_security_number: '^',
                format_tel: '',
                template_tel: 'XXX-XXX XX XX',
                regex_tel: '^',
                green_price: null,
                yellow_price: null,
                checkinfo: null,
                isRoof: false,
                isCharging: false,
                isHandicap: false,
                isLongTime: false,
                isAnonymousProfile: false,
                monday_step_two: false,
                tuesday_step_two: false,
                wednesday_step_two: false,
                thursday_step_two: false,
                friday_step_two: false,
                saturday_step_two: false,
                sunday_step_two: false,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                ja_nej: "ja",
                anonym: true,
                step: 0,
                img_name: null,
                img: null,
                daysName: [],
                checktider_step_two: null,
                checkedDays_step_two: null,
                value2: moment(),
                actual_date: null,
                after_date: null,
                hourstartstring: '',
                hourendstring: '',
                show_specifika_calendar: false,
                show_tider_time: false,
                show_lang_calendar: false,
                open: false,
                open2: false,
                checkCharging: '',
                checkRoof: '',
                checkHandicap: '',

            }
        },
        beforeCreate() {
            document.title = "Onboarding"
        },
        mounted() {
            var date = new Date();
            const current = new Date(date.setHours(19));
            this.date_of_today = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            this.actual_date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
            let test = new Date(new Date().setDate(new Date().getDate() + 7));
            let month = test.getMonth() + 1;
            this.after_date = test.getFullYear() + '-' + month + '-' + test.getDate();
            this.hourstart = moment();
            this.hourend = moment().add(5, 'hours').add(30, 'day');
            this.range.start = moment(this.actual_date, 'YYYY-MM-DD');
            this.range.end = moment(this.after_date, 'YYYY-MM-DD')
            this.hourstartstring = this.hourstart.format("HH:mm").toString();
            this.hourendstring = this.hourend.format("HH:mm").toString();
            let id = this.$route.params.id;
            window.db.collection('new_owners')
                .doc(id)
                .get()
                .then(snapshot => {
                    if (snapshot.exists) {
                        let vm = this;
                        let new_owner = snapshot.data();
                        //Owner Informations
                        this.email = new_owner['email'];
                        this.socialSecurityNumber = new_owner['socialSecurityNumber'];
                        this.lastName = new_owner['lastName'];
                        this.firstName = new_owner['firstName'];
                        this.phoneNumber = new_owner['phoneNumber'];
                         console.log(this.email, this.socialSecurityNumber)

                        //Image of Company
                        this.img_name = new_owner['companyImg'];
                        firebase.storage().ref('companyImg/' + vm.img_name).getDownloadURL().then(imgUrl => {
                            vm.img = imgUrl
                        });
                        //Parking Place : Address
                        this.address = new_owner['address'];
                        this.city = new_owner['city'];
                        this.postCode = new_owner['postCode'];
                        //Parking Place : Size
                        this.size_chosen = new_owner['size'];
                        if (new_owner['size'] === "Standard (2x5)") {
                            this.size = 0
                        } else {
                            this.size = 1
                        }
                        //Parking Place : Type
                        this.type_chosen = new_owner['type'];
                        if (new_owner['type'] === "Parkingar") {
                            this.type = 0
                        }
                        if (new_owner['type'] === "Uppfart") {
                            this.type = 1
                        }
                        if (new_owner['type'] === "Garage") {
                            this.type = 2
                        }
                        //Parking Place : Specifikationer
                        if (new_owner['isRoof'] === true) {
                            document.getElementById("under_tak").checked = true;
                            this.isRoof = true;
                        }
                        if (new_owner['isHandicap'] === true) {
                            document.getElementById("Handikappsanpassad").checked = true;
                            this.isHandicap = true;
                        }
                        if (new_owner['isCharging'] === true) {
                            document.getElementById("Laddningstation").checked = true;
                            this.isCharging = true;
                        }
                        //Parking Place : Price
                        this.price = new_owner['price'];

                    } else {
                        this.$router.push({path: '/'})
                    }
                });
            let x = 1;
            this.format_security_social = this.template_social_security_number.replace(/X+/g, () => '$' + x++);
            this.template_social_security_number.match(/X+/g).forEach((char, key) => {
                // eslint-disable-next-line no-useless-escape
                this.regex_social_security_number += '(\\d{' + char.length + '})?';
                console.log(key)
            })
            let y = 1
            this.format_tel = this.template_tel.replace(/X+/g, () => '$' + y++);
            this.template_tel.match(/X+/g).forEach((char, key) => {
                // eslint-disable-next-line no-useless-escape
                this.regex_tel += '(\\d{' + char.length + '})?';
                console.log(key)
            })
        },
        methods: {
            handleOpenChange(open) {
                this.open = open;
            },
            handleClose() {
                this.open = false;
                this.open2 = true;
            },
            handleClose2() {
                this.show_tider = false;
                this.open = false;
                this.open2 = false;
            },
            on_ok() {
                this.show_specifika_calendar = false;
                this.show_specifika = false
            },
            on_ok_lang() {
                this.show_lang_calendar = false
                this.show_lang = false
            },
            onChangeSpecif(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeReoccuring(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeTimeStart(time, timeString) {
                this.hourstart = time;
                this.hourstartstring = timeString
            },
            onChangeTimeEnd(time, timeString) {
                console.log(time, timeString);
                this.hourend = time;
                this.hourendstring = timeString;
                this.show_tider_time = false
            },
            moment,
            show_and_scroll_specifica() {
                this.show_specifika = !this.show_specifika;
                this.show_specifika_calendar = this.show_specifika;
            },
            show_and_scroll_ater() {
                this.show_tider = !this.show_tider;
                this.open = this.show_tider;
                this.show_tider_time = this.show_tider;
            },
            show_and_scroll_lang() {
                this.show_lang = !this.show_lang;
                this.show_lang_calendar = this.show_lang;
            },
            scrollToEnd: function () {
                this.show_specifika_calendar = this.show_specifika;
                this.show_lang_calendar = this.show_lang;
            },
            check_step_two() {
                this.show_specifika = false
                this.show_tider = false
                this.show_lang = false
                if (this.checktider === 'Langtidsuthyrning') {
                    this.checktider_step_two = this.checktider;
                    this.isLongTime = true;
                    document.getElementById("Lang").checked = true;
                    document.getElementById("Spec").checked = false;
                    document.getElementById("Ater").checked = false;
                }
                if (this.checktider === 'Specifika') {
                    this.checktider_step_two = this.checktider;
                    document.getElementById("Lang").checked = false;
                    document.getElementById("Spec").checked = true;
                    document.getElementById("Ater").checked = false;
                }
                if (this.checktider === 'Aterkommande') {
                    this.checktider_step_two = this.checktider;
                    this.checkedDays_step_two = this.checkedDays;
                    this.monday_step_two = this.monday;
                    this.tuesday_step_two = this.tuesday;
                    this.wednesday_step_two = this.wednesday;
                    this.thursday_step_two = this.thursday;
                    this.friday_step_two = this.friday;
                    this.saturday_step_two = this.saturday;
                    this.sunday_step_two = this.sunday;
                    document.getElementById("Lang").checked = false;
                    document.getElementById("Spec").checked = false;
                    document.getElementById("Ater").checked = true;
                }
            },
            days() {
                let monday_exists = false;
                let tuesday_exists = false;
                let wednesday_exists = false;
                let thursday_exists = false;
                let friday_exists = false;
                let saturday_exists = false;
                let sunday_exists = false;
                this.checkedDays.forEach(el => {
                    switch (el) {
                        case 'Monday':
                            this.daysName.forEach(element => {
                                if (element === 'Mån') {
                                    monday_exists = true
                                }
                            })
                            if (monday_exists === false) {
                                this.daysName.push('Mån')
                            }
                            break;
                        case 'Tuesday':
                            this.daysName.forEach(element => {
                                if (element === 'Tis') {
                                    tuesday_exists = true
                                }
                            })
                            if (tuesday_exists === false) {
                                this.daysName.push('Tis')
                            }
                            break;
                        case 'Wednesday':
                            this.daysName.forEach(element => {
                                if (element === 'Ons') {
                                    wednesday_exists = true
                                }
                            })
                            if (wednesday_exists === false) {
                                this.daysName.push('Ons')
                            }
                            break;
                        case 'Thursday':
                            this.daysName.forEach(element => {
                                if (element === 'Tor') {
                                    thursday_exists = true
                                }
                            })
                            if (thursday_exists === false) {
                                this.daysName.push('Tor')
                            }
                            break;
                        case 'Friday':
                            this.daysName.forEach(element => {
                                if (element === 'Fre') {
                                    friday_exists = true
                                }
                            })
                            if (friday_exists === false) {
                                this.daysName.push('Fre')
                            }
                            break;
                        case 'Saturday':
                            this.daysName.forEach(element => {
                                if (element === 'Lör') {
                                    saturday_exists = true
                                }
                            })
                            if (saturday_exists === false) {
                                this.daysName.push('Lör')
                            }
                            break;
                        case 'Sunday':
                            this.daysName.forEach(element => {
                                if (element === 'Sön') {
                                    sunday_exists = true
                                }
                            })
                            if (sunday_exists === false) {
                                this.daysName.push('Sön')
                            }
                            break;
                    }
                })
            },
            specifikation() {
                if (document.querySelector('input[name="specifikationerCharging"]:checked')) {
                    this.checkCharging = "Laddningstation";
                } else {
                    this.checkCharging = "";
                }
                if (document.querySelector('input[name="specifikationerUnderTak"]:checked')) {
                    this.checkRoof = "Under tak";
                } else {
                    this.checkRoof = "";
                }
                if (document.querySelector('input[name="specifikationerHandikap"]:checked')) {
                    this.checkHandicap = "Handikappsanpassad";
                } else {
                     this.checkHandicap = "";
                }
            },
            create_parking_and_user() {
                this.loader = 'loading';
                this.isDisabled = true;
                try {
                    let without_zero = this.phoneNumber
                    if (without_zero.charAt(0) === '0') {
                        without_zero = without_zero.substring(1);
                    }
                    let phone_number_formatted = without_zero.replace(/\s/g, '');
                    let newStr = phone_number_formatted.replace(/-/g, "");
                    var new_phone_number = '+46' + newStr;
                    Authentication.register({
                        firstName: this.firstName,
                        lastName: this.lastName,
                        socialSecurityNumber: this.socialSecurityNumber,
                        address: this.address,
                        postCode: this.postCode,
                        city: this.city,
                        email: this.email,
                        phoneNumber: new_phone_number
                    }).then(res => {
                        let userID = res.data;
                        try {
                            ParkingSpotApi.addParkingSpot({
                                'address': this.address,
                                'postcode': this.postCode,
                                'stad': this.city,
                                'ja_nej': this.ja_nej,
                                'anonym': this.anonym,
                                'range': this.range,
                                'hourstart': this.hourstart,
                                'hoursend': this.hourend,
                                'checkedDays': this.checkedDays,
                                'parkingType': this.type_chosen,
                                'tiderType': this.checktider,
                                'checkinfo': this.checkinfo,
                                'description': this.description,
                                'img': this.url,
                                'price': this.price,
                                "userId": userID,
                                "price_day": this.price_day,
                                "price_week": this.price_week,
                                "storlek": this.size_chosen,
                                "createdAt": new Date(),
                                "checkHandicap": this.checkHandicap,
                                "checkRoof": this.checkRoof,
                                "checkCharging": this.checkCharging,
                            }).then(async (res) => {
                                if (res.status === 200) {
                                    await firebase.storage().ref(`/lotImages/${res.data}`).put(this.imageData);
                                    let vm = this;
                                    await vm.$router.push({name: 'Login'})
                                } else {
                                    window.alert("Error");
                                }

                            })
                        } catch (error) {
                            console.log(error)
                        }
                    })
                } catch (error) {
                    console.log(error)
                }
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.imageData = e.target.files[0];
                this.url = URL.createObjectURL(file);
            },
            check_tider() {
                let select = document.querySelector('input[name="Type"]:checked').value;
                this.show_specifika = false;
                this.show_lang = false;
                this.show_tider = false;
                this.show_tider_time = this.show_tider;
                this.show_lang_calendar = this.show_lang;
                this.show_specifika_calendar = this.show_specifika;
                this.checktider = select;
                return select
            },
            check_tider_two() {
                let select = document.querySelector('input[name="Type_step_two"]:checked').value;
                this.show_specifika = false;
                this.show_lang = false;
                this.show_tider = false;
                this.show_tider_time = this.show_tider;
                this.show_lang_calendar = this.show_lang;
                this.show_specifika_calendar = this.show_specifika;
                this.checktider_step_two = select
                return select
            },
            checkInput() {
                let returnvalue = true
                if (this.firstName && this.lastName && this.email && this.phoneNumber && this.socialSecurityNumber && this.address && this.city && this.postCode) {
                    if ((this.range.start && this.range.end) || (this.hourstart && this.hourend && this.checkedDays)) {
                        if (this.type_chosen && this.size_chosen && this.checktider_step_two) {
                            if (this.url && this.price > 0) {
                                returnvalue = false
                            }

                        }

                    }

                }
                return returnvalue
            }
        },
        watch: {
            step: function (value) {
                let v = this
                if (value === 2) {
                    setTimeout(function () {
                        v.check_step_two()
                    }, 2500)
                }
            },
            type: function (value) {
                if (value === 0) {
                    this.type_chosen = 'Parkering'
                } else if (value === 1) {
                    this.type_chosen = 'Uppfart'
                } else if (value === 2) {
                    this.type_chosen = 'Garage'
                }
            },
            size: function (value) {
                if (value === 0) {
                    this.size_chosen = 'Standard (2x5)'
                } else if (value === 1) {
                    this.size_chosen = 'Handikapp (3x5)'
                }
            },
            city(value) {
                let vm = this;
                window.db.collection('pricezones').doc(value).onSnapshot((querySnapshot) => {
                        if (querySnapshot.exists) {
                            vm.price_zone = querySnapshot.data()['red'];
                            vm.blue_price = querySnapshot.data()['blue'];
                            vm.green_price = querySnapshot.data()['green'];
                            vm.yellow_price = querySnapshot.data()['yellow'];
                            vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                            vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                            vm.price_day = vm.price * vm.dayMultiplier;
                            vm.price_week = vm.price * vm.weekMultiplier
                        } else {
                            window.db.collection('pricezones').doc('standardZones').onSnapshot((querySnapshot) => {
                                vm.price_zone = querySnapshot.data()['red'];
                                vm.blue_price = querySnapshot.data()['blue'];
                                vm.green_price = querySnapshot.data()['green'];
                                vm.yellow_price = querySnapshot.data()['yellow'];
                                vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                                vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                                vm.price_day = vm.price * vm.dayMultiplier;
                                vm.price_week = vm.price * vm.weekMultiplier
                            })
                        }
                    }
                );
            },
            price: function (value) {
                this.price_day = value * this.dayMultiplier;
                this.price_week = value * this.weekMultiplier
            },
            loader() {
                const l = this.loader;
                this[l] = !this[l];
                setTimeout(() => (this[l] = false), 6000);
                this.loader = null
            },
            otp(value) {
                this.otp = value;
                if (this.otp.length === 6) {
                    this.verifyCode()
                }
            },
            socialSecurityNumber() {
                this.socialSecurityNumber = this.socialSecurityNumber.replace(/[^0-9]/g, '').replace(new RegExp(this.regex_social_security_number, 'g'), this.format_security_social).substr(0, this.template_social_security_number.length)
            },
            phoneNumber() {
                this.phoneNumber = this.phoneNumber.replace(/[^0-9]/g, '').replace(new RegExp(this.regex_tel, 'g'), this.format_tel).substr(0, this.template_tel.length)
            },
            checkedDays() {
                this.days()
                let monday_exists = false
                let tuesday_exists = false
                let wednesday_exists = false
                let thursday_exists = false
                let friday_exists = false
                let saturday_exists = false
                let sunday_exists = false
                this.daysName.forEach(el => {
                    switch (el) {
                        case 'Mån':
                            this.checkedDays.forEach(element => {
                                if (element === 'Monday') {
                                    monday_exists = true
                                }
                            })
                            break;
                        case 'Tis':
                            this.checkedDays.forEach(element => {
                                if (element === 'Tuesday') {
                                    tuesday_exists = true
                                }
                            })
                            break;
                        case 'Ons':
                            this.checkedDays.forEach(element => {
                                if (element === 'Wednesday') {
                                    wednesday_exists = true
                                }
                            })
                            break;
                        case 'Tor':
                            this.checkedDays.forEach(element => {
                                if (element === 'Thursday') {
                                    thursday_exists = true
                                }
                            })
                            break;
                        case 'Fer':
                            this.checkedDays.forEach(element => {
                                if (element === 'Friday') {
                                    friday_exists = true
                                }
                            })
                            break;
                        case 'Lör':
                            this.checkedDays.forEach(element => {
                                if (element === 'Saturday') {
                                    saturday_exists = true
                                }
                            })
                            break;
                        case 'Sön':
                            this.checkedDays.forEach(element => {
                                if (element === 'Sunday') {
                                    sunday_exists = true
                                }
                            })
                            break;
                    }
                })
                if (monday_exists === false) {
                    let index = this.daysName.indexOf("Mån");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (tuesday_exists === false) {
                    let index = this.daysName.indexOf("Tis");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (wednesday_exists === false) {
                    let index = this.daysName.indexOf("Ons");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (thursday_exists === false) {
                    let index = this.daysName.indexOf("Tor");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (friday_exists === false) {
                    let index = this.daysName.indexOf("Fer");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (saturday_exists === false) {
                    let index = this.daysName.indexOf("Lör");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (sunday_exists === false) {
                    let index = this.daysName.indexOf("Sön");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
            }
        },
        computed: {
            isDisabled() {
                return this.checkInput()
            },
            getMinDate() {
                const current = new Date();
                return new Date(current.getFullYear(), current.getMonth(), current.getDate());
            }
        }
    }
</script>

<style scoped>
    textarea {
        border: none;
        outline: none;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
    }

    ::-webkit-input-placeholder { /* WebKit, Blink, Edge */
        color: #B3B3B3;
    }

    * {
        margin: 0;
        padding: 0;
    }

    .input {
        padding-left: 10px;
        padding-right: 10px;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: xx-large;
        background: #eeeeee;
        margin-bottom: 20px;
        width: 100%;
        height: 65px;
    }

    v-text-field {
        background: rgba(174, 171, 171, 0.4);
        border-radius: 10px;
    }

    /* Style the image inside the centered container, if needed */
    .centered v-img {
        width: 150px;
        border-radius: 50%;
    }

    input:focus {
        outline: none;
    }

    .right-col {
        position: fixed;
        overflow-y: auto;
        float: right;
        height: 100%;
        width: 50%;
        padding: 0;
        right: 0;
        background: url("../assets/img.png");
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
    }

    .little-right-col {
        height: 404px;
        overflow-y: auto;
        float: right;
        width: 50%;
        padding: 0;
        right: 0;
    }

    .little-left-col {
        height: 404px;
        border-right: grey solid 1px;
        overflow-y: auto;
        float: left;
        width: 50%;
    }

    .left-col {
        position: fixed;
        overflow-y: auto;
        float: left;
        height: 100%;
        width: 50%;
    }

    input:focus {
        outline: none;
    }

    .button {
        margin-top: 20px;
        float: left;
        text-align: left;
        border-bottom: solid 2px;
        border-bottom-color: #B3B3B3;
        width: 100%
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .little_input {
        background: #eeeeee;
        margin-top: 0;
        padding-left: 5px;
        border-radius: 10px;
        width: 225px;
        height: 32px;
    }

    p {
        margin-bottom: 0;
    }

</style>

<style scoped lang="scss">
    ::v-deep .v-application--wrap {
        min-height: fit-content;
    }

    .view-picture {
        line-height: 30px;
        color: #080056;
        text-align: center;
        display: block;
        position: relative;
        z-index: 2;
        margin-top: -17.5px;
        margin-left: auto;
        margin-right: auto;
        width: 100px;
        height: 30px;
        background: white;
        box-shadow: 0px 4px 20px rgba(104, 109, 224, 0.5);
        border-radius: 50px;
        border: #080056 solid 1px;
    }

    /*** Radio Checkbox style ***/


    input[type=radio].with-font,
    input[type=checkbox].with-font {
        float: right;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-font ~ label:before,
    input[type=checkbox].with-font ~ label:before {
        float: right;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #BFBFBF;
        width: 1.4em;
    }

    input[type=radio].with-font:checked ~ label:before,
    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        font-size: 1.2em;
        color: black;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-font ~ label:before {
        float: right;
        content: "\f1db";
    }

    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        color: black;
    }


    .dark-theme {
        width: auto;
        margin-right: 15px;
        background: #080056;
        color: white !important;
        border-color: #080056;
    }

    .light-theme {
        width: auto;
        margin-right: 15px;
        background: white;
        border-color: #080056;
        color: #080056;
    }

    input[type=radio].with-day,
    input[type=checkbox].with-day {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-day ~ label:before,
    input[type=checkbox].with-day ~ label:before {
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #080056;
        width: 1.4em;
    }

    input[type=radio].with-day:checked ~ label:before,
    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        font-size: 1.2em;
        color: white;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-day ~ label:before {
        content: "\f1db";
    }

    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        color: white;
    }

    .days {
        width: 100%;
        margin-top: 7px;
        display: flex !important;
    }

    .rectangle {
        height: 95.74px;
        width: 320px;
        border-radius: 47.87px;
        background-color: #00B4FF;
        box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.2);
        color: #FFFFFF;
        font-weight: bold;
        font-size: 32px;
    }


    label {
        color: #BFBFBF;
    }

    input[type=radio]:checked ~ label {
        color: black;
    }

    .blue {
        color: #080056
    }

    .white {
        color: #FFFFFF;
    }

    .blue-two {
        color: #080056
    }

    .white-two {
        color: #FFFFFF;
    }

    .collapse {
        box-sizing: border-box;
        height: 49px;
        width: 926px;
        border: 1px solid #979797;
        border-radius: 5px;
        background-color: #FFFFFF;
        box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);
    }

    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #00B4FF !important;
    }
</style>