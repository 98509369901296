<template>
    <div id="app">
        <Sidebar v-if="!$route.meta.hideNavbar"/>
        <!-- Right header content -->
        <v-row v-if="!$route.meta.hideNavbar">
            <v-col>
                <button
                        style="margin-right: 15px; background: rgb(244,244,244); width: 175px; height: 48px; border-radius: 5px;"
                        hidden>
                    <strong>Notifikationer</strong><i class="fas fa-bell fa-fw" style="margin-left: 10px"></i>
                </button>
                <div class="btn-group dropdown" style="position: absolute; right: 25px; top: 45px;">
                    <a id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                       style="border-radius: 50px; height: 50px; width: 50px;">
                        <v-img v-if="imgavatar.length > 0" :src="imgavatar"
                               style="border: solid black 1px; height: 50px; width: 50px; border-radius: 50%"></v-img>
                        <v-avatar role="button" v-else size="50"
                                  style="background: #080056; color: #FFFFFF; height: 55px; width: 55px">{{
                            initial.toUpperCase()
                            }}
                        </v-avatar>
                    </a>

                    <div class="dropdown-menu pull-right">
                        <button class="dropdown-item" @click="navigateToSettings" style="font-weight: bold">
                            Inställningar
                        </button>
                        <button class="dropdown-item" @click="signout" style="font-weight: bold">Logga ut</button>
                    </div>
                </div>
            </v-col>
        </v-row>
        <router-view/>
    </div>
</template>

<script>
    import firebase from 'firebase'
    import Sidebar from "@/components/Sidebar";

    export default {
        name: 'app',
        props: ['user'],
        components: {
            Sidebar,
        },
        data() {
            return {
                events: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
                imgavatar: '',
                initial: '',
            }
        },
        methods: {
            signout() {
                firebase.auth().signOut();
                this.$store.dispatch('setToken', null);
                this.$store.dispatch('setUser', null);
                this.$router.push({path: '/'});
                window.location.reload(); //reload on signout !
            },
            navigateToSettings() {
                this.$router.push({path: '/settings'})
            },
            callFunction() {
                let v = this
                this.timeout = setTimeout(function () {
                    v.signout()
                }, 3600000);
            },
            resetTimer: function () {
                clearTimeout(this.timeout)
                this.callFunction()
            }
        },
        mounted() {
            let vm = this;
            firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    if (user.displayName) {
                        if (user.displayName.length === 1) {
                            vm.initial = user.displayName
                        } else {
                            window.db.collection('users').doc(user.uid).get().then(function (thisDoc) {
                                if (thisDoc.exists) {
                                    vm.initial = thisDoc.data()['firstName'].charAt(0).toUpperCase() + thisDoc.data()['lastName'].charAt(0).toUpperCase()
                                }
                            });
                        }
                    }
                    window.db.collection('users').doc(user.uid).get().then(function (thisDoc) {
                        if (thisDoc.exists) {
                            firebase.storage().ref('userProfileImages/' + thisDoc.data()['profileImageUrl'] + ".jpg").getDownloadURL().then(imgUrl => {
                                vm.imgavatar = imgUrl
                            });
                        }
                    });
                }
            })
            vm.events.forEach(function (event) {
                window.addEventListener(event, vm.resetTimer)
            })
        }
    }
</script>
<style>
    @font-face {
        font-family: "Proxima_black";
        src: local("Proxima_black"),
        url(/assets/fonts/Proxima-Nova-Black.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_black_it";
        src: local("Proxima_black_it"),
        url(/assets/fonts/Proxima-Nova-Black-it.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_bold";
        src: local("Proxima_bold"),
        url(/assets/fonts/Proxima-Nova-Bold.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_bold_it";
        src: local("Proxima_bold_it"),
        url(/assets/fonts/Proxima-Nova-Bold-it.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_semibold";
        src: local("Proxima_semibold"),
        url(/assets/fonts/Proxima-Nova-Semibold.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_thin";
        src: local("Proxima_thin"),
        url(/assets/fonts/Proxima-Nova-Thin.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_thin_it";
        src: local("Proxima_thin_it"),
        url(/assets/fonts/Proxima-Nova-Thin-it.otf) format("truetype");

    }

    @font-face {
        font-family: "Proxima_reg";
        src: local("Proxima_reg"),
        url(/assets/fonts/Proxima_Nova-reg.ttf) format("truetype");

    }
</style>