<template>
    <div
            style="border-bottom: solid 1px; border-bottom-color: #DFDFDF; margin-left: 45px; margin-right: 45px; height: auto; padding-top: 38px; padding-bottom: 18px">
        <!-- Header content -->
        <div>
            <v-row no-gutters>
                <!-- Left header content -->
                <v-col cols="12" sm="6" md="8">
                    <button class="h2" @click="push_parking_spot" v-if="this.title === 'Koppla bankkonto'"
                            style="font-weight: bold; font-size: 45px">
                        <v-icon style="color: black; font-size: 40px; font-weight: bold; margin-right: 19px">west
                        </v-icon>
                        {{ this.title }}
                    </button>
                    <button class="h2" @click="push_parking_spot" v-if="this.title === 'Ny parkeringsplats'"
                            style="font-weight: bold; font-size: 45px; margin-left: 10px">
                        <v-icon style="color: black; font-size: 40px; font-weight: bold; margin-right: 19px">west
                        </v-icon>
                        {{ this.title }}
                    </button>
                    <button class="h2" @click="reload" v-if="this.title === 'Edit'"
                            style="font-weight: bold; font-size: 45px; margin-left: 10px">
                        <v-icon style="color: black; font-size: 40px; font-weight: bold; margin-right: 19px">west
                        </v-icon>
                        {{ this.title }} {{ this.parking.address }}
                    </button>
                    <p v-if="this.title !== 'Edit' && this.title !== 'Ny parkeringsplats' && this.title !== 'Koppla bankkonto' "
                       style="font-weight: bold; font-size: 45px">{{ this.title }}</p>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import firebase from "firebase";

    export default {
        name: "Header",
        props: ['title', 'edit', 'parking'],
        data() {
            return {
                imgavatar: '',
                initial: '',
            }
        },
        methods: {
            reload() {
                window.location.reload()
            },
            signout() {
                firebase.auth().signOut();
                this.$store.dispatch('setToken', null)
                this.$store.dispatch('setUser', null)
                this.$router.push({path: '/'})
                window.location.reload(); //reload on signout !
            },
            push_parking_spot() {
                this.$router.push({name: 'ParkingSpots'})
            }
        }
    }
</script>


<style scoped>
    html {
        height: 100%;
    }

    v-row {
        height: 100%;
    }

    .dropdown-menu {
        margin-top: 10px;
        right: 0;
    }

    .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;

        border-bottom: 5px solid black;
    }

    body {
        background: #ededed;
        font-family: 'Open Sans', sans-serif;
    }

    .center {
        position: absolute;
        display: inline-block;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .my-menu {
        margin-top: 40px;
        contain: initial;
        overflow: visible;
    }

    .my-menu::before {
        position: absolute;
        content: "";
        top: 0;
        right: 10px;
        transform: translateY(-100%);
        width: 10px;
        height: 13px;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 13px solid #fff;
    }
</style>