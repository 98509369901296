import axios from 'axios'

export default () => {
    if (process.env.NODE_ENV === "development") {
        return axios.create({
            baseURL: 'http://localhost:5005'
        })
    } else if (process.env.NODE_ENV === "production") {
        return axios.create({
            baseURL: 'https://fastpark-cbda0.uc.r.appspot.com/'
        })
    }

}