<template>
    <div style="display: flex">
        <div class="body">
            <!-- Header -->
            <Header title="Ny parkeringsplats"/>
            <!-- Body -->
            <v-row class="row">
                <!-- Left body content -->
                <v-col class="left-col" style="overflow-y: scroll">
                    <div>
                        <button class="button" @click="showAdress()" style="margin-bottom: 20px">
                            <div style="float: left">
                                <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">
                                    Adress</p>
                                <p style="font-size: 17px; font-weight: bold" v-if="adress">{{ adress }} {{ postkod }}
                                    {{ stad }} &nbsp;</p>
                                <p style="font-size: 17px; font-weight: bold" v-if="adress === '' || adress === null">
                                    Tryck för att ändra</p>
                            </div>
                            <div>
                                <p v-if="arrow_add === true" style="margin-left: 10px; color: #080056; float: right">
                                    <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                        east
                                    </v-icon>
                                </p>
                            </div>
                        </button>
                        <button class="button" @click="showTider()" style="margin-bottom: 20px">
                            <div style="float: left; display: inline-block">
                                <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">
                                    Tider</p>
                                <p style="font-size: 17px; font-weight: bold"
                                   v-if="checkedDays.length===0 && checktider === 'Aterkommande'">Tryck för att
                                    ändra</p>
                                <p style="font-size: 17px; font-weight: bold"
                                   v-if="range.start !=null && range.end !=null && checktider !== 'Aterkommande'">
                                    från {{range.start.toString().substr(0, 21)}} till {{range.end.toString().substr(0,
                                    21)}}</p>
                                <p style="font-size: 17px; font-weight: bold"
                                   v-if="checkedDays.length>0 && checktider === 'Aterkommande'">
                                    <span v-for="day in daysName" v-bind:key="day">{{day}}, </span>
                                    <span>{{hourstartstring}} - {{hourendstring}}</span>
                                </p>
                            </div>
                            <div>
                                <p v-if="arrow_tid === true" style="margin-left: 10px; color: #080056; float: right">
                                    <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                        east
                                    </v-icon>
                                </p>
                            </div>
                        </button>
                        <button class="button" @click="showInfo()" style="margin-bottom: 20px">
                            <div style="float: left">
                                <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">
                                    Information</p>
                                <p style="display: inline-block; font-size: 17px; font-weight: bold">{{ typechosen }},
                                    {{specchosen }},</p>
                                <p style="display: inline-block; font-size: 17px; font-weight: bold" v-if="checkRoof">
                                    Under tak,</p>
                                <p style="display: inline-block; font-size: 17px; font-weight: bold"
                                   v-if="checkCharging">
                                    Laddningstation,
                                </p>
                                <p style="display: inline-block; font-size: 17px; font-weight: bold"
                                   v-if="checkHandicap">
                                    Handikappsanpassad
                                </p>
                            </div>
                            <div>
                                <p v-if="arrow_inf === true" style="margin-left: 10px; color: #080056; float: right">
                                    <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                        east
                                    </v-icon>
                                </p>
                            </div>
                        </button>
                        <button class="button" @click="showBild()" style="margin-bottom: 20px">
                            <div style="float: left">
                                <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Bild</p>
                                <p style="font-size: 17px; font-weight: bold;">Tryck för att ändra</p>
                            </div>
                            <div>
                                <p v-if="arrow_bil === true"
                                   style="margin-left: 10px; color: #080056; float: right;">
                                    <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                        east
                                    </v-icon>
                                </p>
                            </div>

                        </button>
                        <button class="button" @click="showPris()" style="margin-bottom: 49px">
                            <div style="float: left">
                                <p style="color: black; font-size: 30px; font-weight: bold; margin-bottom: 3px">Pris</p>
                                <p style="font-size: 17px; font-weight: bold" v-if="price">{{price}} kr</p>
                                <p style="font-size: 17px; font-weight: bold" v-else>Tryck för att ändra</p>

                            </div>
                            <div>
                                <p v-if="arrow_pri === true"
                                   style="margin-left: 10px; color: #080056; float: right;">
                                    <v-icon style="margin-top: 20px; color: #0E046E; font-size: 32px; font-weight: bold; margin-right: 19px">
                                        east
                                    </v-icon>
                                </p>
                            </div>
                        </button>
                        <v-btn class="slutfor"
                               style="margin-bottom: 55px; text-transform: none; margin-top: 20px; margin-left: 11%; width: 475px; height: 73px; background: #0E046E; margin-right: auto; font-weight: bold; color: white; font-size: 38px; border-radius: 50px; box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);"
                               :disabled="isDisabled" :loading="loading" @click="addNewParkingSpot">
                            Slutför
                        </v-btn>
                    </div>
                </v-col>

                <!-- Right body content -->
                <v-col id="test" class="right-col" style="margin-left: 39px">
                    <!-- Adress -->
                    <div id="A"
                         style="float: left; width: 70vh; overflow-y: scroll; overflow-x: -moz-hidden-unscrollable">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Adress</p>
                        <div style="display: grid; width: 100%; margin-bottom: 38px">
                            <p style="float: left; color: black; font-weight: bold; font-size: 19px; margin-bottom: 18px">
                                Ange adress:</p>
                            <input placeholder="Adress" class="input" v-model="adress">
                            <input placeholder="Postkod" class="input" v-model="postkod">
                            <input placeholder="Stad" class="input" v-model="stad" style="text-transform: capitalize;">
                        </div>
                        <div style="font-size: 20px; width: 100%; margin-bottom: 39px">
                            <p style="color: black; font-size: 20px"><i class="fas fa-user-circle"
                                                                        style="color: red; margin-right: 3px"></i>
                                <strong>Ligger din parkering på din hemadress</strong> eller är <br>parkeringsadressen
                                känslig av annan anledning?
                            </p>
                        </div>
                        <div>
                            <div>
                                <div class="Ja_nej" style="margin-bottom: 43px; width: 70vh">
                                    <div style="font-size: 30px; margin-bottom: 20px">
                                        <input id="JA" name="JA_NEJ" type="radio" class="with-font" v-model="ja_nej"
                                               v-bind:value="'ja'" @click="show_and_scroll_Ja_Nej()"/>

                                        <label style="width: 100%; font-size: 40px" for="JA">Ja</label>
                                    </div>
                                    <div style="font-size: 30px;">
                                        <input style="width: 100%" id="NEJ" name="JA_NEJ" type="radio" class="with-font"
                                               v-model="ja_nej"
                                               v-bind:value="'nej'"/>
                                        <label style="width: 100%; font-size: 40px" for="NEJ">Nej</label>
                                    </div>
                                </div>
                                <div v-if="ja_nej === 'ja'" style="font-size: 30px;  width: 70vh; margin-bottom: 60px">
                                    <input style="width: 100%" id="anonym" name="anonym" type="checkbox"
                                           class="with-font"
                                           v-model="anonym" v-bind:value="'anonym'"/>
                                    <label style="width: 100%; font-size: 40px; color: black; margin-bottom: -25px; margin-top: -15px"
                                           for="anonym">Vill du vara anonym?</label>
                                    <p style="color:black; font-size: 16px; margin-top: 1px">Din profilbild och förnamn
                                        kommer vara dolt
                                        för <br>hyraren.</p>
                                </div>
                                <div>
                                    <button
                                            class="next"
                                            @click="showTider"
                                    >
                                        Nästa
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- Tider -->
                    <div id="B"
                         style="display: none; float: left; width: 70vh; overflow-y: scroll; overflow-x: hidden">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Tider</p>
                        <p style="color: black; font-size: 23px">Uthyrningstyp:</p>
                        <div class="Type" style=" width: 70vh">
                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input id="Specifika" name="Type" type="radio" class="with-font" @click="check()"
                                       value="Specifika"/>
                                <label style="width: 100%; font-size: 45px" for="Specifika">Specifika tider</label>
                            </div>

                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input style="width: 100%" id="Aterkommande" name="Type" type="radio"
                                       class="with-font"
                                       @click="check()" value="Aterkommande"/>
                                <label style="width: 100%; font-size: 45px" for="Aterkommande">Återkommande</label>
                            </div>

                            <div style="font-size: 30px; display: block; width: 70vh">
                                <input style="width: 100%" id="Langtidsuthyrning" name="Type" type="radio"
                                       class="with-font"
                                       @click="check()" value="Langtidsuthyrning"/>
                                <label style="width: 100%; font-size: 45px"
                                       for="Langtidsuthyrning">Långtidsuthyrning</label>
                            </div>
                        </div>
                        <div v-if="checktider==='Specifika'" style="margin-top: 34px">
                            <p style="font-size: 20px; color: black; margin-left: 12px">Tider för uthyrning :</p>
                            <p role="button" @click="show_and_scroll_specifica()"
                               style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                        class="material-icons-two-tone">calendar_today
                                </v-icon>
                                Ändra datum och tid
                            </p>
                            <div v-show="show_specifika === true" style="height: 420px;">
                                <a-range-picker :open="show_specifika_calendar" size="large"
                                                style="width: 60vh; height: 91px; font-size: 15px"
                                                @change="onChangeSpecif"
                                                :show-time='{format: "hh:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                @ok="on_ok"
                                                :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: auto; margin-bottom: 30px; float: left; display: block;  width: 70vh; margin-left: 12px">
                                <p style="margin-bottom: 0; margin-top: 5px; color: #C1C1C1; font-size: 21px">Hyr ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px"
                                   v-if="range.start !=null">
                                    {{range.start.locale('sv').format('DD')}}
                                    {{range.start.locale('sv').format('MMM')}}, {{range.start.format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>

                            <div v-show="show_specifika === false"
                                 style="height: auto; float: left; display: block; width: 70vh; margin-left: 12px; margin-bottom: 25px">
                                <p style="margin-bottom: 0; color: #C1C1C1; font-size: 21px">Hyr ut till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{range.end.locale('sv').format('DD')}} {{range.end.locale('sv').format('MMM')}},
                                    {{range.end.format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                                <br><br><br>
                            </div>
                        </div>
                        <div v-if="checktider==='Aterkommande'" style="margin-top: 34px">
                            <div style="height: auto">
                                <p style="font-size: 20px; color: black; margin-left: 12px;">Tider för uthyrning :</p>
                                <p role="button" @click="show_and_scroll_ater"
                                   style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                    <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                            class="material-icons-two-tone">calendar_today
                                    </v-icon>
                                    Ändra tider
                                </p>

                                <div id="time" style="display: flex; margin-top: 35px">
                                    <div v-show="show_tider === false"
                                         style="height: 50px; margin-bottom: 30px;  width: fit-content; margin-left: 12px">
                                        <p style="margin-bottom: 0; padding-top: 3px; font-size: 21px; color: #C1C1C1">
                                            Hyr ut från:</p>
                                        <p style="color: black; font-size: 50px; margin-bottom: 25px"> {{
                                            hourstartstring }}</p>
                                    </div>

                                    <div v-show="show_tider === false"
                                         style="height: 50px; width: fit-content; margin-left: 12px; margin-left: 72px">
                                        <p style="margin-bottom: 0; padding-top: 3px; font-size: 21px; color: #C1C1C1">
                                            Hyr ut till:</p>
                                        <p style="color: black; font-size: 50px"> {{ hourendstring }}</p>
                                    </div>
                                </div>

                                <b-row v-if="show_tider === true"
                                       style="height: auto; width: 100%; position: relative; margin-bottom: 20px">
                                    <b-col md="auto">
                                        {{hourend}}
                                        <a-time-picker :open="open" @openChange="handleOpenChange"
                                                       size="large" format="HH:mm"
                                                       @change="onChangeTimeStart"
                                                       v-model="hourstart">
                                            <a-button slot="addon" size="small" type="primary"
                                                      @click="handleClose">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                    <b-col md="auto" style="margin-left: 40px">
                                        <a-time-picker :open.sync="open2" format="HH:mm" size="large"
                                                       @change="onChangeTimeEnd"
                                                       v-model="hourend">
                                            <a-button slot="addon" size="small" type="primary" @click="handleClose2">
                                                Ok
                                            </a-button>
                                        </a-time-picker>
                                    </b-col>
                                </b-row>
                            </div>
                            <div style="width: 70vh; display: inline-block; overflow-x: auto; margin-top: 25px; margin-left: 12px">
                                <p style="color: #C1C1C1; font-size: 21px; margin-bottom: 40px">Dagar för uthyrning:</p>
                                <div :class="monday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Monday" value="Monday"
                                           @click="monday = !monday"
                                           v-model="checkedDays">
                                    <label :class="monday===true ? 'white' : 'blue'" for="Monday">Måndag</label>
                                </div>
                                <div :class="tuesday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Tuesday" value="Tuesday"
                                           @click="tuesday = !tuesday"
                                           v-model="checkedDays">
                                    <label :class="tuesday===true ? 'white' : 'blue'" for="Tuesday">Tisdag</label>
                                </div>
                                <div :class="wednesday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Wednesday" value="Wednesday"
                                           @click="wednesday = !wednesday" v-model="checkedDays">
                                    <label :class="wednesday===true ? 'white' : 'blue'"
                                           for="Wednesday">Onsdag</label>
                                </div>
                                <div :class="thursday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Thursday" value="Thursday"
                                           @click="thursday = !thursday"
                                           v-model="checkedDays">
                                    <label :class="thursday===true ? 'white' : 'blue'" for="Thursday">Torsdag</label>
                                </div>
                                <div :class="friday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Friday" value="Friday"
                                           @click="friday = !friday"
                                           v-model="checkedDays">
                                    <label :class="friday===true ? 'white' : 'blue'" for="Friday">Fredag</label>
                                </div>
                                <div :class="saturday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Saturday" value="Saturday"
                                           @click="saturday = !saturday"
                                           v-model="checkedDays">
                                    <label :class="saturday===true ? 'white' : 'blue'" for="Saturday">Lördag</label>
                                </div>
                                <div :class="sunday ? 'dark-theme' : 'light-theme'"
                                     style="line-height: 40px ;border-radius: 50px; border: #080056 solid 1px; width: fit-content; padding-left: 5px; padding-right: 5px; display: inline-block">
                                    <input class="with-day" type="checkbox" id="Sunday" value="Sunday"
                                           @click="sunday = !sunday"
                                           v-model="checkedDays">
                                    <label :class="sunday===true ? 'white' : 'blue'" for="Sunday">Söndag</label>
                                </div>
                                <br><br><br>
                            </div>
                        </div>
                        <div v-if="checktider==='Langtidsuthyrning'" style="margin-top: 34px">
                            <p style="font-size: 20px; color: black; margin-left: 12px">Tider för uthyrning :</p>
                            <p role="button" @click="show_and_scroll_lang"
                               style="font-weight: bold; font-size: 35px; color: #100770; margin-left: 12px">
                                <v-icon style="color: #0E046E; font-size: 35px; margin-top: -7px"
                                        class="material-icons-two-tone">calendar_today
                                </v-icon>
                                Ändra
                                datum och tid
                            </p>
                            <div v-show="show_lang === true"
                                 style="height: 420px">
                                <br>
                                <a-range-picker :open="show_lang_calendar" size="large"
                                                @change="onChangeReoccuring"
                                                :show-time='{format: "hh:mm"}'
                                                format="YYYY-MM-DD HH:mm"
                                                style="width: 60vh; height: 100px; font-size: 20px"
                                                @ok="on_ok_lang"
                                                :default-value="[moment(actual_date, 'YYYY-MM-DD'), moment(after_date, 'YYYY-MM-DD')]"/>
                            </div>

                            <div v-show="show_lang === false"
                                 style="height: auto; margin-bottom: 30px; float: left; display: block;  width: 70vh; margin-left: 12px">
                                <p style="margin-bottom: 0; margin-top: 5px; color: #C1C1C1; font-size: 21px">Hyr ut
                                    från:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{range.start.locale('sv').format('DD')}}
                                    {{range.start.locale('sv').format('MMM')}}, {{range.start.format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.start.locale('sv').format('dddd').toString()[0].toUpperCase() + range.start.locale('sv').format('dddd').toString().slice(1)}} </span>
                            </div>

                            <div v-show="show_lang === false"
                                 style="height: auto; margin-bottom: 30px; float: left; display: block;  width: 70vh; margin-left: 12px">
                                <p style="margin-bottom: 0; color: #C1C1C1; font-size: 21px">Hyr ut till:</p>
                                <p style="color: black; font-size: 45px; margin-bottom: -15px" v-if="range.end !=null">
                                    {{range.end.locale('sv').format('DD')}} {{range.end.locale('sv').format('MMM')}},
                                    {{range.end.format("hh:mm")}} </p>
                                <span style="color: #0E046E; font-size: 27px">{{range.end.locale('sv').format('dddd').toString()[0].toUpperCase() + range.end.locale('sv').format('dddd').toString().slice(1)}} </span>
                                <br><br><br>
                            </div>
                        </div>
                        <button
                                class="next"
                                @click="showInfo"
                        >
                            Nästa
                        </button>

                    </div>

                    <!-- Info -->
                    <div id="C" style="display: none; float: left; width: 90vh; overflow-y: scroll">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Information</p>
                        <div style="margin-bottom: 42px">
                            <p style="font-size: 24px; color: black">Parkeringtyp:</p>
                            <div style="align-items: center; text-align: center; height: 74px; width: 80vh">
                                <v-card style="width: 66.5vh; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;  margin-right: auto; margin-left: 2px; margin-top: 10px">
                                    <v-tabs :currentTab="parkingtype" fixed-tabs card v-model="parkingtype">
                                        <v-tab title="Tab 1" style="width: 33%" key="first">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Parkering
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 2" style="width: 33%" key="second">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Uppfart
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 2" style="width: 33%" key="third">
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Garage
                                            </v-card-text>
                                        </v-tab>
                                    </v-tabs>
                                </v-card>
                            </div>
                        </div>
                        <div style="margin-bottom: 42px">
                            <p style="font-size: 24px; color: black">Storlek:</p>
                            <div style="align-items: center; text-align: center; height: 74px; width: 80vh">
                                <v-card style="width: 66.5vh; box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px; margin-right: auto; margin-left: 2px; margin-top: 10px"
                                        no-body>
                                    <v-tabs card v-model="storlek">
                                        <v-tab title="Tab 1" style="width: 50%;" key='first'>
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Standard (2x5)
                                            </v-card-text>
                                        </v-tab>
                                        <v-tab title="Tab 2" style="width: 50%;" key='second'>
                                            <v-card-text
                                                    style="font-weight: bold; text-transform: none; font-size: 21px">
                                                Handikapp (3x5)
                                            </v-card-text>
                                        </v-tab>
                                    </v-tabs>
                                </v-card>
                            </div>
                        </div>
                        <div>
                            <div class="Specifikationer" style="width: 70vh">
                                <p style="color: black; font-size: 23px; margin-bottom: 26px">Specifikationer:</p>
                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input id="Laddningstation" name="Specifikationer" type="checkbox"
                                           class="with-font"
                                           @click="specifikation()" value="Laddningstation"/>
                                    <label style="width: 100%; font-size: 35px"
                                           for="Laddningstation">Laddningstation</label>
                                </div>

                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input style="width: 100%" id="under_tak" name="Specifikationer"
                                           type="checkbox"
                                           class="with-font"
                                           @click="specifikation()" value="Under tak"/>
                                    <label style="width: 100%; font-size: 35px" for="under_tak">Under tak</label>
                                </div>

                                <div style="font-size: 30px; display: block; width: 70vh">
                                    <input style="width: 100%" id="Handikappsanpassad" name="Specifikationer"
                                           type="checkbox"
                                           class="with-font" @click="specifikation()" value="Handikappsanpassad"/>
                                    <label style="width: 100%; font-size: 35px" for="Handikappsanpassad">Handikappsanpassad</label>
                                </div>
                            </div>
                            <div style="margin-bottom: 30px">
                                <p style="font-size: 23px; margin-top: 3px; color: black; margin-bottom: 41px">Övriga
                                    detaljer:</p>
                                <textarea
                                        style="height: 150px; font-size: 20px; text-align: left; margin-bottom: 55px;  color: black"
                                        placeholder="Ange övriga specifikationer och eventuella tips som kan hjälpa för att lättare hitta din parkering…"
                                        v-model="description"
                                        class="input"
                                ></textarea>
                            </div>
                        </div>
                        <button
                                class="next"
                                @click="showBild"
                        >
                            Nästa
                        </button>
                    </div>

                    <!-- Bild -->
                    <div id="D" style="display: none; float: left; width: 90vh; overflow-y: scroll">
                        <p style="font-size: 45px; color: black; margin-bottom: 33px">Bild</p>
                        <p style="font-size: 23px; color: black; margin-top: 33px">Omslagsbild:</p>
                        <div style="margin-bottom: 50px">
                            <input id="file" type="file" style="display: none;" @change="onFileChange"
                                   accept="image/*"/>
                            <div id="preview" style="width: 531px; height: 580px; overflow-y: hidden">
                                <v-img v-if="url" style="width: 531px; height: 531px; border-radius: 11px; z-index: 1"
                                       v-bind:src="url"
                                       alt=""/>
                                <label for="file" class="view-picture"
                                       style="margin-left: auto; margin-right: auto ; color: #080056; font-weight: bold"
                                       role="button">Lägg till bild</label>
                            </div>
                        </div>
                        <div>
                            <button
                                    class="next"
                                    @click="showPris"
                            >
                                Nästa
                            </button>
                        </div>

                    </div>

                    <!-- Pris -->
                    <div id="E"
                         style="display: none; float: left; width: 70vh; overflow-y: scroll; height: 80vh; overflow-x: hidden;">
                        <p style="color: black; font-size: 45px; margin-bottom: 33px">Pris</p>
                        <div style="border-bottom: solid 2px #D7D7D7; padding-bottom: 27px;">
                            <div style="width: 100%; padding-bottom: 156px">
                                <p style="color: black; font-size: 23px">Timpris:</p>
                                <input class="input" type="number" min="0" :max=price_zone
                                       style="font-size: 100px; background-color: white; color: black; height: 130px"
                                       placeholder="Ange pris" v-model="price">
                            </div>

                            <div id="price" style="width: 70vh; display: flex; margin-top: 36px">
                                <div>
                                    <p style="color: #C3C3C3; font-size: 24px">Dagspris:</p>
                                    <p style="color: #E3E3E3; font-size: 69px">{{ price_day }}kr</p>
                                </div>
                                <div style="margin-left: 178px">
                                    <p style="font-size: 24px; color: #C3C3C3">Veckopris:</p>
                                    <p style="color: #E3E3E3; font-size: 69px"> {{ price_week }}kr</p>
                                </div>
                            </div>

                        </div>
                        <div style="margin-top: 20px">
                            <div>
                                <p style="margin-bottom: 17px; color: black; margin-top: 40px; font-size: 24px">
                                    Priszon:</p>
                                <p style="color: black; font-size: 47px; text-decoration: underline">{{
                                    stad }} <i style="text-decoration: none" v-if="stad" class="fas fa-map-pin"></i></p>
                            </div>
                            <div style="margin-top: 30px">
                                <p style="margin-bottom: 16px; color: black; font-size: 24px">Maxpris:</p>
                                <p style="font-size: 52px; color: black; margin-bottom: 18px" v-if="price_zone>0">{{
                                    price_zone }}kr</p>
                                <p v-else style="font-size: 52px; color: black; margin-bottom: 18px">&nbsp;</p>
                                <p style="color: black; margin-bottom: 16px; font-size: 24px">Uppskattat område:</p>
                                <p v-if="price > price_zone" style="color: black; font-size: 47px; margin-bottom: 45px">
                                    Inget
                                    resultat</p>
                                <p v-if="price <= price_zone && price > yellow_price"
                                   style="color: black; font-size: 47px; margin-bottom: 45px">
                                    I centrum</p>
                                <p v-if="yellow_price >= price && price > green_price"
                                   style="color: black; font-size: 47px; margin-bottom: 45px">Centralt</p>
                                <p v-if="green_price >= price && price > blue_price"
                                   style="color: black; font-size: 47px; margin-bottom: 45px">Nära stan</p>
                                <p v-if="price <= blue_price && price > 0"
                                   style="color: black; font-size: 47px; margin-bottom: 45px">Landsbygd</p>
                                <p v-if="price === null || price <= 0 "
                                   style="color: black; font-size: 47px; margin-bottom: 45px">Inget
                                    resultat</p>
                            </div>
                        </div>
                    </div>
                </v-col>
            </v-row>
        </div>
    </div>
</template>

<script>
    import Header from "./Header";
    import firebase from "firebase";
    import ParkingSpotApi from "../services/ParkingSpotApi";
    import moment from 'moment';
    import 'moment/locale/sv'

    export default {
        data() {
            return {
                daysName: [],
                range: {
                    start: this.getDate,
                    end: null,
                },
                dialog: false,
                show_tider: false,
                show_specifika: false,
                show_lang: false,
                checkedDays: [],
                hourstart: this.date_of_today,
                hourend: null,
                date: new Date(),
                parkingtype: 1,
                storlek: 0,
                ja_nej: null,
                anonym: false,
                adressbool: false,
                tiderbool: false,
                infobool: false,
                bildbool: false,
                prisbool: false,
                checktider: null,
                checkinfo: null,
                url: require('../assets/empty_photo.png'),
                adress: null,
                postkod: null,
                stad: null,
                price: null,
                price_day: '0',
                price_week: '0',
                dragValue: null,
                typechosen: null,
                specchosen: null,
                description: null,
                date_of_today: new Date(),
                imageData: null,
                minDate: new Date(2021, 10, 4),
                price_zone: null,
                dayMultiplier: 5,
                weekMultiplier: 15,
                monday: false,
                tuesday: false,
                wednesday: false,
                thursday: false,
                friday: false,
                saturday: false,
                sunday: false,
                arrow_add: true,
                arrow_tid: false,
                arrow_inf: false,
                arrow_bil: false,
                arrow_pri: false,
                green_price: null,
                yellow_price: null,
                blue_price: null,
                loader: null,
                loading: false,
                value2: moment(),
                actual_date: null,
                after_date: null,
                hourstartstring: '',
                hourendstring: '',
                show_specifika_calendar: false,
                show_tider_time: false,
                show_lang_calendar: false,
                open: false,
                open2: false,
                checkCharging: '',
                checkRoof: '',
                checkHandicap: '',
                count: 0,
            }
        },
        beforeCreate() {
            document.title = "Ny parkeringsplats";
        },
        watch: {
            loader() {
                const l = this.loader
                this[l] = !this[l]
                setTimeout(() => (this[l] = false), 6000)
                this.loader = null
            },
            ja(value) {
                this.ja = value;
            },
            price: function (value) {
                this.price_day = value * this.dayMultiplier;
                this.price_week = value * this.weekMultiplier
            },
            parkingtype: function (value) {
                if (value === 0) {
                    this.typechosen = 'Parkering'
                } else if (value === 1) {
                    this.typechosen = 'Uppfart'
                } else if (value === 2) {
                    this.typechosen = 'Garage'
                }

            },
            storlek: function (value) {
                if (value === 0) {
                    this.specchosen = 'Standard (2x5)'
                } else if (value === 1) {
                    this.specchosen = 'Handikapp (3x5)'
                }
            },
            stad(value) {
                value = value[0].toUpperCase() + value.slice(1);
                this.stad = value;
                let vm = this;
                window.db.collection('pricezones').doc(value).onSnapshot((querySnapshot) => {
                        if (querySnapshot.exists) {
                            vm.price_zone = querySnapshot.data()['red'];
                            vm.blue_price = querySnapshot.data()['blue'];
                            vm.green_price = querySnapshot.data()['green'];
                            vm.yellow_price = querySnapshot.data()['yellow'];
                            vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                            vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                            vm.price_day = vm.price * vm.dayMultiplier;
                            vm.price_week = vm.price * vm.weekMultiplier
                        } else {
                            window.db.collection('pricezones').doc('standardZones').onSnapshot((querySnapshot) => {
                                vm.price_zone = querySnapshot.data()['red'];
                                vm.blue_price = querySnapshot.data()['blue'];
                                vm.green_price = querySnapshot.data()['green'];
                                vm.yellow_price = querySnapshot.data()['yellow'];
                                vm.dayMultiplier = querySnapshot.data()['dayMultiplier'];
                                vm.weekMultiplier = querySnapshot.data()['weekMultiplier'];
                                vm.price_day = vm.price * vm.dayMultiplier;
                                vm.price_week = vm.price * vm.weekMultiplier
                            })
                        }
                    }
                );
            },
            checkedDays() {
                this.days()
                let monday_exists = false
                let tuesday_exists = false
                let wednesday_exists = false
                let thursday_exists = false
                let friday_exists = false
                let saturday_exists = false
                let sunday_exists = false
                this.daysName.forEach(el => {
                    switch (el) {
                        case 'Mån':
                            this.checkedDays.forEach(element => {
                                if (element === 'Monday') {
                                    monday_exists = true
                                }
                            })
                            break;
                        case 'Tis':
                            this.checkedDays.forEach(element => {
                                if (element === 'Tuesday') {
                                    tuesday_exists = true
                                }
                            })
                            break;
                        case 'Ons':
                            this.checkedDays.forEach(element => {
                                if (element === 'Wednesday') {
                                    wednesday_exists = true
                                }
                            })
                            break;
                        case 'Tor':
                            this.checkedDays.forEach(element => {
                                if (element === 'Thursday') {
                                    thursday_exists = true
                                }
                            })
                            break;
                        case 'Fer':
                            this.checkedDays.forEach(element => {
                                if (element === 'Friday') {
                                    friday_exists = true
                                }
                            })
                            break;
                        case 'Lör':
                            this.checkedDays.forEach(element => {
                                if (element === 'Saturday') {
                                    saturday_exists = true
                                }
                            })
                            break;
                        case 'Sön':
                            this.checkedDays.forEach(element => {
                                if (element === 'Sunday') {
                                    sunday_exists = true
                                }
                            })
                            break;
                    }
                })
                if (monday_exists === false) {
                    let index = this.daysName.indexOf("Mån");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (tuesday_exists === false) {
                    let index = this.daysName.indexOf("Tis");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (wednesday_exists === false) {
                    let index = this.daysName.indexOf("Ons");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (thursday_exists === false) {
                    let index = this.daysName.indexOf("Tor");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (friday_exists === false) {
                    let index = this.daysName.indexOf("Fer");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (saturday_exists === false) {
                    let index = this.daysName.indexOf("Lör");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
                if (sunday_exists === false) {
                    let index = this.daysName.indexOf("Sön");
                    if (index !== -1) {
                        this.daysName.splice(index, 1);
                    }
                }
            }
        },
        components: {
            Header,
        },
        props: ['user'],
        mounted() {
            var date = new Date();
            const current = new Date(date.setHours(19));
            this.date_of_today = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;
            this.actual_date = `${current.getFullYear()}-${current.getMonth() + 1}-${current.getDate()}`;
            let test = new Date(new Date().setDate(new Date().getDate() + 7));
            let month = test.getMonth() + 1;
            this.after_date = test.getFullYear() + '-' + month + '-' + test.getDate();
            this.hourstart = moment();
            this.hourend = moment().add(5, 'hours').add(30, 'day');
            this.range.start = moment(this.actual_date, 'YYYY-MM-DD');
            this.range.end = moment(this.after_date, 'YYYY-MM-DD')
            this.hourstartstring = this.hourstart.format("HH:mm").toString()
            this.hourendstring = this.hourend.format("HH:mm").toString()
            this.parkingtype = 2;
            this.storlek = 1;
        },
        methods: {
            activate() {
                this.$fire({
                    title: "Framgång",
                    type: "success",
                    text: "Din parkeringsplats har skapats",
                    customClass: {
                        confirmButton: 'Okbutton',
                    }
                })
            },
            handleOpenChange(open) {
                this.open = open;
            },
            handleClose() {
                this.open = false;
                this.open2 = true;
            },
            handleClose2() {
                this.show_tider = false;
                this.open = false;
                this.open2 = false;
            },
            on_ok() {
                this.show_specifika_calendar = false;
                this.show_specifika = false
            },
            on_ok_lang() {
                this.show_lang_calendar = false
                this.show_lang = false
            },
            onChangeSpecif(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeReoccuring(date, dateString) {
                console.log(date, dateString);
                this.range.start = date[0];
                this.range.end = date[1];
            },
            onChangeTimeStart(time, timeString) {
                this.hourstart = time;
                this.hourstartstring = timeString
            },
            onChangeTimeEnd(time, timeString) {
                console.log(time, timeString);
                this.hourend = time;
                this.hourendstring = timeString;
                this.show_tider_time = false
            },
            moment,
            show_and_scroll_specifica() {
                this.show_specifika = !this.show_specifika;
                setTimeout(this.scrollToEnd, 100)
            },
            show_and_scroll_ater() {
                this.show_tider = !this.show_tider;
                this.open = this.show_tider
                setTimeout(this.scrollToEnd, 100)
                this.show_tider_time = this.show_tider
            },
            show_and_scroll_lang() {
                this.show_lang = !this.show_lang;
                setTimeout(this.scrollToEnd, 100)
            },
            scrollToEnd: function () {
                var objDiv = document.getElementById("test");
                objDiv.scrollTop = objDiv.scrollHeight;
                this.show_specifika_calendar = this.show_specifika;
                this.show_lang_calendar = this.show_lang;
            },
            show_and_scroll_Ja_Nej() {
                setTimeout(this.scrollToEnd, 100)
            },
            onFileChange(e) {
                const file = e.target.files[0];
                this.imageData = e.target.files[0]
                this.url = URL.createObjectURL(file);
            },
            signout() {
                firebase.auth().signOut();
                this.$store.dispatch('setToken', null)
                this.$store.dispatch('setUser', null)
                this.$router.push({path: '/'})
                window.location.reload(); //reload on signout !
            },
            showAdress() {
                this.arrow_add = true;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (a.style.display === "none") {
                    a.style.display = "block";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showTider() {
                this.arrow_add = false;
                this.arrow_tid = true;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (b.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "block";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showInfo() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = true;
                this.arrow_bil = false;
                this.arrow_pri = false;
                if (this.count === 0) {
                    this.parkingtype = 0;
                    this.storlek = 0;
                    this.count += 1
                }
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (c.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "block";
                    d.style.display = "none";
                    e.style.display = "none";
                }
            },
            showBild() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = true;
                this.arrow_pri = false;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (d.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "block";
                    e.style.display = "none";
                }
            },
            showPris() {
                this.arrow_add = false;
                this.arrow_tid = false;
                this.arrow_inf = false;
                this.arrow_bil = false;
                this.arrow_pri = true;
                var a = document.getElementById("A");
                var b = document.getElementById("B");
                var c = document.getElementById("C");
                var d = document.getElementById("D");
                var e = document.getElementById("E");
                if (e.style.display === "none") {
                    a.style.display = "none";
                    b.style.display = "none";
                    c.style.display = "none";
                    d.style.display = "none";
                    e.style.display = "block";
                }
            },
            check() {
                let select = document.querySelector('input[name="Type"]:checked').value;
                this.show_specifika = false;
                this.show_lang = false;
                this.show_tider = false;
                this.show_tider_time = this.show_tider;
                this.show_lang_calendar = this.show_lang;
                this.show_specifika_calendar = this.show_specifika;
                this.checktider = select
                return select
            },
            specifikation() {
                if (document.querySelector('input[name="specifikationerCharging"]:checked')) {
                    this.checkCharging = document.querySelector('input[name="specifikationerCharging"]:checked').value
                } else {
                    this.checkCharging = null
                }
                if (document.querySelector('input[name="specifikationerUnderTak"]:checked')) {
                    this.checkRoof = document.querySelector('input[name="specifikationerUnderTak"]:checked').value
                } else {
                    this.checkRoof = null
                }
                if (document.querySelector('input[name="specifikationerHandikap"]:checked')) {
                    this.checkHandicap = document.querySelector('input[name="specifikationerHandikap"]:checked')
                } else {
                    this.checkHandicap = null
                }
            },
            async addNewParkingSpot() {
                this.loader = 'loading';
                this.isDisabled = true;
                try {
                    await ParkingSpotApi.addParkingSpot({
                        'address': this.adress,
                        'postcode': this.postkod,
                        'stad': this.stad,
                        'ja_nej': this.ja_nej,
                        'anonym': this.anonym,
                        'range': this.range,
                        'hourstart': this.hourstart,
                        'hoursend': this.hourend,
                        'checkedDays': this.checkedDays,
                        'parkingType': this.typechosen,
                        'tiderType': this.checktider,
                        'checkinfo': this.checkinfo,
                        'description': this.description,
                        'img': this.url,
                        'price': this.price,
                        "userId": firebase.auth().currentUser.uid,
                        "price_day": this.price_day,
                        "price_week": this.price_week,
                        "storlek": this.specchosen,
                        "checkHandicap": this.checkHandicap,
                        "checkRoof": this.checkRoof,
                        "checkCharging": this.checkCharging,
                        "createdAt": new Date()
                    }).then(async (res) => {
                        console.log("ADD")
                        if (res.status === 200) {
                            console.log("SUCCESS")
                            await firebase.storage().ref(`/lotImages/${res.data}` + '.jpg').put(this.imageData).then(() => {
                                let vm = this;
                                vm.$fire({
                                    title: "Success",
                                    type: "success",
                                    customClass: {
                                        closeButton: 'Okbutton'
                                    }
                                }).then(() => {
                                    window.db.collection('stripe_connected_accounts').doc(firebase.auth().currentUser.uid).onSnapshot((querySnapshot) => {
                                        if (querySnapshot.exists) {
                                            vm.$router.push({name: 'ParkingSpots'})
                                        } else {
                                            vm.$router.push({name: 'ParkingSpotsConfirmation'})
                                        }
                                    })
                                });
                            });
                        } else {
                            this.$fire({
                                title: "Error",
                                type: "error",
                            })
                        }

                    })
                } catch (error) {
                    console.log(error)
                }
            },
            checkInput() {
                let returnvalue = true
                if (this.adress && this.postkod && this.stad && this.ja_nej) {
                    if ((this.range.start && this.range.end) || (this.hourstart && this.hourend && this.checkedDays)) {
                        if (this.parkingtype !== null && this.storlek !== null && this.checktider) {
                            if (this.url && this.price > 0) {
                                returnvalue = false
                            }
                        }
                    }
                }
                return returnvalue
            },
            days() {
                let monday_exists = false
                let tuesday_exists = false
                let wednesday_exists = false
                let thursday_exists = false
                let friday_exists = false
                let saturday_exists = false
                let sunday_exists = false
                this.checkedDays.forEach(el => {
                    switch (el) {
                        case 'Monday':
                            this.daysName.forEach(element => {
                                if (element === 'Mån') {
                                    monday_exists = true
                                }
                            })
                            if (monday_exists === false) {
                                this.daysName.push('Mån')
                            }
                            break;
                        case 'Tuesday':
                            this.daysName.forEach(element => {
                                if (element === 'Tis') {
                                    tuesday_exists = true
                                }
                            })
                            if (tuesday_exists === false) {
                                this.daysName.push('Tis')
                            }
                            break;
                        case 'Wednesday':
                            this.daysName.forEach(element => {
                                if (element === 'Ons') {
                                    wednesday_exists = true
                                }
                            })
                            if (wednesday_exists === false) {
                                this.daysName.push('Ons')
                            }
                            break;
                        case 'Thursday':
                            this.daysName.forEach(element => {
                                if (element === 'Tor') {
                                    thursday_exists = true
                                }
                            })
                            if (thursday_exists === false) {
                                this.daysName.push('Tor')
                            }
                            break;
                        case 'Friday':
                            this.daysName.forEach(element => {
                                if (element === 'Fre') {
                                    friday_exists = true
                                }
                            })
                            if (friday_exists === false) {
                                this.daysName.push('Fre')
                            }
                            break;
                        case 'Saturday':
                            this.daysName.forEach(element => {
                                if (element === 'Lör') {
                                    saturday_exists = true
                                }
                            });
                            if (saturday_exists === false) {
                                this.daysName.push('Lör')
                            }
                            break;
                        case 'Sunday':
                            this.daysName.forEach(element => {
                                if (element === 'Sön') {
                                    sunday_exists = true
                                }
                            })
                            if (sunday_exists === false) {
                                this.daysName.push('Sön')
                            }
                            break;
                    }
                })
            },
        },
        computed: {
            isDisabled() {
                return this.checkInput()
            }
            ,
            getMinDate() {
                const current = new Date();
                return new Date(current.getFullYear(), current.getMonth(), current.getDate());
            }
        }
    }
</script>

<style scoped lang="scss">
    @import url(//netdna.bootstrapcdn.com/font-awesome/4.2.0/css/font-awesome.css);

    .right-col {
        overflow-y: scroll;
        float: right;
        margin-right: 47px;
        height: 90vh;
        width: 70vh;
        margin-top: 20px;
        text-align: left;
        overflow-x: hidden;
    }

    .left-col {
        overflow-x: hidden;
        padding-right: 0;
        float: left;
        border-right: solid #0E046E 1px;
        margin-left: 45px;
        height: 90vh;
        width: 90vh;
    }

    input:focus {
        outline: none;
    }

    h4 {
        font-weight: bold;
    }

    p {
        margin-top: -8px;
        font-weight: bold;
        color: #B3B3B3;
    }

    .button {
        height: 84px;
        margin-top: 20px;
        float: left;
        text-align: left;
        border-bottom: solid 1px;
        border-bottom-color: #DFDFDF;
        width: 100%
    }

    .input {
        padding-left: 15px;
        float: left;
        border-radius: 7px;
        color: black;
        text-align: left;
        font-size: xx-large;
        background: #eeeeee;
        margin-bottom: 20px;
        width: 70vh;
        height: 91px;
        font-weight: bold;
    }


    .Ja_nej {
        border-bottom: solid 1px lightgray;
    }

    .Type {
        border-bottom: solid 1px lightgray;
    }

    /*** Radio Checkbox style ***/


    input[type=radio].with-font,
    input[type=checkbox].with-font {
        float: right;
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-font ~ label:before,
    input[type=checkbox].with-font ~ label:before {
        float: right;
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #BFBFBF;
        width: 1.4em;
    }

    input[type=radio].with-font:checked ~ label:before,
    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        font-size: 1.2em;
        color: black;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-font ~ label:before {
        float: right;
        content: "\f1db";
    }

    input[type=checkbox].with-font:checked ~ label:before {
        float: right;
        content: "\f058";
        color: black;
    }


    .slutfor {
        margin-left: auto;
        margin-right: auto;
        font-weight: bold;
        color: white;
        font-size: 38px;
        width: 100%;
        height: 73px;
        background: #0E046E;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    ::-webkit-scrollbar {
        display: none;
    }

    .test {
        font-weight: bold;
        font-size: 35px;
        color: #100770;
    }

    .dark-theme {
        overflow-y: hidden;
        width: auto;
        height: 44px;
        margin-right: 15px;
        background: #080056;
        color: white !important;
        border-color: #080056;
    }

    .light-theme {
        overflow-y: hidden;
        width: auto;
        height: 44px;
        margin-right: 15px;
        background: white;
        border-color: #080056;
        color: #080056;
    }


    input[type=radio].with-day,
    input[type=checkbox].with-day {
        border: 0;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    input[type=radio].with-day ~ label:before,
    input[type=checkbox].with-day ~ label:before {
        font-family: FontAwesome;
        display: inline-block;
        content: "\f1db";
        letter-spacing: 10px;
        font-size: 1.2em;
        color: #080056;
        width: 1.4em;
    }

    input[type=radio].with-day:checked ~ label:before,
    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        font-size: 1.2em;
        color: white;
        letter-spacing: 5px;
    }

    input[type=checkbox].with-day ~ label:before {
        content: "\f1db";
    }

    input[type=checkbox].with-day:checked ~ label:before {
        content: "\f058";
        color: white;
    }

    .view-picture {
        line-height: 58px;
        color: #0E046E;
        text-align: center;
        display: block;
        position: relative;
        z-index: 2;
        margin-top: -31.5px;
        margin-left: auto;
        margin-right: auto;
        width: 230px;
        height: 63px;
        background: white;
        box-shadow: 0px 4px 20px lightgray;
        border-radius: 50px;
        border: #080056 solid 1px;
        font-size: 25px;
        font-weight: bold;
    }

    label {
        color: #BFBFBF;
    }

    input[type=radio]:checked ~ label {
        color: black;
    }

    input[type=checkbox].with-font:checked ~ label {
        color: black;
    }

    .blue {
        color: #080056
    }

    .white {
        color: #FFFFFF;
    }

    .row {
        position: fixed;
        height: 100%;
    }

    .body {
        width: 100%;
        float: right;
    }

    .all {
        display: flex;
        -ms-overflow-style: none;
        overflow: hidden;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }

    input[type=number] {
        -moz-appearance: textfield; /* Firefox */
    }

    #custom-disabled.v-btn--disabled {
        background-color: #0E046E !important;
        color: white !important;
    }

    .theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn-outlined) {
        color: white !important;
        background: #b5b2d3 !important;
    }

    .next {
        margin-top: 25px;
        margin-left: 23%;
        text-transform: none;
        font-weight: bold;
        color: white;
        font-size: 25px;
        margin-bottom: 50px;
        position: center;
        width: 300px;
        height: 70px;
        background: #080056;
        border-radius: 50px;
        box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.2);
    }

    .Okbutton {
        background: #080056 !important;
    }

    Okbutton{
        background: #080056 !important;
    }
</style>