<template>
    <div style="padding-left: 7px; padding-right: 7px">
        <div>
            <h1 style="text-align: center; font-weight: bold">Användarvillkor:</h1>

            <p>
                Vid användande av FastPark applikation och mobila betaltjänst gäller nedanstående
                användarvillkor.
            </p>
            <ul style="list-style-type: disc">
                <li>1. Introduktion</li>
            </ul>
            <p>
                Dessa regler och villkor avser för din användning av applikationen och om du vill boka en
                parkeringsplats kommer du att vara bunden till våra Användaravtal. Om du äger en
                parkeringsplats som du vill hyra ut genom FastPark kommer du att vara bunden till vårt
                Upplåtaravtal. Observera att dessa regler och villkor kan ändras från en tidsperiod till en
                annan.
                Registrering i webbplatsen är för närvarande gratis, men för att bli en registrerad
                användare måste du ange några grundläggande personuppgifter.
                Vid godkännande av villkor samtycker du också till att vara bunden till våra villkor i
                ”Integritetspolicy” vilket förklarar vår användning av dina personuppgifter.
                Observera att dessa regler och villkor kan komma att ändras från tid till annan,
                Meddelanden om eventuella ändringar kommer att göras genom att publicera nya villkor
                på webbplatsen. Det åligger användaren av appen att uppdatera sig. FastPark har
                således inga skyldigheter att meddela när ändringar sker. När du sedan fortsätter att
                använda dig av FastPark bekräftar du också de aktuella villkoren i sin helhet vid den
                tidpunkt du använder applikationen.
                Upplåtaren ska vara medveten om att det kan finnas befintliga regler gällande dennes
                parkeringsplats som denne måste ta hänsyn till innan upplåtelse
                i FastParks app sker. FastPark tar inget ansvar för eventuella konsekvenser som
                upplåtaren drabbas av om denne hyr ut sin parkeringsplats i strid med de regler som
                parkeringsplatsen omfattas av.
                Dessa villkor styrs och tolkas i enlighet med svensk lagstiftning. Vid reklamationer kan
                parterna vända sig till Allmänna Reklamationsnämnden (ARN). Vid eventuell tvist ska
                den avgöras i allmän domstol.
                Om du vid något tillfälle vill kontakta oss med dina synpunkter om våra användarvillkor
                kan du göra det på <a href="mailto:Info@fastpark.se">Info@fastpark.se</a>
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>2. Beskrivning av förmedlingstjänsten</li>
            </ul>
            <p>
                FastPark-appen är en applikation för mobiltelefoner som ägs och har
                utvecklats av FastPark Sweden AB.
                Genom FastPark-appen kan den, som innehar en parkeringsplats och som inte avser att
                nyttja parkeringsplatsen under viss period, upplåta åt tredje man att mot ersättning nyttja
                parkeringsplatsen under hela eller en begränsad del av perioden. FastPark-appen ger
                således innehavaren av parkeringsplatsen både möjlighet att annonsera ut
                parkeringsplatsen och att, genom FastPark:s förmedling, ingå avtal med den som önskar
                nyttja parkeringsplatsen. För användandet av FastPark-appen betalar den som upplåtit
                sin parkeringsplats en förmedlingsprovision till FastPark Sweden AB. Avtal ingås direkt
                mellan upplåtaren och användaren. FastPark tar emot betalning för
                upplåtarens räkning. FastPark samarbetar med fastighetsbolag som kan få ta del av
                provisionen.
                Den som är i behov av tillfällig parkeringsplats kan genom FastPark-appen söka efter en
                geografiskt passande parkeringsplats att nyttja under viss tid, erhålla prisuppgift samt
                genom tjänsten boka och betala för det tillfälliga nyttjandet. För den som
                använder FastPark-appen för att söka och boka parkeringsplats är applikationen gratis
                att använda. Bokning av parkeringsplats sker i två steg. Första steget är att användaren
                reserverar parkeringsplats, varvid besked lämnas om vem som innehar
                parkeringsplatsen. Andra steget är att användaren bokar parkeringsplatsen, varvid
                användaren bekräftar att han accepterar villkoren för upplåtelsen samt erlägger
                betalning.
                Först efter att betalning erlagts får användaren besked exakt var parkeringsplatsen är
                belägen.
                För sitt nyttjande av parkeringsplatsen erlägger användaren ersättning, vilken betalning
                sker till FastPark Sweden AB för redovisning till den som upplåtit sin parkeringsplats och
                fastighetsägare.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li> 3 Definitioner</li>
            </ul>
            <p>
                Följande definitioner gäller för detta avtal.
                FastPark-appen – den av Förmedlaren utvecklade och ägda applikationen för förmedling
                av tillfälliga upplåtelser av parkeringsplatser.
                Användare - Den som använder sig av FastPark-appen för att söka och boka
                parkeringsplats.
                Förmedlaren - FastPark Sweden AB, utvecklare och ägare av FastPark-appen.
                Upplåtare – innehavare av parkeringsplats som använder sig av FastPark-appen för att
                utbjuda sin parkeringsplats att tillfälligt nyttjas av tredje man.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>4. Upplåtarens användning av FastPark-appen</li>
            </ul>
            <p>
                Upplåtaren får endast använda FastPark-appen för att erbjuda tredje man att mot
                vederlag tillfälligt nyttja Upplåtarens parkeringsplats samt ingå avtal. Upplåtelse av
                parkeringsplats till Användaren får inte ske på andra än de av Förmedlare tillhandahållna
                avtalsvillkoren ”FastPark – Avtal mellan Upplåtare och Användare”.<br><br>
                Priset bestäms av upplåtaren med rekommendationer på olika intervaller
                av FastPark Sweden AB.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>5. Uppgifter från Upplåtaren</li>
            </ul>
            <p>
                För användningen av FastPark-appen skall Upplåtaren förse Förmedlaren med de
                uppgifter som behövs för att kunna upplåta sin parkeringsplats.<br><br>
                Upplåtaren och parkeringsplatsen som erfordras för förmedlingen, däribland namn,
                personnummer, postadress, e-postadress, telefonnummer samt härutöver preciserade
                uppgifter om parkeringsplatsen ifråga samt uppgifter för utbetalning av ersättning från
                Användaren som Förmedlaren har uppburit för Upplåtarens räkning. Uppgifterna lämnas
                genom ifyllande av formulär i FastPark-appen. Upplåtaren garanterar att de uppgifter han
                lämnar förmedlaren är korrekta och att de inte är missvisande eller vilseledande för
                Förmedlaren eller Användaren. Vid ändrade omständigheter, såsom adressbyte eller det
                förhållandet att Upplåtaren inte längre kan upplåta parkeringsplatsen, åligger det
                Upplåtaren att ofördröjligen rätta de registrerade uppgifterna. För det fall att Upplåtaren
                lämnar eller underlåter att rätta uppgifter som Förmedlaren bedömer som osanna,
                missvisande, vilseledande, kränkande eller i övrigt opassande äger Förmedlaren vägra
                att utföra förmedlingsuppdraget samt härutöver neka Upplåtaren att vidare
                nyttja FastPark-appen. Förmedlaren äger vidare rätt att neka att utföra
                förmedlingsuppdraget om uppgifterna som Upplåtaren lämnar är ofullständiga och om de
                inte är korrekt ifyllda.<br><br>
                Upplåtarens uppgifter får endast behandlas av FastPark Sweden AB. Anonymiserade
                uppgifter eller helt avidentifierade uppgifter kan dock komma att lämnas ut till externa
                mottagare. Härutöver äger Förmedlaren rätt att till Användare som reserverat eller bokat
                parkeringsplats lämna uppgifter om Upplåtarens namn, adress, telefon och e-post.<br><br>
                Om du vill veta om hur vi använder dina personuppgifter hänvisar vi till
                vår Integritetspolicy.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>6. Förmedlarens uppdrag och ansvar</li>
            </ul>
            <p>
                Förmedlarens uppdrag är begränsat till förmedling av Upplåtarens parkeringsplats för
                tillfälligt nyttjande av Användaren, mottagande av betalning för Upplåtarens räkning samt
                redovisning och utbetalning av uppburna medel med avdrag för Förmedlarens provision.
                Förmedlaren är således inte att anse som avtalspart ifråga om upplåtelsen av
                parkeringsplatsen. Användaren är hänvisad till att framställa eventuella reklamationer
                och krav hänförliga till förhyrningen till Upplåtaren. På motsvarande sätt är Upplåtaren
                hänvisad till att framställa eventuella krav mot Användaren direkt till denne. Förmedlaren
                lämnar inga som helst utfästelser eller garantier om att avtal mellan Upplåtare och
                Användare skall komma till stånd. Upplåtaren är införstådd med att tillgången
                till FastPark-appen från tid till annan kan vara begränsad för Användare och Upplåtare
                på grund av tekniskt underhåll, uppdateringar, driftsstörningar eller av andra orsaker.
                Upplåtaren äger inte rikta något krav mot Förmedlaren för att tillgången till tjänsten under
                någon period har varit begränsad. Förmedlarens ersättningsansvar för fel eller brist i
                tjänsten är begränsat till belopp motsvarande den förmedlingsprovision som Upplåtaren
                har betalat.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>7. Förmedlingsprovision; redovisning</li>
            </ul>
            <p>
                Under förutsättning att avtal om upplåtelse kommer till stånd mellan Upplåtare och
                Användare, vilket skall anses ha skett när Användaren bokat och erlagt betalningen för
                nyttjandet av parkeringsplatsen, skall Upplåtaren betala förmedlingsprovision
                till Förmedlaren. Förmedlingsprovision betalas med 25% av priset för
                upplåtelsen. Provisionen delas mellan FastPark och eventuella samarbetspartners till
                FastPark. I förmedlingsprovisionen ingår lagstadgad moms (20 %
                av provisionen) Förmedlaren äger rätt att lyfta sin provision ur den betalning som
                Förmedlaren uppbär från Användaren för Upplåtarens räkning, vilket får ske omgående
                efter att Förmedlaren mottagit betalningen. Återstående redovisningsmedel skall
                inom nittio (90) dagar från mottagandet utbetalas av Förmedlaren till
                Upplåtaren via banköverföring till anvisat bankkonto. Förmedlaren skall därvid även
                lämna skriftlig redovisning för sitt uppdrag, vilken redovisning skall sändas till av
                Upplåtaren angiven e-postadress.<br><br>
                Bekräftelse på att betalning har skett finns för Användare i appen samt att det skickas till
                angiven e-postadress.<br><br>
                Förmedlarens rätt till provision är inte villkorad av annat förhållande än att avtal kommer
                till stånd mellan Upplåtare och Användare enligt första stycket. Upplåtaren är således
                skyldig att betala provision oberoende av vilka krav som med anledning av upplåtelsen
                eventuellt kan komma att riktas av Upplåtaren mot Användaren eller omvänt.
                Priset på parkeringsplatsen bestäms av Upplåtaren med rekommendationer på olika
                intervaller av FastPark Sweden AB.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li> 8. Kommunikation</li>
            </ul>
            <p>
                Vi begär att all information som lämnats till oss är korrekt, aktuell och fullständig.
                Personlig information som du tillhandahåller oss kommer inte att avslöjas av oss till
                tredje part i enlighet med vår Integritetspolicy. Du samtycker till att vi kan använda den
                personliga information som du tillhandahåller i enlighet med vår sekretesspolicy.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>9. Reklam och kommersiellt bruk samt andra webbplatser och länkning</li>
            </ul>
            <p>
                Du har inte rätt att sälja eller kommersiellt utnyttja appens innehåll annat än innehåll som
                du har publicerat. Du har inte rätt att använda data mining, robotar eller liknande verktyg
                för insamling och extrahering av data för att samla in användares namn, e-postadresser
                eller annan data i syfte att skicka oönskad e-post eller för annan användning.
                I händelse av att du upptäcks av oss för att ha skickat oönskad e-post till våra användare
                så förbehåller vi oss rätten att avsluta utan förvarning din användning av appen.<br><br>
                Appen kan innehålla länkar till andra webbplatser eller material som ligger utanför dess
                kontroll. Vi är inte ansvariga för innehåll på någon webbplats utanför appen. Du får inte
                upprätta en länk från någon webbplats som inte ägs av dig.
                Vi förbehåller oss rätten att återkalla länkning utan tillstånd utan förvarning.
            </p>
        </div>
        <div>
            <ul style="list-style-type: disc">
                <li>10. Immateriella tillgångar</li>
            </ul>
            <p>
                Denna app eller någon del av denna app får inte reproduceras, dupliceras, kopieras,
                säljas, återförsäljas, besökas eller på annat sätt utnyttjas för något kommersiellt syfte
                utan vårt uttryckliga skriftliga medgivande. Du får inte systematiskt extrahera och/eller
                återanvända delar av innehållet på appen utan vårt uttryckliga skriftliga medgivande. I
                synnerhet får du inte använda någon data utvinning, robotar, eller liknande datainsamling
                och utvinning verktyg för att extrahera (om en gång eller flera gånger) för åter
                användning av några väsentliga delar av denna app utan vårt uttryckliga skriftliga
                medgivande.
            </p>
        </div>

        <h1 style="text-align: center">FastPark– Avtal mellan FastPark Sweden AB och Användare</h1>

        <div>
            <ul style="list-style-type: disc">
                <li>1. Inledning</li>
            </ul>
            <p>
                FastPark-appen är en applikation för mobiltelefon som ägs och har utvecklats
                av FastPark Sweden AB. Genom FastPark-appen kan den, som innehar en
                parkeringsplats och som inte avser att nyttja parkeringsplatsen under viss period, upplåta
                åt tredje man att mot ersättning nyttja parkeringsplatsen under hela eller en begränsad
                del av perioden. FastPark-appen ger således innehavaren av parkeringsplatsen både
                möjlighet att annonsera ut parkeringsplatsen och att, genom FastPark:s förmedling, ingå
                avtal med den som önskar nyttja parkeringsplatsen. FastPark ska förmedla p-plats i
                upplåtarens namn för upplåtarens räkning. För användandet av FastPark-appen betalar
                den som upplåtit sin parkeringsplats en förmedlingsprovision till FastPark Sweden AB.
                Den som är i behov av tillfällig parkeringsplats kan genom FastPark-appen söka efter en
                geografiskt passande parkeringsplats att nyttja under viss tid, erhålla prisuppgift samt
                genom tjänsten boka och betala för det tillfälliga nyttjandet. För den som
                använder FastPark-appen för att söka och boka parkeringsplats är applikationen gratis
                att använda. Bokning av parkeringsplats sker i två steg. Första steget är att användaren
                reserverar parkeringsplats. Andra steget är att användaren bokar parkeringsplatsen,
                varvid användaren bekräftar att han accepterar priset, upplåtelsetiden och nedan
                angivna villkor för upplåtelsen samt erlägger betalning.
                För sitt nyttjande av parkeringsplatsen erlägger användaren ersättning. Betalning sker
                till FastPark Sweden AB för redovisning till den som upplåtit sin parkeringsplats.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>2. Definitioner</li>
            </ul>
            <p>

                Följande definitioner gäller för detta avtal.
                FastPark-appen – den av Förmedlaren utvecklade och ägda applikationen för förmedling
                av tillfälliga upplåtelser av parkeringsplatser.<br><br>
                Användare - den som använder sig av FastPark-appen för att söka och boka
                parkeringsplats.<br><br>
                Förmedlaren - FastPark Sweden AB utvecklare och ägare av FastPark-appen.<br><br>
                Upplåtare – innehavare av parkeringsplats som använder sig av FastPark-appen för att
                utbjuda sin parkeringsplats att tillfälligt nyttjas av tredje man.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>3. Avtal om användning</li>
            </ul>
            <p>
                Avtal om användning kommer till stånd mellan FastPark Sweden AB och användaren
                genom att användaren loggar in på FastPark-appen. I samband med skapandet av
                kontot/alternativt genom inloggning bekräftar användaren att han godtar de allmänna
                villkor som följer av detta avtal.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>
                    4. Användaren kan söka och boka parkeringsplatser med FastPark-appen. För att
                    utnyttja en parkeringsplats måste användaren godkänna och betala den avgift som
                    Upplåtaren angett. Upplåtelsen sker för den tidsperiod som Användaren bokar. Det är
                    inte möjligt att boka parkeringsplats för annan eller längre tid än vad Upplåtaren
                    genom FastPark-appen erbjuder. Som upplåtelsen av parkeringsplatsen är tillfällig och
                    tidsbegränsad upphör upplåtelsen utan föregående uppsägning vid utgången av den
                    tidsperiod som Användaren bokat.
                </li>
            </ul>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>5. Förmedlingsprovision; redovisning under förutsättning att avtal om
                    upplåtelse kommer till stånd mellan Upplåtare och Användare, vilket skall anses ha skett
                    när Användaren bokat och erlagt betalningen för nyttjandet av parkeringsplatsen, skall
                    Upplåtaren betala förmedlingsprovision till Förmedlaren. Förmedlingsprovision betalas
                    med 25% av priset för upplåtelsen. I förmedlingsprovisionen ingår lagstadgad moms
                    (20% av provisionen. FastPark delar förmedlingsprovision med eventuella
                    samarbetspartners.
                    Förmedlaren äger rätt att lyfta sin provision ur den betalning som Förmedlaren uppbär
                    från Användaren för Upplåtarens räkning, vilket får ske omgående efter att Förmedlaren
                    mottagit betalningen. Återstående redovisningsmedel skall inom nittio (90) dagar från
                    mottagandet utbetalas av Förmedlaren till Upplåtaren via banköverföring till anvisat
                    bankkonto. Upplåtaren garanterar att angivna bankuppgifter är korrekta och FastPark tar
                    inte ansvar för felaktigt angivna betaluppgifter. Förmedlaren skall därvid även lämna
                    skriftlig redovisning för sitt uppdrag, vilken redovisning skall sändas till av Upplåtaren
                    angiven e-postadress.
                    Bekräftelse på att betalning har skett finns för Användare i appen samt att det skickas till
                    angiven e-postadress.
                    Förmedlarens rätt till provision är inte villkorad av annat förhållande än att avtal kommer
                    till stånd mellan Upplåtare och Användare enligt första stycket. Upplåtaren är således
                    skyldig att betala provision oberoende av vilka krav som med anledning av upplåtelsen
                    eventuellt kan komma att riktas av Upplåtaren mot Användaren eller omvänt.
                </li>
            </ul>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>6. Betalning</li>
            </ul>
            <p>
                Användaren är skyldig att i förskott erlägga full betalning för nyttjandet, vilket sker vid
                Användarens bokning av parkeringsplatsen. Betalningen erläggs av Användaren till
                Förmedlaren för redovisning till Upplåtaren. Betalning kan endast ske genom
                betalningsfunktionen i FastPark-appen. Först när full betalning har erlagts anses
                parkeringsplatsen upplåten till Användaren.
                När betalningen är genomförd föreligger ingen ångerrätt för användaren.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>7. Användarens nyttjande av parkeringsplatsen</li>
            </ul>
            <p>
                Användaren får endast nyttja parkeringsplatsen under den tid som Användaren har
                bokat. Användaren får endast nyttja parkeringsplatsen för parkering av personbil. Såvida
                Upplåtaren inte har uppgivit sig acceptera större fordon får personbilens mått ej överstiga
                följande: längd 5 meter, bredd 2 meter, höjd 2 m. Användarens nyttjande av
                parkeringsplatsen får inte medföra utsläpp av olja eller andra miljöfarliga ämnen,
                nedskräpning eller annan förorening av parkeringsplatsen eller omgivningen.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>8. Säkerhet för ditt fordon</li>
            </ul>
            <p>
                Tillse att ditt fordon är säkert låst med alla fönster ordentligt stängda och alla fordon
                larm, styrlås eller liknande anordning monterad är inkopplad. Vi är inte ansvariga för
                någon konsekvens eller förlust som uppstår.
                Alla ägodelar kvar i ett fordon lämnas helt på ägarens risk. Vi föreslår att inga föremål
                lämnas så att de syns från fordonets utsida. Vi är inte ansvariga för någon stöld från ditt
                fordon.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>9. Reklamation</li>
            </ul>
            <p>
                Användarens eventuella reklamationer och krav mot Upplåtaren skall Användaren
                framställa direkt till Upplåtaren, enligt de kontaktuppgifter som Användaren har erhållit
                inför bokning av parkeringsplatsen. Parterna är införstådda med att Förmedlaren, som
                inte är att anse som avtalspart ifråga om upplåtelseavtalet, inte är behörig att mottaga
                reklamationer eller krav för någondera parten.
            </p>
        </div>

        <div>
            <h6>Tredjehands uthyrning</h6>
            <p>
                Användaren/hyrestagaren får i sin tur inte låta annan än den som bokat
                parkeringsplatsen samt dennes familj att nyttja den. Tredjehandsuthyrning får inte
                förekomma. Skulle det förekomma och komma till vår kännedom så medför det till
                omedelbar avstängning.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>10. Avslut av konto</li>
            </ul>
            <p>
                Om man bryter mot ovanstående regler eller på annat sätt missbrukar tjänsten äger
                Förmedlaren rätt att avsluta kontot.
            </p>
        </div>

        <h1 style="text-align: center">FastPark Sweden AB – Avtal mellan Upplåtare och Användare</h1>

        <div>
            <ul style="list-style-type: disc">
                <li>1. Inledning</li>
            </ul>
            <p>
                FastPark-appen är en applikation för mobiltelefon. Applikationen har utvecklats och ägs
                av FastPark Sweden AB.
                Genom FastPark-appen kan den, som innehar en parkeringsplats och som inte avser att
                nyttja parkeringsplatsen under viss period, upplåta åt tredje man att mot ersättning nyttja
                parkeringsplatsen under hela eller en begränsad del av perioden. FastPark-appen ger
                således innehavaren av parkeringsplatsen både möjlighet att annonsera ut
                parkeringsplatsen och att, genom FastPark-appens förmedling, ingå avtal med den som
                önskar nyttja parkeringsplatsen. För användandet av FastPark-appen betalar den som
                upplåtit sin parkeringsplats en förmedlingsprovision till FastPark Sweden AB.
                Den som är i behov av tillfällig parkeringsplats kan genom FastPark-appen söka efter en
                geografiskt passande parkeringsplats att nyttja under viss tid, erhålla prisuppgift samt
                genom tjänsten boka och betala för det tillfälliga nyttjandet. För den som
                använder FastPark-appen för att söka och boka parkeringsplats är applikationen för
                närvarande gratis att använda. Bokning av parkeringsplats sker i två steg. Första steget
                är att användaren reserverar parkeringsplats, varvid besked lämnas om vem som
                innehar parkeringsplatsen. Andra steget är att användaren bokar parkeringsplatsen,
                varvid användaren bekräftar att han accepterar priset, upplåtelsetiden och nedan
                angivna villkor för upplåtelsen samt erlägger betalning.
                När betalningen är genomförd föreligger ingen ångerrätt för användaren.
                Upplåtaren ska vara medveten om att det kan finnas befintliga regler gällande dennes
                parkeringsplats som denne måste ta hänsyn till innan upplåtelse
                i FastParks app sker. FastPark tar inget ansvar för eventuella konsekvenser som
                upplåtaren drabbas av om denne hyr ut sin parkeringsplats i strid med de regler som
                parkeringsplatsen omfattas av.
                För sitt nyttjande av parkeringsplatsen erlägger användaren ersättning. Betalning sker
                till FastPark Sweden AB för redovisning till den som upplåtit sin parkeringsplats.</p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li>2. Definitioner</li>
            </ul>
            <p>
                Följande definitioner gäller för detta avtal.
                FastPark-appen – den av Förmedlaren utvecklade och ägda applikationen för förmedling
                av tillfälliga upplåtelser av parkeringsplatser.<br><br>
                Användare - den som använder sig av FastPark-appen för att söka och boka
                parkeringsplats.<br><br>
                Förmedlaren - FastPark Sweden AB, utvecklare och ägare av FastPark-appen.<br><br>
                Upplåtare – innehavare av parkeringsplats som använder sig av FastPark-appen för att
                utbjuda sin parkeringsplats att tillfälligt nyttjas av tredje man.
            </p>
        </div>

        <div>
            <ul style="list-style-type: disc">
                <li> 3. Avtal om upplåtelse</li>
            </ul>
            <p>
                Avtal om upplåtelse kommer till stånd mellan Användare och Upplåtare genom att
                Användaren i den särskilda funktionen i FastPark-appen bokar och betalar för
                upplåtelsen. För att boka parkeringsplats måste Användaren först acceptera Upplåtarens
                villkor ifråga om pris och tid för upplåtelsen. Vidare måste Användaren bekräfta att han
                godtar de allmänna villkor för upplåtelsen som följer av detta avtal.
                Upplåtaren har i förväg, vid registreringen av parkeringsplatsen för upplåtelse
                genom FastPark-appen, godkänt dessa villkor.
                Priset anges av uthyraren efter överenskommelser med FastPark Sweden AB. Priset per
                uthyrningstillfälle visas för parkeringen i appen.
            </p>
        </div>

        <div>
            <ul>
                <li>4. Pris och tid för upplåtelsen</li>
            </ul>
            <p>
                Priset bestäms av upplåtaren med rekommendationer på olika intervaller
                av FastPark Sweden AB. Av de uppgifter som Upplåtaren registrerat och som
                Användaren tar del av före bokning framgår huruvida och till vilken del priset innefattar
                moms. Priset innefattar alltid den förmedlingsprovision som Förmedlaren äger rätt att
                erhålla från Upplåtaren. Upplåtelsen sker för den tidsperiod som Användaren bokar. Det
                är inte möjligt att boka parkeringsplats för annan eller längre tid än vad Upplåtaren
                genom FastPark-appen erbjuder. Som upplåtelsen av parkeringsplatsen är tillfällig och
                tidsbegränsad upphör upplåtelsen utan föregående uppsägning vid utgången av den
                tidsperiod som Användaren bokat.
            </p>
        </div>

        <div>
            <ul>
                <li>5. Betalning</li>
            </ul>
            <p>
                Användaren är skyldig att i förskott erlägga full betalning för nyttjandet, vilket sker vid
                Användarens bokning av parkeringsplatsen. Betalningen erläggs av Användaren till
                Förmedlaren för redovisning till Upplåtaren. Betalning kan endast ske genom
                betalningsfunktionen i FastPark-appen. Först när full betalning har erlagts anses
                parkeringsplatsen upplåten till Användaren.
            </p>
        </div>

        <div>
            <ul>
                <li>6. Användarens nyttjande av parkeringsplatsen</li>
            </ul>
            <p>
                Användaren får endast nyttja parkeringsplatsen under den tid som Användaren har
                bokat. Användaren får endast nyttja parkeringsplatsen för parkering av personbil. Såvida
                Upplåtaren inte har uppgivit sig acceptera större fordon får personbilens mått ej överstiga
                följande: längd 5 meter, bredd 2 meter, höjd 2 m. Användarens nyttjande av
                parkeringsplatsen får inte medföra utsläpp av olja eller andra miljöfarliga ämnen,
                nedskräpning eller annan förorening av parkeringsplatsen eller omgivningen. Om bilen
                inte hämtas inom föreskriven tid har Upplåtaren rätt att kontakta registrerad ägare. Om
                ägare till bilen efter lagstadgad tid inte hämtat bilen så har Upplåtaren rätt att forsla bort
                bilen på registrerad ägares bekostnad. Användaren/hyrestagaren får i sin tur inte låta
                annan än den som bokat parkeringsplatsen samt dennes familj
                att nyttja den. Tredjehandsuthyrning får inte förekomma. Skulle det förekomma och
                komma till vår kännedom så medför det till omedelbar avstängning.
            </p>
        </div>

        <div>
            <ul>
                <li>7. Upplåtarens åtaganden</li>
            </ul>
            <p>
                Upplåtaren garanterar att han äger upplåta parkeringsplatsen för tillfälligt nyttjande av
                tredje man och på de villkor som erbjudandet genom FastPark-appen avser. Upplåtaren
                garanterar vidare att parkeringsplatsen står till Användarens förfogande under hela den
                tid som upplåtelsen avser, innebärande att Användaren utan hinder skall kunna nyttja
                parkeringsplatsen för normal parkering. Garantin omfattar även att inget hinder föreligger
                under upplåtelsen beträffande till- och frånfartsväg för parkeringsplatsen, dock ansvarar
                Upplåtaren inte för hinder beträffande framfart på allmän väg.
            </p>
        </div>

        <div>
            <ul>
                <li>8. Övergivna fordon</li>
            </ul>
            <p>
                Övergivet fordon är en sak mellan Upplåtare och Användare. Om ägare till bilen efter
                lagstadgad tid inte hämtat bilen så har Upplåtaren rätt att forsla bort bilen på registrerad
                ägares bekostnad.
                Upplåtaren har rätt att efter iakttagande av lagstadgad tid att ansöka om bortforsling av
                fordonet.
            </p>
        </div>

        <div>
            <ul>
                <li>9. Reklamation</li>
            </ul>
            <p>
                Användarens eventuella reklamationer och krav mot Upplåtaren skall Användaren
                framställa direkt till Upplåtaren, enligt de kontaktuppgifter som Användaren har erhållit
                inför bokning av parkeringsplatsen. Parterna är införstådda med att Förmedlaren, som
                inte är att anse som avtalspart ifråga om upplåtelseavtalet, inte är behörig att mottaga
                reklamationer eller krav för någondera parten.
                Dessa villkor styrs och tolkas i enlighet med svensk lag. Om tvist uppkommit mellan
                parterna kan ett opartiskt utlåtande lämnas av Allmänna Reklamationsnämnden.
                Uthyraren ger FastPark Sweden AB rätten att besluta om återbetalning till användaren i
                förekommande fall. Om det redan står en bil på den
            </p>
        </div>

        <ul style="text-align: left">
            <li>Om parkeringsplatsen inte är brukbar av någon anledning krävs en beskrivning av
                situationen och en bedömning kommer att göras av FastPark.
            </li>
        </ul>

        <div style="text-align: left">
            Denna information skickas till FastParks support. FastPark äger rätten att besluta om
            eventuell återbetalning.<br>
            FastPark Sweden AB: 559201 – 4012<br>
            Besöksadress: Packhusallén 8B<br>
            FastPark support: <a href="mailto:Info@fastpark.se">Info@fastpark.se</a>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Licence"
    }
</script>

<style scoped>
    p {
        text-align: left;
    }
</style>